/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: medicines.js
Description: This file contains all the vuex store functions used in medicines page
*/
import ResourceManagmentService from "@/services/ResourceManagementService";

const medicinesStore = {
  namespaced: true,
  state: {
    date: ""
  },
  getters: {
    getDate: function(state) {
      return state.date;
    }
  },
  mutations: {
    SET_DATE(state, date) {
      state.date = date;
    }
  },
  actions: {
    changeDate({ commit }, date) {
      commit("SET_DATE", date);
    },
    addMedicinesToPonds: async (context, params = {}) => {
      const response = await ResourceManagmentService.addMedicinesToPonds(
        params
      );
      return response.data;
    },
    deleteMedicinesAndMineralsInPonds: async (context, params = {}) => {
      const response = await ResourceManagmentService.deleteMedicinesAndMineralsInPonds(
        params
      );
      return response.data;
    }
  }
};

export default medicinesStore;
