/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: axios.js
Description: This file contains the axios objects of different configurations useful for superadmin, account manager, pondlogs, dealers site
*/
import axios from "axios";
import appConfig from "@/config/app";
import localStore from "@/utils/localStore";
import { userTypes } from "@/middleware/pageAccessManager";
import store from "@/store";

const appUrl = appConfig.BK_APP_URL;
const adminUrl = appConfig.BK_ADMIN_URL;
const dealerUrl = appConfig.BK_DEALER_URL;
const accountUrl = appConfig.BK_ACCOUNT_URL;

const instance = axios.create({
  baseURL: appUrl,
  timeout: 0
});

instance.interceptors.request.use(
  config => {
    //  Generate cancel token source
    const source = axios.CancelToken.source();

    // Set cancel token on this request
    config.cancelToken = source.token;

    // Add to vuex to make cancellation available from anywhere
    store.commit("ADD_CANCEL_TOKEN", source);

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export const $axios = instance;

export const $unAuthAxios = axios.create({
  baseURL: appUrl,
  timeout: 0
});

export const $superAccountAxios = axios.create({
  baseURL: accountUrl,
  timeout: 0
});
export const $superSkrettingTechnicianAxios = axios.create({
  baseURL: adminUrl,
  timeout: 0
});

export const $superDealerAxios = axios.create({
  baseURL: dealerUrl,
  timeout: 0
});

export const $superAdminAxios = axios.create({
  baseURL: adminUrl,
  timeout: 0
});

const userType = localStore.getItem(`curr-user-type`);
const userId = localStore.getItem(`${userType}-id`);
if (userType && userId) {
  const userInfo = localStore.getItem(`${userType}-${userId}`);
  if (userInfo && userInfo.access_token && userInfo.refresh_token) {
    if (userType === userTypes.super_admin) {
      $superAdminAxios.defaults.headers.common.Authorization =
        userInfo.access_token;
    } else if (userType === userTypes.dealer) {
      $superDealerAxios.defaults.headers.common.Authorization =
        userInfo.access_token;
    } else if (userType === userTypes.account_manager) {
      $superAccountAxios.defaults.headers.common.Authorization =
        userInfo.access_token;
    } else if (userType === userTypes.skretting_technician) {
      console.log("userInfo", userInfo);
      $superSkrettingTechnicianAxios.defaults.headers.common.Authorization =
        userInfo.access_token;
    } else {
      $axios.defaults.headers.common.Authorization = userInfo.access_token;
    }
  }
}

export default {
  $axios,
  $superDealerAxios,
  $superAccountAxios,
  $superSkrettingTechnicianAxios,
  $superAdminAxios,
  $unAuthAxios
};
