// import { async } from "q";
import newReleaseService from "../../../services/superadmin/newRelease";

const state = {
  versionData: {},
  imageUrl: ""
};

const getters = {
  getReleaseList: (state, getters) => {
    return state.versionData;
  },
  getImageUrl: (state, getters) => {
    return state.imageUrl;
  }
};

const mutations = {
  SET_ALL_RELEASE_LIST: function(state, releaseList) {
    state.versionData = releaseList;
  },
  SET_IMAGE_URL: function(state, imageUrl) {
    state.imageUrl = imageUrl;
  }
};

const actions = {
  fetchAllReleaseList: async function(context, { params = {} }) {
    const response = await newReleaseService.fetchReleaseList({
      params: { ...params }
    });
    context.commit("SET_ALL_RELEASE_LIST", response);
    return response;
  },
  createReleaseVersion: async function(context, params) {
    const response = await newReleaseService.createReleaseVersion({
      params
    });
    return response;
  },
  updateReleaseVersion: async function(context, params) {
    const response = await newReleaseService.updateReleaseVersion({
      params
    });
    console.log("response", response);
    return response;
  },
  deleteReleaseVersion: async function(context, params) {
    const response = await newReleaseService.deleteReleaseVersionService({
      params
    });
    console.log("response", response);
    return response;
  },
  uploadImage: async function(context, params) {
    // let imagePath = "";

    const response = await newReleaseService.uploadImageService({ params });
    return response.data;
    // newReleaseService
    //   .uploadImageService({
    //     params
    //   })
    //   .then(response => {
    //     const { full_file_path, target_url } = response?.data?.data;
    //     imagePath = full_file_path;
    //     console.log(target_url);
    //     context.commit('SET_IMAGE_URL', imagePath);
    //     // const payload = {
    //     //   target_url,
    //     //   file: params
    //     // };
    //     // newReleaseService
    //     //   .savePictureToTargetUrlService(payload)
    //     //   .then(response => {
    //     //     console.log(response);
    //     //   })
    //     //   .catch(err => console.log(err))
    //     //   .finally(() => {
    //     //   });
    //       // return imagePath;
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   });
    //   return imagePath;
  },
  savePictureToTargetUrl: async function(context, params) {
    const response = await newReleaseService.savePictureToTargetUrlService(
      params
    );
    return response.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
