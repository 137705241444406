<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: viewNoInternet.vue
Description: This file gives the info about where internet is not available.When internet is not there it will redirect to viewNoInternet.vue
-->
<template>
  <div class="view404">
    <div class="content">{{ $t("Comn_nointernet") }}</div>
  </div>
</template>

<script>
export default {
  //   methods: {
  //     callfunction: function () {
  //       if (navigator.onLine) {
  //         window.location.replace("http://v2.pondlogs.com");
  //       }
  //       setTimeout(this.callfunction, 1000);
  //     },
  //   },
  //   mounted() {
  //     this.callfunction();
  //   },
};
</script>

<style lang="scss">
.view404 {
  position: relative;
  height: 100%;
  .content {
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 40%;
  }
}
</style>
