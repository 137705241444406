<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: subMenuOverview.vue
Description: This file is the part of navigation menu which contains menu items related to overview module.
-->
<template>
  <el-submenu index="Comn_overview" v-if="canShowSubMenuItem">
    <template slot="title">{{ $t("Comn_overview") }}</template>
    <router-link
      v-for="(item, key) in filterMenuItemsByPermissions"
      :key="key"
      :to="item.route"
      class="menu-item-wrapper"
    >
      <li
        class="el-menu-item"
        :index="item.langKey"
        :route="item.route"
        :class="{ 'is-active': $route.meta.langKey === item.langKey }"
      >
        <img class="menu-image" :src="renderMenuIcon(item.imgKey)" />
        <p class="menu-title truncate" v-if="getSideNavStatus">
          {{ $t(item.langKey) }}
        </p>
      </li>
    </router-link>
  </el-submenu>
</template>

<script>
import { mapGetters } from "vuex";
import renderMenuIcon from "./renderMenuIcon";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";

export default {
  watch: {
    $route(oldValue, newValue) {
      // console.log(newValue);
    }
  },
  computed: {
    ...mapGetters("header", {
      getSideNavStatus: "getSideNavStatus"
    }),

    getPond() {
      return this.$route.query.pondId;
    },
    getTab() {
      return this.$route.query.tab;
    },

    menuItems: function () {
      const defaultTab = this.$store.getters["user/getPreferredUnits"].default_dashboard_tab;
      const getRouteObject = (path, newQuery = {}) => {
        return {
          path,
          query: { location_id: this.$route.query.location_id, ...newQuery }
        };
      };
      return {
        DASHBOARD: {
          langKey: "Comn_dashboard",
          route: getRouteObject("/user/dashboard", { tab: defaultTab !== undefined ? defaultTab : "dashboardTab" }),
          imgKey: "DASHBOARD",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [
            permissions.MANAGE_OVERVIEW,
            permissions.MANAGE_CULTURE,
            permissions.MANAGE_DEVICES,
            permissions.MANAGE_ABW,
            permissions.MANAGE_HARVEST
          ]
        },
        MAPS: {
          langKey: "Comn_map",
          route: getRouteObject("/user/map-view"),
          imgKey: "MAPS",
          keyToValidateAccess: "PERMISSIONS",
          // PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES]
          PERMISSIONS: {
            VIEW: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES],
            UPDATE: [permissions.MANAGE_CULTURE],
            // DELETE: [permissions.MANAGE_LAB_TESTS]
          }
        },
        PONDS: {
          langKey: "Comn_ponds",
          route: getRouteObject("/user/pond-details", {
            pondId: this.getPond,
            tab: this.getTab
          }),
          imgKey: "PONDS",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [
            permissions.MANAGE_ABW,
            permissions.MANAGE_CULTURE,
            permissions.MANAGE_SCHEDULES,
            permissions.MANAGE_MEDICINE,
            permissions.MANAGE_LAB_TESTS,
            permissions.MANAGE_DEVICES
          ]
        },
        DEVICE_HEALTH: {
          langKey: "Comn_device_health",
          route: getRouteObject("/user/device-health", { tab: "batteryTab" }),
          imgKey: "BATTERY",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [permissions.MANAGE_CULTURE, permissions.MANAGE_DEVICES]
        }
      };
    },
    filterMenuItemsByPermissions: function () {
      return this.$gblUAMFilterItemsUserCanView(this.menuItems);
    },
    canShowSubMenuItem: function () {
      return Object.keys(this.filterMenuItemsByPermissions).length > 0;
    }
  },
  methods: {
    renderMenuIcon
  }
};
</script>

<style></style>
