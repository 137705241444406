import { $superAdminAxios } from "@/config/axios";

export default {
  // location
  fetchVersionValues() {
    return $superAdminAxios.get(`admin/pond-mother-versions`);
  },
  fetchPMStatsData({ params }) {
    return $superAdminAxios.get(`admin/pond-mother-version-stats`, { params });
  }
};
