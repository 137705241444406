/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: ShrimpSnapService.js
Description: This file contains all API service calls in relation to ShrimpSnap
*/
import { $axios } from "@/config/axios";
export default {
  fetchByPondId(pondId, params) {
    return $axios.get(`/ponds/${pondId}/shrimp-snaps`, { params });
  },
  fetchAll(params) {
    return $axios.get("/ponds/shrimp-snaps", { params });
  },
  fetchOneById(id) {
    return $axios.get(`/ponds/shrimp-snaps/${id}`);
  },
  fetchImageData(params) {
    // return axios.get(
    //   "https://shrimpsnap-image-data-9nyc9v8a12ay.runkit.sh/shrimpsnap-data"
    // );
    return $axios.get("/ponds/shrimp-snaps/images-list", { params });
  },
  addMultiple(data) {
    return $axios.post(`/ponds/shrimp-snaps`, data);
  },
  deleteMultiple(data, params) {
    return $axios.delete(`/ponds/shrimp-snaps`, { data, params });
  }
};
