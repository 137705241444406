import inquiryLogService from "@/services/superadmin/inquiryLog.js";

const state = {
  inquiryLogData: []
};
const getters = {
  getInquiryLogData(state) {
    return state.inquiryLogData;
  }
};
const mutations = {
  SET_INQUIRY_LOG_DATA: function(state, inquiryLogData) {
    state.inquiryLogData = inquiryLogData;
  }
};
const actions = {
  fetchInquiryLog: async function(context, params) {
    try {
      const response = await inquiryLogService.fetchInquiryLog(params);
      context.commit("SET_INQUIRY_LOG_DATA", response.data.inquiry_logs);
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  addReplyToInquiryLog: async function(context, payload) {
    try {
      await inquiryLogService.addReplyToInquiryLog(payload);
    } catch (err) {
      console.log(err);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
