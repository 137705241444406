<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: notificationView.vue
Description: This file is a component that displays list of notifications to the user
-->
<template>
  <el-row class="notification-view">
    <el-scrollbar
      class="list-scroll"
      viewClass="list-scroll-view"
      wrapClass="list-scroll-wrap"
    >
      <template v-for="(item, index) in itemList">
        <slot :item="{ item }">
          <notification-list-item :key="item._id + index" :list-item="item">
          </notification-list-item>
        </slot>
      </template>
    </el-scrollbar>
  </el-row>
</template>

<script>
import notificationListItem from "@/components/index/notificationListItem";
export default {
  props: ["itemList"],
  components: {
    notificationListItem
  }
};
</script>

<style lang="scss">
.notification-view {
  .list-scroll-wrap {
    max-height: 200px;
  }
  .notification-list-item:not(:last-child) {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ebeef5;
    border-bottom-style: dashed;
  }
}
</style>
