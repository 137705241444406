/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondDetails.js
Description: This file contains all the vuex store functions used in Pond Details page
*/
import { cloneDeep } from "lodash";
import PondGuardService from "@/services/PondGuardService";
import ResourceManagementService from "@/services/ResourceManagementService";
import ShrimpGrowthService from "@/services/ShrimpGrowthService";
import FeedManagementService from "@/services/FeedManagementService.js";
import ShrimpTalkService from "@/services/ShrimpTalkService";
import WaterQualityService from "@/services/WaterQualityService";
import dateUtils from "@/utils/dateUtils";
import {
  IQRUpperBound,
  Quartile_50,
  alphaNumericComparator
} from "@/utils/commonUtils";
import pondABWTab from "./pondABWTab";
import ShrimpTalk from "../../../model/shrimptalk";
import PondsService from "../../../services/PondsService";
const initLatestABWValue = {
  abw: undefined,
  survival_percentage: 100,
  awg: undefined,
  total_dispensed_feed: undefined
};
export default {
  namespaced: true,
  modules: {
    pondABWTab
  },
  state: {
    pondList: [],
    selectedPondId: null,
    selectedPondWithDeviceDetails: {},
    pondIdPondMap: {},
    currentCulture: {
      start_date: undefined,
      end_date: undefined
    },
    arrSelectedPondABWRecords: [],
    manualFeedData: [],
    slotWiseFeed: [],
    pondHourlyFeed: [],
    stHourlyFeed: [],
    stResponseGraphData: [],
    stOCFGraphData: [],
    pmSlotWiseFeed: [],
    pmDetailsOfSelectedPondId: [],
    stDetailsOfSelectedPondId: [],
    selectedTabName: "",
    arrSelectedPondPGs: [],
    latestAbwValue: { ...initLatestABWValue },
    feedConsumptionData: [],
    pondAbwVsBiomassGraphData: [],
    selectedPondMedcnsMinrls: [],
    allpondsMedicineMinerals: [],
    allpondsLabTests: [],
    stGraphLoading: false,
    selectedStIdInGraph: "",
    selectedResponseType: "",
    slotWiseManualFeed: [],
    hourlyFeedVsResponse: []
  },
  getters: {
    getSelectedStIdInGraph: function(state) {
      return state.selectedStIdInGraph;
    },
    getStGraphLoading(state) {
      return state.stGraphLoading;
    },
    getSelectedpondAbwVsBiomassGraphData: state => {
      return state.pondAbwVsBiomassGraphData;
    },
    getAllPondsMedcnsMirls: state => {
      return state.allpondsMedicineMinerals;
    },
    getSelectedPondMedcnsMinrls: function(state) {
      return state.selectedPondMedcnsMinrls;
    },
    getStResponseGraphData: function(state) {
      return state.stResponseGraphData.map(x => {
        x.data.shrimp_talk_code = x.shrimp_talk_code;
        x.data._id = x._id;
        return x.data;
      });
    },
    getStOCFGraphData: function(state) {
      return state.stOCFGraphData.map(x => {
        x.data.shrimp_talk_code = x.shrimp_talk_code;
        x.data._id = x._id;
        return x.data;
      });
    },
    getSelectedPondLabTests: function(state) {
      return state.allpondsLabTests;
    },
    getSelectedPondAbwSummary: (state, getters) => {
      const {
        abw,
        awg,
        survival_percentage,
        total_dispensed_feed
      } = state.latestAbwValue;
      const plStocked = getters.getCurrentCulturePLStocked;
      let biomass =
        abw && survival_percentage && plStocked
          ? (((abw / 1000) * survival_percentage) / 100) * plStocked
          : undefined;
      if (!getters.getCurrentCulture.is_running) {
        biomass = getters.getCurrentCultureBiomass;
      }
      return {
        abw,
        awg,
        biomass,
        survival_percentage,
        total_dispensed_feed
      };
    },
    getSelectedNetFeedSummary: state => {
      return state.feedConsumptionData;
    },
    getSelectedPondId: state => {
      return state.selectedPondId;
    },
    getSelectedPondName: (state, getters) => {
      return (
        getters.getSelectedPondId &&
        getters.getPondIdPondMap[getters.getSelectedPondId].title
      );
    },
    getArrSelectedPondABWRecords: (state, getters) => {
      return state.arrSelectedPondABWRecords;
    },
    getUserCountryName: (state, getters, rootState, rootGetters) => {
      return rootGetters["user/getUserCountryName"];
    },
    getUserTimeZone: (state, getters, rootState, rootGetters) => {
      return rootGetters["user/getUserTimeZone"];
    },
    getPondIdPondMap: (state, getters, rootState, rootGetters) => {
      return rootGetters["pond/getPondsObj"];
    },
    getPondsList(state, getters, rootState, rootGetters) {
      return rootGetters["pond/getPonds"];
    },
    getPondGroupByStatus(state, getters) {
      const objPondGroupByStatus = cloneDeep(getters.getPondsList)
        .sort((a, b) => {
          return alphaNumericComparator(a.title, b.title);
        })
        .reduce(
          (acc, x) => {
            acc[x.status].push(x);
            return acc;
          },
          { ACTIVE: [], INACTIVE: [] }
        );
      return objPondGroupByStatus;
    },
    getSelectedPondWithDeviceDetails: (state, getters) => {
      return state.selectedPondWithDeviceDetails;
    },
    getCultureID(state, getters) {
      return (getters.getCurrentCulture || { harvest_id: 0 }).harvest_id;
    },
    getCurrentCulturePLStocked(state, getters) {
      return (
        getters.getCurrentCulture || { post_larva_stocked: "NO_PL_STOCKED" }
      ).post_larva_stocked;
    },
    getCurrentCultureBiomass(state, getters) {
      return (getters.getCurrentCulture || { biomass: undefined }).biomass;
    },
    getCurrentCulture(state, getters, rootState, rootGetters) {
      let culture = state.currentCulture;
      if (culture) return cloneDeep(culture);
      culture = getters.getRunningCultureForSelectedPond;
      if (culture) return culture;
      const prevCultures = getters.getArrPrevCulturesForSelectedPond;
      if (prevCultures.length > 0) {
        culture = prevCultures.reverse()[0];
        return culture;
      }
      return {};
    },
    getAllCultureOfPond(state, getters) {
      const allCultures = getters.getArrPrevCulturesForSelectedPond;
      const runningCulture = getters.getRunningCultureForSelectedPond;
      if (runningCulture) {
        allCultures.push(runningCulture);
      }
      return allCultures;
    },
    getRunningCultureForSelectedPond(state, getters, rootState, rootGetters) {
      const pondId = getters.getSelectedPondId;
      const pond = getters.getPondIdPondMap[pondId];
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      let currentCulture = null;
      const isPondInCulture = [
        pond => Boolean(pond),
        pond => pond.status === "ACTIVE",
        pond => Boolean(pond.cultivation_date),
        pond => pond.cultivation_date !== ""
      ].every(x => x(pond));
      if (isPondInCulture) {
        currentCulture = {
          start_date: dateUtils
            .castUserUTCToBrowserTZDate(pond.cultivation_date, {
              timeZone: userTimeZoneString
            })
            .toISOString(),
          post_larva_stocked: pond.post_larva_stocked,
          end_date: dateUtils.formatTZToISO(
            dateUtils.endOfDay(
              dateUtils.getCurrDateInGivenTZ(userTimeZoneString)
            ),
            userTimeZoneString
          ),
          harvest_id: 0
        };
        currentCulture.is_running = true;
        currentCulture.doc = dateUtils.differenceInDays(
          dateUtils.parseISO(currentCulture.end_date),
          dateUtils.parseISO(currentCulture.start_date)
        );
        return currentCulture;
      }
      return undefined;
    },
    getArrPrevCulturesForSelectedPond(state, getters, rootState, rootGetters) {
      const pondId = getters.getSelectedPondId;
      if (!pondId) return [];
      const pond = getters.getPondIdPondMap[pondId] || {};
      const cultivationsArr = cloneDeep(pond.cultivations || []);
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      cultivationsArr.forEach(culture => {
        const startDate = dateUtils
          .castUserUTCToBrowserTZDate(culture.start_date, {
            timeZone: userTimeZoneString
          })
          .toISOString();
        const endDate = dateUtils
          .castUserUTCToBrowserTZDate(culture.end_date, {
            timeZone: userTimeZoneString
          })
          .toISOString();
        culture.start_date = startDate;
        culture.end_date = endDate;
        culture.is_running = false;
        culture.doc = dateUtils.differenceInDays(
          dateUtils.parseISO(culture.end_date),
          dateUtils.parseISO(culture.start_date)
        );
      });
      return cultivationsArr;
    },
    getPondScheduleGraphSlotWiseFeed(state) {
      return state.slotWiseFeed;
    },
    getPondScheduleGraphSlotWiseManualFeed(state) {
      return state.slotWiseManualFeed;
    },
    getPondHourlyFeedGraph(state) {
      return state.pondHourlyFeed;
    },
    getStHourlyFeedGraph(state) {
      return state.stHourlyFeed;
    },
    getPmSlotWiseFeedGraph(state) {
      return state.pmSlotWiseFeed;
    },
    getPmIdPmDetailsOfSelectedPondId(state) {
      const pmIdPmDetails = {};
      state.pmDetailsOfSelectedPondId.forEach(pm => {
        pmIdPmDetails[pm._id] = pm;
      });
      return pmIdPmDetails;
    },
    getArrPmDetailsOfSelectedPondId(state) {
      return cloneDeep(state.pmDetailsOfSelectedPondId);
    },
    getStIdStDetailsOfSelectedPondId(state) {
      const stIdStDetails = {};
      state.stDetailsOfSelectedPondId.forEach(st => {
        stIdStDetails[st._id] = st;
      });
      return stIdStDetails;
    },
    getManualFeedData(state) {
      return state.manualFeedData;
    },
    getABWData(state) {
      return state.arrABWRecs;
    },
    getArrSelectedPondPGs: function(state) {
      const objSelectedPondPGs = state.arrSelectedPondPGs.reduce(
        (acc, curr) => {
          acc[curr.device_id] = curr;
          return acc;
        },
        {}
      );
      return Object.values(objSelectedPondPGs);
    },
    getSTGraphOCFRawData(state, getters) {
      return getters.getStOCFGraphData.reduce(
        (acc, { one_cycle_feed: ocf, start }) => {
          const correctDate = dateUtils.parseISO(start);
          const timeEpoch = dateUtils.getTime(correctDate);
          if (acc[timeEpoch] === undefined) {
            acc[timeEpoch] = 0;
          }
          acc[timeEpoch] += ocf;
          return acc;
        },
        {}
      );
    },
    getSTGraphHourlyFGData(state, getters) {
      return getters.getStOCFGraphData.reduce((acc, { feed_gap, start }) => {
        const correctDate = dateUtils.parseISO(start);
        const timeEpoch = dateUtils.getTime(correctDate);
        if (acc[timeEpoch] === undefined) {
          acc[timeEpoch] = 0;
        }
        acc[timeEpoch] = feed_gap;
        return acc;
      }, {});
    },
    getSTGraphHourlyIntakeResponseData(state, getters, rootState, rootGetters) {
      const roundOff = value => +value.toFixed(1);
      const arrValuesST = getters.getStResponseGraphData;
      const arrUtilizationValues = arrValuesST.map(x =>
        roundOff(x.utilization)
      );
      const IQRUpperBoundValue = IQRUpperBound(arrUtilizationValues);
      const IQRMedianValue = Math.round(Quartile_50(arrUtilizationValues));
      // const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const response = arrValuesST.reduce(
        (acc, { utilization, one_cycle_feed: ocf, start, result }) => {
          const correctDate = new Date(
            dateUtils.formatDate(
              dateUtils.startOfHour(
                dateUtils.parse(start, dateUtils.isoFormatString, new Date())
              ),
              dateUtils.isoFormatString
            )
          );
          const timeEpoch = dateUtils.getTime(correctDate);
          const isNoise = result === 3;
          const eachAvgIntakeResponse = Math.round(IQRMedianValue * ocf);
          const eachIntakeResponse = Math.round(roundOff(utilization) * ocf);
          if (acc.total[timeEpoch] === undefined) {
            acc.total[timeEpoch] = 0;
          }
          if (utilization > IQRUpperBoundValue || isNoise) {
            acc.total[timeEpoch] += eachAvgIntakeResponse;
          } else {
            acc.total[timeEpoch] += eachIntakeResponse;
          }
          return acc;
        },
        { total: {} }
      );
      return response.total;
    },
    getSTGraphValidResponseCnt: function(state, getters) {
      return getters.getStResponseGraphData.filter(
        x => x.result === 1 || x.result === 2
      ).length;
    },
    getSTGraphCyclesCnt: function(state, getters) {
      return getters.getStResponseGraphData.length;
    },
    getSelectedSTResponseType: state => {
      return state.selectedResponseType;
    },
    getHourlyFeedVsResponse: state => {
      return state.hourlyFeedVsResponse;
    }
  },
  mutations: {
    SET_ABW_VS_BIOMASS_GRAPH_DATA: function(state, pondAbwVsBiomassGraphData) {
      state.pondAbwVsBiomassGraphData = pondAbwVsBiomassGraphData;
    },
    SET_SELECTED_ST_ID: function(state, stId) {
      state.selectedStIdInGraph = stId;
    },
    SET_SELECTED_NET_FEED_SUMMARY: function(state, feedConsumptionData) {
      state.feedConsumptionData = feedConsumptionData;
    },
    SET_SELECTED_POND_ABW_SUMMARY: function(state, latestAbwValue) {
      state.latestAbwValue = latestAbwValue || {
        ...initLatestABWValue
      };
    },
    SET_POND_ID_POND_MAP: function(state, pondObj) {
      state.pondIdPondMap = pondObj;
    },
    SET_SELECTED_POND_ID: function(state, selectedPondId) {
      state.selectedPondId = selectedPondId;
    },
    SET_CURRENT_CULTURE: function(state, cultureObj) {
      state.currentCulture = cultureObj;
    },
    SET_POND_SLOT_WISE_FEED: function(state, slotWiseLogs) {
      state.slotWiseFeed = slotWiseLogs;
    },
    SET_POND_SLOT_WISE_MANUAL_FEED: function(state, slotWiseManualFeed) {
      state.slotWiseManualFeed = slotWiseManualFeed;
    },
    SET_SELECTED_POND_WITH_DEVICE_DETAILS: function(
      state,
      selectedPondWithDeviceDetails
    ) {
      state.selectedPondWithDeviceDetails = selectedPondWithDeviceDetails;
    },
    SET_POND_HOURLY_WISE_FEED: function(state, hourlyFeedLogs) {
      state.pondHourlyFeed = hourlyFeedLogs;
    },
    SET_ST_HOURLY_WISE_FEED: function(state, hourlyFeedLogs) {
      state.stHourlyFeed = hourlyFeedLogs;
    },
    SET_PM_SLOT_WISE_FEED: function(state, slotWiseFeedLogs) {
      state.pmSlotWiseFeed = slotWiseFeedLogs;
    },
    SET_PM_DETAILS_OF_POND: function(state, pmsDetails) {
      state.pmDetailsOfSelectedPondId = pmsDetails;
    },
    SET_ST_DETAILS_OF_POND: function(state, stsDetails) {
      console.log("stsDetails", stsDetails);
      state.stDetailsOfSelectedPondId = stsDetails.map(x => {
        const newSt = new ShrimpTalk();
        newSt.castToSTObj(x);
        console.log("newSt", newSt);
        return newSt;
      });
    },
    SET_POND_MANUAL_FEED: function(state, manualFeedData) {
      state.manualFeedData = manualFeedData;
    },
    SET_SELECTED_POND_ABW_RECORDS: function(state, arrABWRecs) {
      state.arrSelectedPondABWRecords = arrABWRecs;
    },
    SET_SELECTED_TAB: function(state, selectedTabName) {
      state.selectedTabName = selectedTabName;
    },
    SET_SELECTED_POND_PGS: function(state, listOfPondGuards) {
      state.arrSelectedPondPGs = listOfPondGuards;
    },
    SET_SELECTED_POND_MEDICINE_MINERALS: function(
      state,
      pondsMedicineMinerals
    ) {
      state.selectedPondMedcnsMinrls = pondsMedicineMinerals;
    },
    SET_POND_MEDICINE_MINERALS: function(state, allpondsMedicineMinerals) {
      state.allpondsMedicineMinerals = allpondsMedicineMinerals;
    },
    SET_ST_RESP_GRAPH_DATA: function(state, statsData) {
      state.stResponseGraphData = statsData;
    },
    SET_ST_OCF_GRAPH_DATA: function(state, statsData) {
      state.stOCFGraphData = statsData;
    },
    SET_SELECTED_POND_LAB_TESTS: function(state, allpondsLabTests) {
      state.allpondsLabTests = allpondsLabTests;
    },
    ST_GRAPH_LOADING: function(state, loading) {
      state.stGraphLoading = loading;
    },
    ST_SELECTED_RESPONSE_TYPE: function(state, type = "") {
      state.selectedResponseType = type;
    },
    SET_HOURLY_FEED_VS_RESPONSE: function(state, hourlyFeedVsResponse) {
      state.hourlyFeedVsResponse = hourlyFeedVsResponse;
    }
  },
  actions: {
    fetchAPondDetails: async function(context, selectedPondId) {
      if (selectedPondId !== "") {
        await context.dispatch("pond/fetchAPond", selectedPondId, {
          root: true
        });
        const pondDetails = context.rootGetters["pond/getCurrentPondData"];
        const pms = await context.dispatch(
          "pondmother/fetchPondMothersOnPondId",
          selectedPondId,
          { root: true }
        );
        const sts = await context.dispatch(
          "shrimptalk/fetchShrimpTalksOnPondId",
          { pondId: selectedPondId },
          { root: true }
        );
        console.log("sts", sts);
        context.commit("SET_PM_DETAILS_OF_POND", pms);
        context.commit("SET_ST_DETAILS_OF_POND", sts);
        context.commit("SET_SELECTED_POND_WITH_DEVICE_DETAILS", pondDetails);
      }
    },
    async fetchDeviceDetailsDetailsByDate(
      context,
      { pondId, device_type, from_date, to_date }
    ) {
      const mapDeviceTypeToSetter = {
        POND_MOTHER: {
          SETTER: "SET_PM_DETAILS_OF_POND",
          FETCH: "fetchPondDevicesByDateRange",
          GETTERS: "getPmIdPmDetailsOfSelectedPondId"
        },
        SHRIMP_TALK: {
          SETTER: "SET_ST_DETAILS_OF_POND",
          FETCH: "fetchPondDevicesByDateRange",
          GETTERS: "getStIdStDetailsOfSelectedPondId"
        }
      };
      const response = await PondsService[
        mapDeviceTypeToSetter[device_type].FETCH
      ](pondId, {
        device_type,
        from_date,
        to_date
      });
      context.commit(
        mapDeviceTypeToSetter[device_type].SETTER,
        response.data.pond_device_mapping_logs.reduce((acc, x) => {
          // if (
          //   !context.getters[mapDeviceTypeToSetter[device_type].GETTERS][
          //     x.device_id
          //   ]
          // ) {
          //   return acc;
          // }
          x.code = x.device_code;
          x.title =
            (
              context.getters[mapDeviceTypeToSetter[device_type].GETTERS][
                x.device_id
              ] || {}
            ).title || x.device_title;
          x._id = x.device_id;
          acc.push(x);
          return acc;
        }, [])
      );
    },
    async fetchStAndPmLogs(context, { stId, from_date, to_date }) {
      console.log("stId, from_date, to_date", stId, from_date, to_date);
      const response = await PondsService.fetchShrimpTalkAndPondMotherLog(
        stId,
        { from_date, to_date }
      );
      return response?.data;
    },
    fetchPondPGsInCurrCulture: async function(context, { pondId, params }) {
      const response = await PondGuardService.fetchPGsUsedInCurrCulture(
        pondId,
        { get_all: true, ...params }
      );
      context.commit("SET_SELECTED_POND_PGS", response.data.pond_guards);
    },
    fetchSelectedPondLatestNABWValue: async function(
      context,
      { pondId, params }
    ) {
      const response = await ShrimpGrowthService.fetchSelectedPondLatestNABWValue(
        pondId,
        params
      );
      return response.data;
    },
    fetchFeedConsumptionSummary: async function(context, { pondId, params }) {
      const response = await ShrimpGrowthService.fetchFeedConsumptionSummary(
        pondId,
        params
      );
      return response.data;
    },
    fetchPondGuardData: async function(context, { pondId, pgId, params }) {
      const response = await WaterQualityService.fetchAllParamsOfWaterQuality(
        pondId,
        pgId,
        params
      );
      return response.data;
    },
    fetchAquaLabPondGuardData: async function(context, { pgId, params }) {
      const response = await WaterQualityService.fetchAquaLabAllParamsOfWaterQuality(
        pgId,
        params
      );
      return response.data;
    },
    changeSelectedPondId: async function(context, selectedPondId) {
      await context.dispatch("fetchAPondDetails", selectedPondId);
      context.commit("SET_SELECTED_POND_ID", selectedPondId);
      let currentCulture = context.getters.getRunningCultureForSelectedPond;
      const prevCultures = context.getters.getArrPrevCulturesForSelectedPond;
      if (!currentCulture && prevCultures.length > 0) {
        currentCulture = prevCultures.reverse()[0];
      }
      await context.dispatch("changeCulture", currentCulture);
    },
    pondAbwVsBiomassGraphData: async function(context) {
      const pondId = context.getters.getSelectedPondId;
      const currentCulture = context.getters.getCurrentCulture;
      const timeZone = context.rootGetters["user/getUserTimeZoneString"];
      const abwbiomassgraphqwery = {
        cultivation_start_date: dateUtils.formatUTCISOToUserUTCISO(
          currentCulture.start_date,
          timeZone
        )
      };
      const response = await ShrimpGrowthService.fetchAbwVsBiomassGraphData(
        pondId,
        abwbiomassgraphqwery
      );
      context.commit("SET_ABW_VS_BIOMASS_GRAPH_DATA", response.data.data);
    },
    latestAbwValues: async function(context) {
      const pondId = context.getters.getSelectedPondId;
      const currentCulture = context.getters.getCurrentCulture;
      if (Object.keys(currentCulture).length === 0) {
        context.commit("SET_SELECTED_POND_ABW_SUMMARY", undefined);
        return;
      }
      if (currentCulture.is_running) {
        const abwquery = {
          no_of_records: 1
        };
        const response = await ShrimpGrowthService.fetchSelectedPondLatestNABWValue(
          pondId,
          abwquery
        );
        context.commit(
          "SET_SELECTED_POND_ABW_SUMMARY",
          response.data.pond.latest_records[0]
        );
        return;
      }
      const arrABWRecs = await context.dispatch(
        "pond/fetchPondABWValues",
        {
          pondId,
          ...[
            { dateProp: "end_date", paramKey: "from_date" },
            { dateProp: "end_date", paramKey: "to_date" }
          ].reduce((acc, param) => {
            acc[param.paramKey] = dateUtils.formatDate(
              dateUtils.parse(
                currentCulture[param.dateProp],
                dateUtils.isoFormatString,
                new Date()
              ),
              "yyyy-MM-dd"
            );
            return acc;
          }, {})
        },
        { root: true }
      );
      context.commit("SET_SELECTED_POND_ABW_SUMMARY", arrABWRecs[0]);
    },
    feedConsumptionData: async function(context) {
      const pondId = context.getters.getSelectedPondId;
      const currentCulture = context.getters.getCurrentCulture;
      const timeZone = context.rootGetters["user/getUserTimeZoneString"];
      if (!currentCulture.start_date && !currentCulture.end_date) {
        context.commit("SET_SELECTED_NET_FEED_SUMMARY", []);
        return;
      }
      const query = {
        from_date: dateUtils.formatUTCISOToUserUTCISO(
          currentCulture.start_date,
          timeZone
        ),
        to_date: dateUtils.formatUTCISOToUserUTCISO(
          currentCulture.end_date,
          timeZone
        )
      };
      const response = await ShrimpGrowthService.fetchFeedConsumptionSummary(
        pondId,
        query
      );
      context.commit("SET_SELECTED_NET_FEED_SUMMARY", response.data.data);
    },
    changeCulture: async function(context, cultureObj) {
      context.commit("SET_CURRENT_CULTURE", cultureObj);
      await Promise.all([
        context.dispatch("latestAbwValues"),
        context.dispatch("feedConsumptionData")
      ]);
    },
    fetchAllPondDetails: async function(context) {
      await context.dispatch("pond/fetchAllPonds");
      context.commit(
        "SET_POND_ID_POND_MAP",
        context.rootGetters["pond/pondsObj"]
      );
    },
    fetchSlotWiseFeed: async function(context, { deviceType, params }) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId !== undefined) {
        const response = await FeedManagementService.fetchSlotWisePondFeed(
          pondId,
          {
            ...params
          }
        );
        if (deviceType === "pond") {
          context.commit(
            "SET_POND_SLOT_WISE_FEED",
            response.data.pond_slotwise_feed_logs
          );
          context.commit(
            "SET_POND_SLOT_WISE_MANUAL_FEED",
            response.data.pond_manual_feed
          );
        } else if (deviceType === "pond_mother") {
          context.commit(
            "SET_PM_SLOT_WISE_FEED",
            response.data.pond_slotwise_feed_logs
          );
        }
      }
    },
    fetchHourWisePondFeed: async function(context, { deviceType, params }) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId !== undefined) {
        const response = await FeedManagementService.fetchHourWisePondFeed(
          pondId,
          { ...params }
        );
        if (deviceType === "pond") {
          context.commit(
            "SET_POND_HOURLY_WISE_FEED",
            response.data.pond_hourly_feed_logs
          );
        } else if (deviceType === "shrimp_talk") {
          context.commit(
            "SET_ST_HOURLY_WISE_FEED",
            response.data.pond_hourly_feed_logs
          );
        }
      }
    },
    changePondWithDeviceDetails: async function(
      context,
      pondWithDeviceDetails
    ) {
      context.commit(
        "SET_SELECTED_POND_WITH_DEVICE_DETAILS",
        pondWithDeviceDetails
      );
    },
    fetchManualFeedData: async function(context, { deviceType, params }) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId !== undefined) {
        const response = await context.dispatch(
          "pond/fetchAPondManualFeedingRecords",
          { pondId, params: { ...params } },
          { root: true }
        );
        context.commit("SET_POND_MANUAL_FEED", response);
      }
    },
    fetchStStatsData: async function(context, { stId, params, graph }) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId === undefined) return;
      const response = await ShrimpTalkService.fetchStStatsByPondIdAndStId(
        pondId,
        stId,
        params
      );
      context.commit("SET_ST_RESP_GRAPH_DATA", response.data.data);
      context.commit("SET_ST_OCF_GRAPH_DATA", response.data.data);
    },
    fetchStResponseQualityData: async function(context, { params }) {
      const pondId = context.getters.getSelectedPondId;
      const response = await ShrimpTalkService.fetchStReponseQuality(
        pondId,
        params
      );
      return response.data.data;
    },
    fetchHourlyDataUsingStore: async function(context, { params }) {
      const pondId = context.getters.getSelectedPondId;
      const response = await ShrimpTalkService.fetchHourlyDataUsingStoreService(
        pondId,
        params
      );
      return response.data.data;
    },
    fetchPondABWValues: async function(context, params) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId !== undefined) {
        const arrABWRecs = await context.dispatch(
          "pond/fetchPondABWValues",
          { pondId, ...params },
          { root: true }
        );
        context.commit("SET_SELECTED_POND_ABW_RECORDS", arrABWRecs);
      }
    },
    changeSelectedTab: async function(context, tabName) {
      context.commit("SET_SELECTED_TAB", tabName);
    },
    deleteABWRecord: async function(context, abwId) {
      await context.dispatch("pond/deleteABWRecord", abwId, { root: true });
      await context.dispatch("latestAbwValues");
    },
    fetchResourcesByPondId: async function(context, params = {}) {
      const pondId = context.getters.getSelectedPondId;
      const currentCulture = context.getters.getCurrentCulture;
      const timeZone = context.rootGetters["user/getUserTimeZoneString"];
      const query = {
        from_date: dateUtils.formatUTCISOToUserUTCISO(
          currentCulture.start_date,
          timeZone
        ),
        to_date: dateUtils.formatUTCISOToUserUTCISO(
          currentCulture.end_date,
          timeZone
        )
      };

      params.from_date = query.from_date;
      params.to_date = query.to_date;
      params.get_all = true;
      const response = await ResourceManagementService.fetchResourcesByPondId(
        pondId,
        params
      );
      context.commit(
        "SET_SELECTED_POND_MEDICINE_MINERALS",
        response.data.ponds_medicines_and_mineral_entries
      );
    },
    fetchLabTestsByPondId: async function(context, params = {}) {
      const pondId = context.getters.getSelectedPondId;
      const currentCulture = context.getters.getCurrentCulture;
      if (Object.keys(params).length === 0) {
        const query = {
          from_date: currentCulture.start_date,
          to_date: currentCulture.end_date
        };

        params.from_date = query.from_date;
        params.to_date = query.to_date;
        params.get_all = true;
      }

      const response = await ResourceManagementService.fetchLabTests(
        pondId,
        params
      );

      context.commit("SET_SELECTED_POND_LAB_TESTS", response.data.data);
    },
    fetchResourcesOfAllPonds: async function(context) {
      const response = await ResourceManagementService.fetchAllResources();
      context.commit(
        "SET_POND_MEDICINE_MINERALS",
        response.data.ponds_medicines_and_mineral_entries
      );
    },
    fetchHourlyFeedVsResponse: async function(context, params = {}) {
      const response = await ShrimpTalkService.fetchHourlyFeedVsResponse(
        params
      );
      context.commit("SET_HOURLY_FEED_VS_RESPONSE", response.data.data);
    }
  }
};
