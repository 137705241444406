/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondguard.js
Description: This file has the model,functions  of pondguard schedule object used in the pondlogs customer site
*/
/* eslint-disable */
class PondGuard {
  constructor() {
    this._id = "";
    this.pond_guard_id = "";
    this.title = "";
    this.code = "";
    this.pond_id = "";
    this.settings = {
      low_do_threshold_1: undefined,
      low_do_threshold_2: undefined
    };
    this.do_alerts_config = [
      {
        critical_lower_limit: undefined,
        lower_limit: undefined
      }
    ];
    this.temperature_alerts_config = [
      {
        lower_limit: undefined,
        upper_limit: undefined
      }
    ];
    this.status = "";
    this.user_id = "";
    this.created_at = "";
    this.updated_at = "";
  }

  castToPGObj(inputPGDetails) {
    Object.keys(inputPGDetails).forEach(key => {
      if (
        ["do_alerts_config", "temperature_alerts_config"].indexOf(key) > -1 &&
        inputPGDetails[key].length === 0
      ) {
        console.log(key, inputPGDetails[key].length);
        return;
      }

      this[key] = inputPGDetails[key];
    });
    this.pond_guard_id = this._id;
  }

  getPGPropertiesObj() {
    const propertyNameList = ["title", "pond_id", "code"];
    const properties = {};
    propertyNameList.forEach(property => {
      properties[property] = this[property];
    });
    return properties;
  }
}
export default PondGuard;
