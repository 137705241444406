/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: abw.js
Description: This file has the model,functions related to abw object used in the pondlogs customer site
*/
class ABW {
  constructor() {
    this.pond_id = "";
    this.last_abw = 0;
    this.last_abw_date = "--";
    this.present_date = new Date();
    this.samples = [new Samples()];
    this.abw = 1;
    this.weight_gain = undefined;
    this.survival_percentage = 100;
  }
}
class Samples {
  constructor() {
    this.name = "Sample";
    this.weight = undefined;
    this.no_of_shrimps = undefined;
    this.abw = 1;
  }
}
export { ABW, Samples };
