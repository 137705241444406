import { ABW } from "@/model/abw";
import { cloneDeep } from "lodash";
import PondsService from "@/services/PondsService";
import dateUtils from "@/utils/dateUtils";
import ShrimpSnapService from "../../../services/ShrimpSnapService";
export default {
  namespaced: true,
  state: {
    abwRecActionDialogMode: undefined,
    abwRecActionDialogModel: new ABW(),
    abwRecActionDialogVisibleStatus: false,
    dateRange: [],
    dateValueFormat: "yyyy-MM-dd",
    imageData: [],
    imageViewerInitialIndex: 0,
    listShrimpSnapDetails: [],
    loadingComponents: new Set(),
    manualABWRecords: [],
    selectedSS: undefined,
    selectedSSView: "LIST",
    sortImageSection: "DESC",
    ssABWGraphDate: undefined,
    ssABWRecords: [],
    visibleComponents: new Set()
  },
  getters: {
    getABWRecActionDialogMode(state) {
      return state.abwRecActionDialogMode;
    },
    getSSABWGraphDate(state) {
      const dateChoices = [
        state.ssABWGraphDate,
        state.dateRange.length > 0 && state.dateRange[1]
      ];
      return dateChoices.find(x => x);
    },
    getDateValueFormat(state) {
      return state.dateValueFormat;
    },
    getABWRecActionDialogVisibleStatus(state) {
      return state.abwRecActionDialogVisibleStatus;
    },
    getABWRecActionDialogModel(state) {
      return state.abwRecActionDialogModel;
    },
    getDateRange(state) {
      return state.dateRange;
    },
    getListOfSSDetails(state) {
      return cloneDeep(state.listShrimpSnapDetails);
    },
    getManualABWRecords(state) {
      return state.manualABWRecords;
    },
    getLoadingComponents(state) {
      return state.loadingComponents;
    },
    getVisibleComponents(state) {
      return state.visibleComponents;
    },
    getListOfSSAbwRecords(state, getters, rootState, rootGetters) {
      const selectedCulture = rootGetters["pondDetails/getCurrentCulture"];
      const getDOC = currDate => {
        return dateUtils.differenceInDays(
          dateUtils.parseISO(currDate),
          dateUtils.parseISO(selectedCulture.start_date)
        );
      };
      return state.imageData
        .map(eachDay => {
          return {
            date: eachDay.date,
            doc: getDOC(eachDay.date) + 1,
            day_abw: eachDay.day_abw ? eachDay.day_abw : undefined,
            max_abw: eachDay.max_abw,
            min_abw: eachDay.min_abw,
            samples: eachDay.shrimp_count ? eachDay.shrimp_count : undefined
          };
        })
        .sort((a, b) => dateUtils.dateComparator(a.date, b.date, true))
        .reduce((accDays, currDay, index) => {
          if (index === 0) {
            currDay.day_gain = undefined;
          } else {
            const lastDayABW = accDays[accDays.length - 1];
            const conditionToCalcDayGain = [
              Boolean(lastDayABW.day_abw),
              Boolean(currDay.day_abw),
              currDay.doc - lastDayABW.doc === 1
            ];
            if (conditionToCalcDayGain.every(x => x)) {
              currDay.day_gain = currDay.day_abw - lastDayABW.day_abw;
            } else {
              currDay.day_gain = undefined;
            }
          }
          accDays.push(currDay);
          return accDays;
        }, []);
    },
    getSSAbwRecordsIndexedOnDateEpoch(state, getters) {
      if (getters.getListOfSSAbwRecords.length === 0) return {};
      return getters.getListOfSSAbwRecords
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .reduce((accRecordsToIndex, currRecord) => {
          const dateEpoch = dateUtils.getTime(
            dateUtils.parse(
              currRecord.date,
              dateUtils.isoFormatString,
              new Date()
            )
          );
          if (!accRecordsToIndex[dateEpoch]) {
            accRecordsToIndex[dateEpoch] = currRecord;
          }
          return accRecordsToIndex;
        }, {});
    },
    getThumbnailsGroupedByDate(state) {
      return state.imageData;
    },
    getSelectedSortedOrder(state) {
      return state.sortImageSection;
    },
    getThumbnailsBySelectedSortedOrder(state, getters) {
      const mapSortKeyToSortFunction = {
        DESC: (a, b) => new Date(b.date) - new Date(a.date),
        ASC: (a, b) => new Date(a.date) - new Date(b.date)
      };
      const sortKey = getters.getSelectedSortedOrder;
      const arrElements = cloneDeep(getters.getThumbnailsGroupedByDate).sort(
        mapSortKeyToSortFunction[sortKey]
      );
      let photosLength = 0;
      return arrElements.map((eachDay, index) => {
        eachDay.images.sort((a, b) =>
          dateUtils.dateComparator(
            a.image_captured_date,
            b.image_captured_date,
            true
          )
        );
        if (index > 0) {
          photosLength += arrElements[index - 1].images.length;
        }
        eachDay.images.map((eachPhoto, index2) => {
          eachPhoto.carousel_id = photosLength + index2;
          return eachPhoto;
        });
        return eachDay;
      });
    },
    getPhotoUrlsByThumbnailSortOrder(state, getters) {
      return getters.getThumbnailsBySelectedSortedOrder
        .map(eachDay => eachDay.images.map(x => x.image_url))
        .flat(1);
    },
    getPhotoObjByThumbnailSortOrder(state, getters) {
      return getters.getThumbnailsBySelectedSortedOrder
        .map(eachDay => eachDay.images.map(x => x))
        .flat(1);
    },
    getImageViewerInitialIndex(state) {
      return state.imageViewerInitialIndex;
    },
    getSelectedSSView(state) {
      return state.selectedSSView;
    },
    getSelectedSS(state) {
      return state.selectedSS;
    },
    getSSAbwNearestToToday(state, getters) {
      return getters.getSSAbwRecordsIndexedOnDateEpoch[
        Object.keys(getters.getSSAbwRecordsIndexedOnDateEpoch)[length - 1]
      ];
    },
    getDateToAbwDistribution(state, getters) {
      return getters.getThumbnailsBySelectedSortedOrder.reduce(
        (accDayABWDistributions, currDay) => {
          const objABWDistributions = currDay.images.reduce(
            (accABWDistributions, currPhoto) => {
              currPhoto.shrimps_abw.forEach(currSampleABW => {
                const distributionKey = Math.floor(currSampleABW);
                if (!accABWDistributions[distributionKey]) {
                  accABWDistributions[distributionKey] = 0;
                }
                accABWDistributions[distributionKey]++;
              });
              return accABWDistributions;
            },
            {}
          );
          accDayABWDistributions[currDay.date] = Object.keys(
            objABWDistributions
          )
            .sort((a, b) => +a - +b)
            .map(keyABW => {
              return {
                // x: `${+keyABW}`,
                y: objABWDistributions[keyABW],
                name: `${+keyABW}`,
                tooltip_x_axis: `${+keyABW}-${+keyABW + 1}`
              };
              // [
              //   `${+keyABW}`,
              //   objABWDistributions[keyABW],
              //   `${+keyABW}-${+keyABW + 1}`
              // ];
              //
            });
          return accDayABWDistributions;
        },
        {}
      );
    },
    getSelectedPondInitialABW: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      let initialABW = 0;
      const pondId = rootGetters["pondDetails/getSelectedPondId"];
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const checkInitialABWIsInCulture = initialABWDate => {
        const currentCulture = rootGetters["pondDetails/getCurrentCulture"];
        if (currentCulture.start_date && currentCulture.end_date) {
          const dateArr = [
            currentCulture.start_date,
            currentCulture.end_date
          ].map(eachDate => {
            return dateUtils.utcToZonedTime(eachDate, userTimeZoneString);
          });
          const paramObj = {
            dateRange: dateArr,
            date: initialABWDate,
            timeZone: userTimeZoneString,
            actionsOnDate: [],
            actionsOnDateRangeItem: []
          };
          if (dateUtils.queryInDateRange(paramObj)) {
            return true;
          }
        }
        return false;
      };
      if (
        pondId &&
        rootGetters["pondDetails/getPondIdPondMap"][pondId] &&
        rootGetters["pondDetails/getPondIdPondMap"][pondId].initial_abw_id
      ) {
        const initialABWObj =
          rootGetters["pondDetails/getPondIdPondMap"][pondId].initial_abw_id;
        if (checkInitialABWIsInCulture(new Date(initialABWObj.date))) {
          initialABW = initialABWObj.abw;
        }
      }
      return initialABW;
    }
  },
  mutations: {
    SET_ABW_REC_ACTION_DIALOG_VISIBLE_STATUS(
      state,
      abwRecActionDialogVisibleStatus
    ) {
      state.abwRecActionDialogVisibleStatus = abwRecActionDialogVisibleStatus;
    },
    SET_ABW_REC_ACTION_DIALOG_MODE(state, abwRecActionDialogMode) {
      state.abwRecActionDialogMode = abwRecActionDialogMode;
    },
    SET_SHRIMP_SNAP_IMAGE_DATA(state, imageData) {
      state.imageData = imageData;
    },
    SET_ABW_REC_ACTION_DIALOG_MODEL(state, abwRecActionDialogModel) {
      state.abwRecActionDialogModel = abwRecActionDialogModel;
    },
    SET_DATE_RANGE: function(state, dateRange) {
      state.dateRange = dateRange;
    },
    SET_MANUAL_ABW_RECORDS: function(state, manualABWRecords) {
      state.manualABWRecords = manualABWRecords;
    },
    SET_SS_ABW_RECORDS: function(state, ssABWRecords) {
      state.ssABWRecords = ssABWRecords;
    },
    SET_SS_DETAILS: function(state, ssDetails) {
      state.listShrimpSnapDetails = Object.values(
        ssDetails.reduce((acc, curr) => {
          acc[curr.device_code] = curr;
          return acc;
        }, {})
      );
    },
    SET_SELECTED_SS_VIEW: function(state, selectedSSView) {
      state.selectedSSView = selectedSSView;
    },
    SET_SELECTED_SS: function(state, selectedSS) {
      state.selectedSS = selectedSS;
    },
    INSERT_A_LOADING_COMPONENT: function(state, loadingComponent) {
      state.loadingComponents = new Set(
        state.loadingComponents.add(loadingComponent)
      );
    },
    REMOVE_A_LOADING_COMPONENT: function(state, loadingComponent) {
      state.loadingComponents.delete(loadingComponent);
      state.loadingComponents = new Set(state.loadingComponents);
    },
    INSERT_A_VISIBLE_COMPONENT: function(state, visibleComponent) {
      state.visibleComponents = new Set(
        state.visibleComponents.add(visibleComponent)
      );
    },
    REMOVE_A_VISIBLE_COMPONENT: function(state, visibleComponent) {
      state.visibleComponents.delete(visibleComponent);
      state.visibleComponents = new Set(state.visibleComponents);
    },
    SET_IMAGE_VIEWER_INITIAL_INDEX: function(state, initialIndex) {
      state.imageViewerInitialIndex = initialIndex;
    },
    SET_SS_ABW_GRAPH_DATE: function(state, datePicked) {
      state.ssABWGraphDate = datePicked;
    }
  },
  actions: {
    changeABWRecActionDialogVisibilityStatus(
      { commit },
      abwRecActionDialogVisibleStatus
    ) {
      commit(
        "SET_ABW_REC_ACTION_DIALOG_VISIBLE_STATUS",
        abwRecActionDialogVisibleStatus
      );
    },
    changeABWRecActionDialogMode({ commit }, newActionDialogMode) {
      commit("SET_ABW_REC_ACTION_DIALOG_MODE", newActionDialogMode);
    },
    changeABWRecActionDialogModel({ commit }, abwRecord) {
      commit("SET_ABW_REC_ACTION_DIALOG_MODEL", abwRecord);
    },
    changeDateRange({ commit }, dateRange) {
      commit("SET_DATE_RANGE", dateRange);
    },
    async fetchManualABWRecords({ dispatch, commit, getters, rootGetters }) {
      try {
        const dateRange = getters.getDateRange;
        dispatch("addComponentToLoading", "ABW_TABLE");
        await dispatch(
          "pondDetails/fetchPondABWValues",
          {
            from_date: dateRange[0],
            to_date: dateRange[1],
            get_all: true
          },
          { root: true }
        );
        const initialABW = getters.getSelectedPondInitialABW;
        const abwRecords = cloneDeep(
          rootGetters["pondDetails/getArrSelectedPondABWRecords"]
        )
          .sort((a, b) => b.day_of_culture - a.day_of_culture)
          .map((rec, index) => {
            rec.isPopUpVisible = false;
            rec.index = index;
            rec.sample_total_weight = rec.samples.reduce((acc, curr) => {
              if (!acc) {
                acc = curr.weight;
              } else {
                acc += curr.weight;
              }
              return acc;
            }, undefined);
            rec.adg = (rec.abw - initialABW) / rec.day_of_culture;
            rec.awg = rec.abw - rec.last_abw;
            if (
              index ===
              rootGetters["pondDetails/getArrSelectedPondABWRecords"].length - 1
            ) {
              rec.awg = rec.abw;
            }
            return rec;
          });
        commit("SET_MANUAL_ABW_RECORDS", abwRecords);
        dispatch("removeComponentFromLoading", "ABW_TABLE");
      } catch (err) {
        dispatch("removeComponentFromLoading", "ABW_TABLE");
        throw err;
      }
    },
    async fetchShrimpSnapsByPondId(context) {
      try {
        const pondId = context.rootGetters["pondDetails/getSelectedPondId"];
        const dateRange = context.getters.getDateRange;
        context.dispatch("addComponentToLoading", "FETCH_SS_DETAILS");
        const response = await PondsService.fetchPondDevicesByDateRange(
          pondId,
          {
            device_type: "SHRIMP_SNAP",
            from_date: dateRange[0],
            to_date: dateRange[1]
          }
        );
        context.commit(
          "SET_SS_DETAILS",
          response.data.pond_device_mapping_logs
        );
        const firstShrimpSnap = context.getters.getListOfSSDetails[0];
        context.dispatch("changeSelectedSS", firstShrimpSnap);
        context.dispatch("removeComponentFromLoading", "FETCH_SS_DETAILS");
      } catch (err) {
        context.dispatch("removeComponentFromLoading", "FETCH_SS_DETAILS");
        throw err;
      }
    },
    async fetchShrimpSnapsImageData(context) {
      try {
        context.dispatch("addComponentToLoading", "FETCH_SS_IMAGE_DETAILS");
        if (!context.getters.getSelectedSS) {
          context.commit("SET_SHRIMP_SNAP_IMAGE_DATA", []);
          context.dispatch(
            "removeComponentFromLoading",
            "FETCH_SS_IMAGE_DETAILS"
          );
          return;
        }
        const dateRange = context.getters.getDateRange;
        const selectedShrimpSnap = context.getters.getSelectedSS.device_code;
        const response = await ShrimpSnapService.fetchImageData({
          from_date: dateRange[0],
          to_date: dateRange[1],
          device_code: selectedShrimpSnap
        });
        // "";
        context.commit("SET_SHRIMP_SNAP_IMAGE_DATA", response.data.data);
        context.dispatch(
          "removeComponentFromLoading",
          "FETCH_SS_IMAGE_DETAILS"
        );
      } catch (err) {
        context.dispatch(
          "removeComponentFromLoading",
          "FETCH_SS_IMAGE_DETAILS"
        );
        throw err;
      }
    },
    changeImageViewerInitialIndex({ commit }, carouselId) {
      commit("SET_IMAGE_VIEWER_INITIAL_INDEX", carouselId);
    },
    addComponentToLoading({ commit }, loadingComponent) {
      commit("INSERT_A_LOADING_COMPONENT", loadingComponent);
    },
    removeComponentFromLoading({ commit }, loadingComponent) {
      commit("REMOVE_A_LOADING_COMPONENT", loadingComponent);
    },
    addComponentToVisible({ commit }, visibleComponent) {
      commit("INSERT_A_VISIBLE_COMPONENT", visibleComponent);
    },
    removeComponentFromVisible({ commit }, visibleComponent) {
      commit("REMOVE_A_VISIBLE_COMPONENT", visibleComponent);
    },
    changeSelectedSSView({ commit }, selectedSSView) {
      commit("SET_SELECTED_SS_VIEW", selectedSSView);
    },
    changeSelectedSS({ commit }, selectedSS) {
      commit("SET_SELECTED_SS", selectedSS);
    },
    changeSSABWGraphDate({ commit }, datePicked) {
      commit("SET_SS_ABW_GRAPH_DATE", datePicked);
    }
  }
};
