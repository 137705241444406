<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: layoutToolbar.vue
Description:This file gives the abstract file(template layout file) used across the n number of the components
-->
<template>
  <el-row
    type="flex"
    :justify="justify"
    :align="align"
    :style="`--gap-units:${gap}px`"
    :class="`toolbar-layout ${seperator === 'line' ? 'line' : 'space'}`"
  >
    <slot></slot>
  </el-row>
</template>

<script>
export default {
  props: {
    justify: {
      default: "end"
    },
    align: {
      default: "middle"
    },
    seperator: {
      default: "space"
    },
    marginBottom: {
      default: "0px"
    },
    gap: {
      default: "5"
    }
  }
};
</script>

<style lang="scss">
.toolbar-layout {
  &.space > *:not(:last-child):not(.filler) {
    margin-right: var(--gap-units);
    align-self: center;
  }
  &.space > *:not(:first-child):not(.filler) {
    margin-left: var(--gap-units);
    align-self: center;
  }
  &.line > *:not(:last-child):not(.filler) {
    border-right: 1px solid #5b6271;
    padding-right: 5px;
    margin-right: 5px;
  }
  .filler {
    flex-grow: 1;
  }
}
</style>
