/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: feedInfoMod.js
Description: This file contains all the vuex store functions used in monitor page
*/
import dateUtils from "@/utils/dateUtils";
import FeedManagementService from "../../services/FeedManagementService";
import PondsService from "../../services/PondsService";

const feedInfoStore = {
  namespaced: true,
  state: {
    activePonds: [],
    feed: [],
    currLocation: {},
    datesInGraph: ["", ""],
    loading: true,
    feedConsumptionGrowth: {}
  },
  getters: {
    getActivePonds: state => state.activePonds,
    getActivePondsIds: state => state.activePonds.map(({ _id }) => _id),
    getPondToPondId: state =>
      state.activePonds.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {}),
    getFeedData: state => state.feed,
    getDatesInGraph: state => state.datesInGraph,
    getDateRangeForFeedGraph: state => {
      return state.datesInGraph;
    },
    getLoading: state => state.loading,
    getMinStartCultureDate: (state, getters, rootState, rootGetters) => {
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const arrDateObj = getters.getActivePonds.map(x =>
        dateUtils.dateActionsMap(
          x.cultivation_date,
          {
            action: "castUserUTCToBrowserTZDate",
            params: { timeZone: userTimeZoneString }
          },
          {
            timeZone: userTimeZoneString
          }
        )
      );
      const minDate = dateUtils.min(arrDateObj);
      return dateUtils.toISOString(
        dateUtils.isValid(minDate) ? minDate : new Date()
      );
    },
    getCurrLocation: state => {
      return state.currLocation;
    }
  },
  mutations: {
    SET_DATE_CHANGE(state, { date }) {
      state.datesInGraph = date;
    },
    SET_GRAPH_DATE: (state, { dateRange }) => {
      state.datesInGraph = dateRange;
    },
    SET_ACTIVE_PONDS_DATA(state, pondsData) {
      if (pondsData != null) {
        pondsData.forEach(pond => {
          pond.status = "ACTIVE";
          pond.cultivation_date =
            pond.cultivation_date || new Date().toISOString();
        });
        state.activePonds = pondsData;
      } else {
        state.activePonds = [];
      }
    },
    SET_SCHEDULE_FEED: (state, feedData) => {
      state.feed = feedData;
    },
    SET_CURR_LOCATION: (state, currLocation) => {
      state.currLocation = currLocation;
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    }
  },
  actions: {
    changeLocation: (context, locationObj) => {
      context.commit("SET_CURR_LOCATION", locationObj);
    },
    changeDateRangeInGraph: async (context, data = {}) => {
      context.commit("SET_DATE_CHANGE", data);
      context.commit("SET_LOADING", true);
      await context.dispatch("fetchScheduleFeedData");
    },
    fetchActivePonds: async context => {
      const currLocation = context.getters.getCurrLocation;
      const params = {
        location_id: currLocation._id,
        get_all: true,
        include: ["title", "cultivation_date", "units", "size"],
        status: ["ACTIVE"]
      };
      const response = await PondsService.fetchAllPonds(params);
      await context.commit("SET_ACTIVE_PONDS_DATA", response.data.ponds);
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const dateRange = dateUtils.getDateRangeFromRefDate({
        referenceDate: new Date(),
        distanceFromRefDate: [{ action: "subtract", params: [{ days: 10 }] }],
        timeZone: userTimeZoneString
      });
      await context.commit("SET_GRAPH_DATE", {
        dateRange: dateRange
      });
      await context.dispatch("fetchScheduleFeedData");
    },
    fetchScheduleFeedData: async context => {
      const date = context.getters.getDatesInGraph;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const currLocation = context.getters.getCurrLocation;
      const pondToPondId = context.getters.getPondToPondId;
      const params = {
        location_id: currLocation._id,
        from_date: date[0],
        to_date: date[1],
        get_all: true,
        include_mode: true
      };
      const res = await FeedManagementService.fetchAllPondsWithSchedFeedDetails(
        params
      );
      let formatedData = res.data.schedules.reduce(
        (
          acc,
          {
            pond_id,
            size,
            units,
            date,
            feed,
            dispensed_feed,
            mode,
            feed_per_acre,
            feed_per_hectare
          }
        ) => {
          if (!acc[pond_id]) {
            if (pondToPondId[pond_id] === undefined) return acc;
            acc[pond_id] = {
              pond_id,
              title: pondToPondId[pond_id].title,
              size: pondToPondId[pond_id].size,
              units: pondToPondId[pond_id].units,
              feedList: [],
              dispFeedList: [],
              modesList: [],
              values: []
            };
          }
          let newDate = dateUtils.dateActionsMap(
            date,
            {
              action: "castUserUTCToBrowserTZDate",
              params: { timeZone: userTimeZoneString }
            },
            {
              timeZone: userTimeZoneString
            }
          );
          newDate = dateUtils.toISOString(newDate);
          acc[pond_id].feedList.push({ newDate, feed });
          acc[pond_id].dispFeedList.push({ newDate, dispensed_feed });
          acc[pond_id].modesList.push({ newDate, mode });
          acc[pond_id].values.push({
            newDate,
            feed,
            dispensed_feed,
            feed_per_acre,
            feed_per_hectare,
            mode
          });
          return acc;
        },
        {}
      );
      formatedData = Object.values(formatedData).map(pond => {
        pond.values.sort((a, b) => a - b);
        return pond;
      });
      console.log("formatedData", formatedData);
      context.commit("SET_SCHEDULE_FEED", formatedData);
      context.commit("SET_LOADING", false);
    }
  }
};

export default feedInfoStore;
