<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erTextTag.vue
Description: This file is component which scroll text if the length of the given text exceeds the length of the word specified
-->
<template>
  <div :key="loading">
    <template v-if="textToScroll.length > +thresholdCharacters">
      <marquee scrolldelay="500" behavior="alternate">
        <slot></slot>
      </marquee>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    textToScroll: {
      required: true,
      default: "0"
    },
    thresholdCharacters: {
      required: true,
      default: 0
    },
    loading: {
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" >
</style>
