/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: usetService.js
Description: This file contains all API service calls in relation to User
*/
import { $axios } from "../config/axios";
import newAxios from "axios";
const richDataAxios = newAxios.create({});
export default {
  getProfilePictureAWSS3URL(params) {
    return $axios.get("/profile/company-logo-upload-url");
  },
  saveProfilePictureToTargetUrl(targetUrl, file, params = {}) {
    return richDataAxios.put(targetUrl, file);
  },
  informUploadStatusToBackend(fileId) {
    return $axios.patch(`/profile/company-logo-upload/${fileId}`);
  },

  // fetchZipReportsData({ responseType, ...params }) {
  //   return $axios.get(`/download-reports`, { params, responseType });
  // },
  fetchZipReportsData(payload) {
    return $axios.post(`/download-reports`, payload, {
      responseType: "arraybuffer"
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      //   "Content-Type": "multipart/form-data"
      // }
    });
  },
  updateGlobalSettings(params) {
    return $axios.put(`/global-settings`, params);
  },
  sendEmailToUser(params) {
    return $axios.get(`/email-reports`, { params });
  },
  fetchSubUserDetails(params) {
    return $axios.get(`/users/sub-users`, { params });
  },
  fetchSubUserPlainDetails(params) {
    return $axios.get(`/users/sub-users-plain`, { params });
  },
  updatePasswordSubUser(userId, payload) {
    return $axios.post(`/users/sub-users/${userId}/update-password`, payload);
  },
  updateSubUser(userId, payload) {
    return $axios.patch(`/users/sub-users/${userId}`, payload);
  },
  createSubUser(userData) {
    return $axios.post(`/users/sub-users`, userData);
  },
  notifyBackendOnLogoutService() {
    return $axios.post(`/logout`);
  }
};
