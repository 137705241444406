<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: App.vue
Description: This is typically the root of your application defined in Vue Component file format. It's usually something that defines the template for your page
-->
<template>
  <div id="pondlogs-app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data: function() {
    return {};
  },
  created() {
    this.$i18n.locale = localStorage.getItem(`lang`) || "en";
  },
  methods: {}
};
</script>

<style lang="scss">
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "./styles/main";
.el-message-box {
  .er-button.el-button--default:not(.is-circle) {
    padding: 2px 15px !important;
  }
}
.el-date-editor {
  .el-range__close-icon {
    display: none;
  }
}
:root {
  --header-height: 40px;
  --date-picker-width: 220px;
  @include respond-to(tablet) {
    --header-height: 40px;
    --date-picker-width: 220px;
  }
  @include respond-to(medium-screens) {
    --header-height: 45px;
    --date-picker-width: 220px;
  }
  @include respond-to(wide-screens) {
    --header-height: 50px;
    --date-picker-width: 330px;
  }
}

$fonts: (
  ("HelveticaNeue", 500, normal, "assets/fonts/HelveticaNeueLight.ttf"),
  ("HelveticaNeue", 600, normal, "assets/fonts/HelveticaNeueMedium.ttf"),
  ("HelveticaNeue", 700, bold, "assets/fonts/HelveticaNeueBold.ttf"),
  ("Lato", 500, normal, "assets/fonts/LatoRegular.ttf"),
  ("Lato", 700, bold, "assets/fonts/LatoBold.ttf")
);

@each $name, $weight, $style, $url in $fonts {
  @font-face {
    font-family: $name;
    font-weight: $weight;
    font-style: $style;
    src: url("#{$url}") format("truetype");
    font-display: swap;
  }
}
$font-family: "Lato", "HelveticaNeue", "Arial", "sans-serif";
* {
  margin: 0px;
  padding: 0px;
  outline: none;
  font-family: $font-family;
}

html,
body,
#pondlogs-app {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

hr {
  border-style: solid;
  border-width: 0.5px;
}

*::-webkit-scrollbar {
  background-color: #fff;
  width: 6px;
  height: 7.5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  border-color: transparent;
}
*::-webkit-scrollbar-button {
  display: none;
}
*::-webkit-scrollbar-thumb {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  margin: 0px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 5px solid transparent;
  background-color: rgba(144, 147, 153, 0.3);
  -webkit-transition: 0.3s background-color;
  transition: 0.3s background-color;
}

.el-menu--popup {
  a {
    text-decoration: none;
  }
}

/* change the size of the img icons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number  "]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.el-popper[x-placement^="bottom"] .popper__arrow {
  top: 0px;
  border: 0px;
  display: none;
}

.very-small-text {
  @include responsiveProperty(
    font-size,
    $app_font_size_v_small,
    $app_font_size_small,
    $app_font_size_1
  );
}
.small-text {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.normal-text {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
.captalize {
  text-transform: capitalize;
}
.material-icons-round,
.material-icons-outlined {
  @include icon-size(icon-font);
  &.icon__prefix--space {
    padding-right: 5px;
    @include icon-size(icon-font, suffix);
  }
  &.icon__suffix--space {
    padding-left: 5px;
    @include icon-size(icon-font, suffix);
  }
  &.icon__prefix,
  &.icon__suffix {
    @include icon-size(icon-font, suffix);
  }
}
.font-primary-color {
  color: $primary-color;
}
.align-text--left {
  text-align: left;
}
.align-item-left {
  margin-left: auto;
}
.align-text--right {
  text-align: right;
}
.align-item-right {
  margin-right: auto;
}
.align-text--center {
  text-align: center;
}
.bottom-margin {
  margin-bottom: 10px;
}
.el-loading-spinner {
  .el-loading-text {
    color: $primary-color;
  }
  .path {
    stroke: $primary-color;
  }
}
// form styles
.form-container {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}

.form-field-label {
  color: #909399;
  margin-bottom: 50px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-link.el-link--default {
  color: #606266;
  height: 25px;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.img-icon {
  @include responsiveProperty(width, 1rem, 1rem, 0.8rem);
  @include responsiveProperty(height, 1rem, 1rem, 0.8rem);
  vertical-align: middle;
}

.svg-icon-size {
  @include icon-size(svg);
}

.el-range-separator,
.el-input .el-input__inner,
.el-input-number .el-input__inner,
.el-date-editor .el-range-input {
  color: $primary-color;
}

.el-input:not(.el-pagination__editor):not(.el-date-editor),
.el-input-number {
  .el-input__inner {
    text-align: left;
  }
}

.el-input__inner,
.el-input__inner:hover,
.el-textarea__inner,
.el-textarea__inner:hover {
  border-color: #acc9f4;
}

// table global styling
.el-table {
  .cell {
    word-break: break-word;
  }
}

.el-table__empty-text {
  // display: inline-flex;
  flex-direction: column;
  line-height: 25px;
  padding: 25px 0px;
  .no-data__img {
    width: 50px;
    height: 50px;
  }
  .no-data__text {
    text-transform: capitalize;
  }
}

// error popup styles
.popup-error__header {
  .popup-error__header-title {
    font-weight: 600;
    @include small-text;
  }
}
.popup-error-lists {
  list-style-position: outside;
  overflow-y: initial;
  padding: 2px 10px;
  max-height: 100px;
  @include small-text;
  li {
    text-indent: 10px;
  }
  li:before {
    content: "";
    margin-right: -10px;
  }
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
.el-popper[x-placement^="bottom"] {
  margin-top: 0px;
}
// card default settings
.el-table__empty-text .no-data__text h4 {
  color: #000000cf;
  font-weight: 500;
}

.el-card {
  h3,
  h4 {
    font-weight: 500;
    color: #000000cf;
  }
  &.is-always-shadow,
  &.is-hover-shadow:focus,
  &.is-hover-shadow:hover {
    box-shadow: 1px 1px rgba(229, 236, 243, 0.8);
  }
}

.el-collapse-item__header.focusing:focus:not(:hover) {
  color: unset;
}
// highcharts graph stylingss
.highcharts {
  font-family: $font-family;
}
.highcharts-legend-item {
  font-family: $font-family;
  span {
    fill: red;
    font-family: $font-family !important;
  }
}
.highcharts-no-data span {
  font-family: $font-family !important;
  font-weight: 400 !important;
  color: #333 !important;
}
.el-notification__title {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
.el-notification__content {
  p {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  white-space: pre-wrap;
}
.el-select__tags-text {
  white-space: break-spaces;
}

.popup-error__header .popup-error__header-title {
  font-weight: 600;
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}

.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 110px;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}

// Table filters
.is-filters-set-absolute .table-filters-right {
  position: absolute;
  top: 6px;
  right: 0;
  width: 100%;
}
.is-filters-set-relative .table-filters-right {
  position: relative;
  top: -35px;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 20px;
  right: 10px;
}
// Date Picker - Default Style overriding
.el-month-table {
  td {
    padding: 4px 0;
    div {
      height: 36px;
    }
    .cell {
      font-size: 14px;
      height: 24px;
      line-height: 24px;
    }
  }
  .el-date-range-picker__content {
    padding: 10px 16px;
  }
}

.el-dropdown.height-auto {
  .er-button {
    height: auto !important;
  }
}
</style>
