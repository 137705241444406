/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: dealer.js
Description:  Contains all vuex store functions of dealer
*/
import { $superDealerAxios } from "../../config/axios";
import {
  saveAccessTokenToLocalStorage,
  saveUserDetailsToLocalStorage,
  setImpersonation,
  setImpersonationBy
} from "@/middleware/auth";
import localStore from "@/utils/localStore";
import dealerService from "../../services/dealerService";
const state = {
  dealerProfile: {},
  dealerUsersAndDevices: [],
  dealerassignedpms: [],
  dealerassignpgs: [],
  dealerassignsts: [],
  dealerassigngws: [],
  dealerunassignedpms: [],
  dealerUsers: [],
  dealerunassignedpgs: [],
  dealerunassignedsts: [],
  dealerunassignedgts: [],
  dealerSubscriptions: [],
  dealerSubscriptionReceivables: [],
  dealerCustomerSubscriptionReceivables: [],
  dealerCustomerPondShrmiptalks: []
};

const getters = {
  getDealerProfile: state => state.dealerProfile,
  getDealerUsersDevices: state => state.dealerUsersAndDevices,
  getDealerAssignedPms: state => state.dealerassignedpms,
  getDealerAssignedPgs: state => state.dealerassignpgs,
  getDealerAssignSts: state => state.dealerassignsts,
  getDealerAssignGws: state => state.dealerassigngws,
  getDealerUnassignPms: state => state.dealerunassignedpms,
  getDealerUsers: state => state.dealerUsers,
  getDealerUnassignPgs: state => state.dealerunassignedpgs,
  getDealerUnassignSts: state => state.dealerunassignedsts,
  getDealerUnassignGws: state => state.dealerunassignedgts,
  getDealerSubscriptions: state => state.dealerSubscriptions,
  getDealerSubscriptionReceivables: state =>
    state.dealerSubscriptionReceivables,
  getDealerCustomerSubscriptionReceivables: state =>
    state.dealerCustomerSubscriptionReceivables,
  getCustomerPondShrimptalks: state => state.dealerCustomerPondShrmiptalks
};

const mutations = {
  SET_USER_DATA(state, userData) {
    state.dealerProfile = userData;
  },
  SET_DEALER_USERS(state, dealerUsers) {
    state.dealerUsers = dealerUsers;
  },
  SET_DEALER_USERS_DEVICES(state, dealerUsersAndDevices) {
    state.dealerUsersAndDevices = dealerUsersAndDevices;
  },
  SET_DEALER_ASSIGNED_POND_MOTHERS(state, dealerassignedpms) {
    state.dealerassignedpms = dealerassignedpms;
  },
  SET_DEALER_ASSIGNED_POND_GUARDS(state, dealerassignpgs) {
    state.dealerassignpgs = dealerassignpgs;
  },
  SET_DEALER_ASSIGNED_SHRIMP_TALKS(state, dealerassignsts) {
    state.dealerassignsts = dealerassignsts;
  },
  SET_DEALER_ASSIGNED_GATEWAYS(state, dealerassigngws) {
    state.dealerassigngws = dealerassigngws;
  },
  SET_DEALER_UNASSIGNED_POND_MOTHERS(state, dealerunassignedpms) {
    state.dealerunassignedpms = dealerunassignedpms;
  },
  SET_DEALER_UNASSIGNED_POND_GUARDS(state, dealerunassignedpgs) {
    state.dealerunassignedpgs = dealerunassignedpgs;
  },
  SET_DEALER_UNASSIGNED_SHRIMP_TALKS(state, dealerunassignedsts) {
    state.dealerunassignedsts = dealerunassignedsts;
  },
  SET_DEALER_UNASSIGNED_GATEWAYS(state, dealerunassignedgts) {
    state.dealerunassignedgts = dealerunassignedgts;
  },
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.dealerSubscriptions = subscriptions;
  },
  SET_SUBSCRIPTION_RECEIVABLES(state, subscriptionReceivables) {
    state.dealerSubscriptionReceivables = subscriptionReceivables;
  },
  SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES(state, subscriptionReceivables) {
    state.dealerCustomerSubscriptionReceivables = subscriptionReceivables;
  },

  SET_CUSTOMER_POND_SHRIMPTALKS(state, pondShrimptalks) {
    state.dealerCustomerPondShrmiptalks = pondShrimptalks;
  }
};

const actions = {
  async loginAsDealer(context) {
    const userId = localStore.getItem(`DEALER-id`);
    const credentials = localStore.getItem(`DEALER-${userId}`);
    console.log(credentials);
    if (credentials === undefined) {
      throw new Error("No Keys Available");
    }
    $superDealerAxios.defaults.headers.common.Authorization =
      credentials.access_token;
    await context.dispatch("fetchUserProfile");
    const userProfile = context.getters.getDealerProfile;
    saveUserDetailsToLocalStorage(userProfile);
    saveAccessTokenToLocalStorage(credentials);
    context.commit("auth/SET_AUTH_STATUS", true, {
      root: true
    });
  },
  async dealerNotifyBackendOnLogout() {
    await $superDealerAxios.post(`/admin/users/logout`);
  },
  async LoginAsUser(context, userId) {
    setImpersonation(true);
    setImpersonationBy("DEALER");
    await context.dispatch("fetchUserToken", { userId, params: {} });
  },
  fetchUserToken: async function(context, { userId, params }) {
    const response = await $superDealerAxios.get(
      `/dealer/users/${userId}/signin`,
      {
        params
      }
    );
    // saving accesstoken and refresh in the local storage
    const { user_details, access_token, refresh_token } = response.data;
    saveUserDetailsToLocalStorage(user_details);
    saveAccessTokenToLocalStorage({
      access_token,
      refresh_token
    });
    // saving the user data from the response
    context.commit("SET_USER_DATA", response.data.user_details);
    context.commit("auth/SET_AUTH_STATUS", true, {
      root: true
    });
    return response.data;
  },
  fetchUserProfile: async context => {
    const response = await $superDealerAxios.get("/dealer/profile");
    context.commit("SET_USER_DATA", response.data.profile);
    const userDetails = response.data.profile;
    return userDetails;
  },
  fetchDealerRelatedUsers: async (context, { params = {} }) => {
    const response = await $superDealerAxios.get("dealer/users", { params });
    console.log(response);
    context.commit("SET_DEALER_USERS", response.data.users);
    return response.data;
  },
  fetchDealerUsersAndDevices: async (context, { params }) => {
    // params.get_all=true,
    params.include_device_counts = true;
    params.order_by = "first_name";
    params.order_type = "asc";
    const response = await $superDealerAxios.get("dealer/users", { params });
    context.commit("SET_DEALER_USERS_DEVICES", response.data.users);
    return response.data;
  },
  fetchDealerAssignedDevices: async (
    context,
    { device_type, infinite_scroll = false, params = {} }
  ) => {
    const urlForDevice = {
      gateway: "/dealer/gateways",
      pond_mother: "/dealer/pond-mothers",
      pond_guard: "/dealer/pond-guards",
      shrimp_talk: "/dealer/shrimp-talks"
    };
    const settersStr = {
      gateway: "SET_DEALER_ASSIGNED_GATEWAYS",
      pond_mother: "SET_DEALER_ASSIGNED_POND_MOTHERS",
      pond_guard: "SET_DEALER_ASSIGNED_POND_GUARDS",
      shrimp_talk: "SET_DEALER_ASSIGNED_SHRIMP_TALKS"
    };
    const responseDeviceKey = {
      gateway: "gateways",
      pond_mother: "pond_mothers",
      pond_guard: "pond_guards",
      shrimp_talk: "shrimp_talks"
    };
    params.include_user_details = true;
    params.order_by = "code";
    params.order_type = "asc";
    const response = await $superDealerAxios.get(urlForDevice[device_type], {
      params
    });
    const setter = settersStr[device_type];
    context.commit(setter, response.data[responseDeviceKey[device_type]]);
    return response.data;
  },
  fetchAllUnassignedDealerDevices: async (
    context,
    { device_type, infinite_scroll = false, params = {} }
  ) => {
    const urlForDevice = {
      gateway: "/dealer/gateways",
      pond_mother: "/dealer/pond-mothers",
      pond_guard: "/dealer/pond-guards",
      shrimp_talk: "/dealer/shrimp-talks"
    };
    const settersStr = {
      gateway: "SET_DEALER_UNASSIGNED_GATEWAYS",
      pond_mother: "SET_DEALER_UNASSIGNED_POND_MOTHERS",
      pond_guard: "SET_DEALER_UNASSIGNED_POND_GUARDS",
      shrimp_talk: "SET_DEALER_UNASSIGNED_SHRIMP_TALKS"
    };

    const responseDeviceKey = {
      gateway: "gateways",
      pond_mother: "pond_mothers",
      pond_guard: "pond_guards",
      shrimp_talk: "shrimp_talks"
    };

    // params.order_type = "asc";
    params.un_assigned_only = true;
    // params.get_all = true;

    const response = await $superDealerAxios.get(urlForDevice[device_type], {
      params
    });
    const setter = settersStr[device_type];
    context.commit(setter, response.data[responseDeviceKey[device_type]]);
    return response.data;
  },
  addGateWayToUser: async (context, payload) => {
    const response = await $superDealerAxios.post(
      "dealer/users/gateways",
      payload
    );
    return response;
  },
  addShrimpTalkToUser: async (context, payload) => {
    const response = await $superDealerAxios.post(
      "dealer/users/shrimp-talks",
      payload
    );
    return response;
  },
  addPondGuardToUser: async (context, payload) => {
    const response = await $superDealerAxios.post(
      "dealer/users/pond-guards",
      payload
    );
    return response;
  },
  addPondMotherToUser: async (context, payload) => {
    const response = await $superDealerAxios.post(
      "dealer/users/pond-mothers",
      payload
    );
    return response;
  },
  deletePondMotherFromUser: async (context, payload) => {
    const data = payload;
    const response = await $superDealerAxios.delete(
      "dealer/users/pond-mothers",
      { data }
    );
    return response;
  },
  deletePondGuardFromUser: async (context, payload) => {
    const data = payload;
    const response = await $superDealerAxios.delete(
      "dealer/users/pond-guards",
      { data }
    );
    return response;
  },
  deleteShrimpTalkFromUser: async (context, payload) => {
    const data = payload;
    const response = await $superDealerAxios.delete(
      "dealer/users/shrimp-talks",
      { data }
    );
    return response;
  },
  deleteGatewayFromUser: async (context, payload) => {
    const data = payload;
    const response = await $superDealerAxios.delete("dealer/users/gateways", {
      data
    });
    return response;
  },
  fetchAllSubscriptions: async (context, query) => {
    const response = await dealerService.fetchAllSubscriptions(
      "/dealer/shrimp-talk-subscription-plans",
      {
        query
      }
    );
    context.commit("SET_SUBSCRIPTIONS", response.data.subscription_plans);
    return response;
  },
  fetchAllSubscriptionReceivables: async (context, query) => {
    const response = await dealerService.fetchSubscriptionReceivables(query);

    context.commit(
      "SET_SUBSCRIPTION_RECEIVABLES",
      response.data.shrimptalk_subscription_receivables
    );
    return response;
  },
  fetchCustomerSubscriptionReceivables: async (context, params) => {
    try {
      const response = await dealerService.fetchCustomerSubscriptionReceivables(
        params.id,
        params.query
      );

      context.commit(
        "SET_CUSTOMER_SUBSCRIPTION_RECEIVABLES",
        response.data.data
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  },
  fetchCustomerPondShrimptalks: async (context, params) => {
    try {
      const response = await dealerService.fetchCustomerPondShrimptalks(
        params.id,
        params.query
      );

      context.commit("SET_CUSTOMER_POND_SHRIMPTALKS", response.data.data);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
