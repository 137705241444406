/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pmSchedule.js
Description: This file has the model,functions  of pond mother schedule object used in the pondlogs customer site
*/
import { cloneDeep } from "lodash";
import { PM_TS_STATUS, PM_MODES, TS_PM_MODES } from "@/constants/schedule";
import PondMotherTS from "@/model/pondMotherTS";
import { alphaNumericComparator } from "@/utils/commonUtils";
import dateUtils from "@/utils/dateUtils";
class PmSchedule {
  constructor(pond_mother_id, managed_by) {
    this.pond_mother_id = pond_mother_id;
    this.code = "";
    this.title = "";
    this.pond_id = undefined;
    this.managed_by = managed_by;
    this.total_modes_changed = 0;
    this.obj_ts_modes = {};
    this.modified = undefined;
    this.feed = 0;
    this.dispensed_feed = 0;
    this.start_time = "";
    this.end_time = "";
    this.date = "";
    this.formatted_date = "";
    this.time_slots = [];
    this.feeding_level = 0;
  }

  set from_time({ dayInSecs, userTimeZoneString, format }) {
    this.start_time = dateUtils.formatTZ(dayInSecs, format, {
      timeZone: userTimeZoneString
    });
  }

  set to_time({ dayInSecs, userTimeZoneString, format }) {
    this.end_time = dateUtils.formatTZ(dayInSecs, format, {
      timeZone: userTimeZoneString
    });
  }

  set setFormattedScheduleDate({ dayInSecs, userTimeZoneString, format }) {
    this.formatted_date = dateUtils.formatTZ(dayInSecs, format, {
      timeZone: userTimeZoneString
    });
  }

  reCalculateScheduleFeed() {
    const totalFeed = this.time_slots.reduce((acc, curr) => {
      return acc + curr.feed;
    }, 0);
    this.feed = totalFeed;
  }

  clone(schedule) {
    this._id = schedule._id;
    this.pond_mother_id = schedule.pond_mother_id;
    this.date = schedule.date;
    this.time_slots = cloneDeep(schedule.time_slots);
  }

  setTotalFeed() {
    const managedByToFeedField = combination => {
      switch (combination) {
        case `${PM_MODES.AUTOMATIC}_${PM_MODES.AUTOMATIC}`:
        case `${PM_MODES.SCHEDULE}_${PM_MODES.SCHEDULE}`:
        case `${PM_MODES.BASIC}_${PM_MODES.BASIC}`:
          return "feed";
        case `${PM_MODES.AUTOMATIC}_${PM_MODES.SCHEDULE}`:
          return "feed";
        default:
          return "feed";
      }
    };
    const time_slots_length = this.time_slots.length;
    this.feed = cloneDeep(this.time_slots)
      .map((pm, index) => ({
        feed: pm.feed,
        dispensed_feed: pm.dispensed_feed,
        managed_by: PM_MODES[pm.mode], // pm.managed_by,
        feeding_level: pm.feeding_level === -1 ? 0 : pm.feeding_level,
        future_managed_by:
          PM_MODES[
            this.time_slots[
              index + 1 < time_slots_length ? index + 1 : time_slots_length - 1
            ].mode
          ], // .managed_by,
        status: pm.status
      }))
      .reduce((acc, curr) => {
        return (
          acc +
          curr[
            managedByToFeedField(`${curr.managed_by}_${curr.future_managed_by}`)
          ]
        );
      }, 0);
  }

  setLatestMode() {
    this.managed_by = cloneDeep(this.time_slots).reverse()[0].managed_by;
  }

  setModesChangedOnADay() {
    this.obj_ts_modes = this.time_slots.reduce((acc, curr) => {
      if (!acc.has(curr.managed_by)) {
        acc.set(curr.managed_by, {
          ts_s_secs: curr.s_time_secs,
          ts_s_str: curr.s_time,
          ts_e_secs: curr.e_time_secs,
          ts_e_str: curr.e_time
        });
      }
      if (acc.get(curr.managed_by).ts_s_secs > curr.s_time_secs) {
        acc.get(curr.managed_by).ts_s_secs = curr.s_time_secs;
        acc.get(curr.managed_by).ts_s_str = curr.s_time;
      }
      if (acc.get(curr.managed_by).ts_e_seconds < curr.e_time_secs) {
        acc.get(curr.managed_by).ts_e_secs = curr.e_time_secs;
        acc.get(curr.managed_by).ts_e_str = curr.e_time;
      }
      return acc;
    }, new Map());
    this.total_modes_changed = this.obj_ts_modes.size;
  }

  setModifiedStatus() {
    const doPmHasModifiedTS = this.time_slots.find(
      ts => (ts.modified || { status: false }).status === true
    );
    const length = this.time_slots.length;
    const lastPmModifiedTS = this.time_slots[length - 1] || {};
    this.modified = (doPmHasModifiedTS || lastPmModifiedTS).modified;
  }

  setDispensedFeed() {
    this.dispensed_feed = this.time_slots
      .map(pm => pm.dispensed_feed)
      .reduce((curr, prev) => prev + curr);
  }

  static transformTSPMHierachyToPMTSHierachy(
    schedule,
    objPmIdToPmTitle,
    page = ""
  ) {
    const arrTSPMHierachy = schedule.time_slots;
    const objPmIdToPmSchedule = arrTSPMHierachy
      .map(ts => {
        return ts.pond_mothers.map(pm => {
          const pmTS = pm;
          pmTS.ts_id = ts._id;
          pmTS.s_time = ts.s_time;
          pmTS.e_time = ts.e_time;
          pmTS.s_time_secs = ts.s_time_in_seconds;
          pmTS.e_time_secs = ts.e_time_in_seconds;
          pmTS.managed_by = pm.managed_by;
          return pmTS;
        });
      })
      .flat(1)
      .sort((a, b) => {
        return alphaNumericComparator(a.pond_mother_code, b.pond_mother_code);
      })
      .sort((a, b) => {
        return a.s_time_secs - b.s_time_secs;
      })
      .reduce((acc, curr) => {
        let pmObj = acc[curr.pond_mother_id];
        if (!pmObj) {
          pmObj = new PmSchedule(curr.pond_mother_id, undefined);
          pmObj.code = curr.pond_mother_code;
          pmObj.title = objPmIdToPmTitle[curr.pond_mother_id];
          pmObj.pond_id = schedule.pond_id;
          if (page !== "") {
            pmObj.feeding_level =
              schedule.pm_feeding_level[curr.pond_mother_id];
          }
        }
        pmObj.time_slots.push(curr);
        acc[curr.pond_mother_id] = pmObj;
        return acc;
      }, {});
    return Object.values(objPmIdToPmSchedule).reduce((acc, pmSchedule) => {
      pmSchedule.setLatestMode();
      pmSchedule.setModesChangedOnADay();
      pmSchedule.setTotalFeed();
      pmSchedule.setModifiedStatus();
      pmSchedule.setDispensedFeed();
      acc.push(pmSchedule);
      return acc;
    }, []);
  }

  getBKFormat(requestType) {
    const bkPayload = {
      pond_mother_id: this.pond_mother_id,
      managed_by: this.managed_by,
      time_slots: this.time_slots
    };
    switch (this.managed_by) {
      case "HYBRID":
      case PM_MODES.AUTOMATIC:
        bkPayload.shrimp_talk_id = this.shrimp_talk_id || undefined;
    }
    switch (requestType) {
      case "CREATE":
        bkPayload.start_time = this.start_time;
        bkPayload.end_time = this.end_time;
        break;
    }
    return bkPayload;
  }

  static prepareFutureDayBKSchedules(
    iterDay,
    selectedDay,
    selectedDayPmTS,
    pondId,
    pmId,
    pmMode,
    pmStDetails,
    isIterDayIsCurrentDay,
    iterDayPondSched,
    iterDayBKPmExistSchedDetails,
    userTimeZoneString,
    backSchedDateFormat
  ) {
    let validTimeSlots = Object.values(selectedDayPmTS);
    validTimeSlots = PondMotherTS.getAllValidPmTSFromArrPmTSByTime(
      validTimeSlots
    );
    // console.log("validTimeSlots---11", validTimeSlots);
    const schedule = new PmSchedule(pmId, pmMode);
    if (pmMode === PM_MODES.AUTOMATIC || pmMode === "HYBRID") {
      schedule.shrimp_talk_id = pmStDetails?.shrimp_talk_id
        ? pmStDetails.shrimp_talk_id
        : undefined;
    }
    schedule.pond_mother_id = pmId;
    schedule.from_time = {
      dayInSecs: iterDay,
      userTimeZoneString,
      format: backSchedDateFormat
    };
    schedule.to_time = {
      dayInSecs: iterDay,
      userTimeZoneString,
      format: backSchedDateFormat
    };
    schedule.time_slots = validTimeSlots.map(
      pmTS =>
        pmTS.castToBackendObjType2(
          pmTS,
          pmTS.mode,
          pmStDetails,
          "NEW",
          userTimeZoneString,
          pmMode
        ) // pmMode,
    );
    // console.log("schedule--", schedule);
    return schedule.time_slots.length > 0
      ? {
          CREATE: schedule.getBKFormat("CREATE")
        }
      : {
          CLEAR_FUTURE_SCHEDULES: {
            pmId,
            pondId,
            doRefreshTableData: false
          }
        };
  }

  static prepareCurrDayBKSchedules(
    iterDay,
    selectedDay,
    selectedDayPmTS,
    pondId,
    pmId,
    pmMode,
    pmStDetails,
    isIterDayIsCurrentDay,
    iterDayPondSched,
    iterDayBKPmExistSchedDetails,
    userTimeZoneString,
    backSchedDateFormat
  ) {
    let validTimeSlots = Object.values(selectedDayPmTS);
    const allUiTimeSlots = Object.values(selectedDayPmTS);
    validTimeSlots = PondMotherTS.getAllValidPmTSFromArrPmTS(validTimeSlots);
    // console.log("validTimeSlots---", validTimeSlots);
    // pm not in any timeslots
    let deletedBackendTSId;
    if (iterDayBKPmExistSchedDetails && +iterDay === +selectedDay) {
      const iterDayPmExistTSIds = iterDayBKPmExistSchedDetails.time_slots.reduce(
        (acc, currTS) => {
          if (
            isIterDayIsCurrentDay &&
            [PM_TS_STATUS.TO_BE_RUN].indexOf(currTS.status) > -1
          ) {
            acc.push(currTS.ts_id);
          } else if (!isIterDayIsCurrentDay) {
            acc.push(currTS.ts_id);
          }
          return acc;
        },
        []
      );
      // console.log("iterDayPmExistTSIds", iterDayPmExistTSIds, allUiTimeSlots, validTimeSlots);
      const uiBkTSIds = allUiTimeSlots.reduce((acc, curr) => {
        if (curr.bk_id) {
          acc.push(curr.bk_id);
        }
        return acc;
      }, []);
      // if Multiple Days are true
      const deleteTSWhenModeChange = validTimeSlots.reduce((acc, ts) => {
        if (TS_PM_MODES[ts.mode] !== TS_PM_MODES[ts.previousMode]) {
          acc.push(ts.bk_id);
        }
        return acc;
      }, []);
      // console.log("deleteTSWhenModeChange", deleteTSWhenModeChange, validTimeSlots);
      deletedBackendTSId = iterDayPmExistTSIds.reduce((acc, id) => {
        // console.log("deletedBackendTSId", uiBkTSIds.indexOf(id), uiBkTSIds.indexOf(id) === -1);
        if (
          uiBkTSIds.indexOf(id) === -1 ||
          deleteTSWhenModeChange.indexOf(id) !== -1
        ) {
          acc.push(id);
        }
        return acc;
      }, []);
      // console.log("deleteTSWhenModeChange", deletedBackendTSId);
      deletedBackendTSId = deletedBackendTSId.map(x => ({
        pond_mother_id: pmId,
        time_slot_id: x,
        schedule_id: iterDayPondSched._id
      }));
      const time_slots = validTimeSlots.reduce(
        (acc, ts) => {
          const hasMatchedTS = ts.bk_id;
          const action =
            hasMatchedTS &&
            TS_PM_MODES[ts.mode] === TS_PM_MODES[ts.previousMode]
              ? "UPDATE"
              : "CREATE";
          const { s_time, e_time } = ts.getStimeEtimeBasedOnMode(
            userTimeZoneString
          );
          // console.log("ts.mode--", ts);
          let payload = {};
          if (pmMode === "HYBRID") {
            if (
              (TS_PM_MODES[ts.mode] === "SHRIMP_TALK" && hasMatchedTS) ||
              (TS_PM_MODES[ts.mode] === "SHRIMP_TALK" && !hasMatchedTS)
            ) {
              payload = {
                s_time: ts.s_time,
                e_time: ts.e_time,
                feed: ts.feed,
                mode: ts.mode,
                managed_by: TS_PM_MODES[ts.mode],
                feeding_level: ts.feeding_level === -1 ? 0 : ts.feeding_level,
                running_mode: "HYBRID"
              };
            } else {
              payload = {
                s_time: s_time,
                e_time: e_time,
                feed: ts.feed,
                mode: ts.mode,
                managed_by: TS_PM_MODES[ts.mode],
                // feeding_level: ts.feeding_level === -1 ? 0 : ts.feeding_level,
                running_mode: "HYBRID"
              };
            }
          } else {
            payload = {
              s_time: s_time,
              e_time: e_time,
              feed: ts.feed,
              mode: ts.mode,
              managed_by: TS_PM_MODES[ts.mode]
              // feeding_level: ts.feeding_level
            };
          }
          // console.log("ts.mode--22", payload, pmMode);
          if (action === "UPDATE") {
            payload = {
              schedule_id: iterDayPondSched._id,
              time_slot_id: ts.bk_id,
              pond_mother_id: pmId,
              ...payload
            };
          }
          if (TS_PM_MODES[ts.mode] !== PM_MODES.AUTOMATIC) {
            payload.ocf = ts.ocf;
            payload.feed_gap =
              ts.getFeedGapBasedOnTSStatus(userTimeZoneString) * 60;
          }
          acc[action].push(payload);
          return acc;
        },
        { UPDATE: [], CREATE: [] }
      );
      let schedule;
      if (time_slots.CREATE.length > 0) {
        schedule = new PmSchedule(pmId, pmMode);
        if (pmMode === PM_MODES.AUTOMATIC || pmMode === "HYBRID") {
          schedule.shrimp_talk_id = pmStDetails?.shrimp_talk_id
            ? pmStDetails.shrimp_talk_id
            : undefined;
        }
        schedule.pond_mother_id = pmId;
        schedule.from_time = {
          dayInSecs: iterDay,
          userTimeZoneString,
          format: backSchedDateFormat
        };
        schedule.to_time = {
          dayInSecs: iterDay,
          userTimeZoneString,
          format: backSchedDateFormat
        };
        schedule.time_slots = time_slots.CREATE;
        return {
          DELETE_BK_PM_TS: deletedBackendTSId,
          UPDATE: time_slots.UPDATE,
          CREATE: schedule.getBKFormat("CREATE")
        };
      }
      return {
        DELETE_BK_PM_TS: deletedBackendTSId,
        UPDATE: time_slots.UPDATE
      };
    } else {
      if (
        iterDayBKPmExistSchedDetails &&
        iterDayBKPmExistSchedDetails.time_slots.length > 0
      ) {
        deletedBackendTSId = iterDayBKPmExistSchedDetails.time_slots.reduce(
          (acc, currTS) => {
            acc.push(currTS.ts_id);
            return acc;
          },
          []
        );
        deletedBackendTSId = deletedBackendTSId.map(x => ({
          pond_mother_id: pmId,
          time_slot_id: x,
          schedule_id: iterDayPondSched._id
        }));
      }
      const schedule = new PmSchedule(pmId, pmMode);
      if (pmMode === PM_MODES.AUTOMATIC || pmMode === "HYBRID") {
        schedule.shrimp_talk_id = pmStDetails?.shrimp_talk_id
          ? pmStDetails.shrimp_talk_id
          : undefined;
      }
      schedule.pond_mother_id = pmId;
      schedule.from_time = {
        dayInSecs: iterDay,
        userTimeZoneString,
        format: backSchedDateFormat
      };
      schedule.to_time = {
        dayInSecs: iterDay,
        userTimeZoneString,
        format: backSchedDateFormat
      };
      schedule.time_slots = validTimeSlots.map(pmTS =>
        pmTS.castToBackendObjType2(
          pmTS,
          pmTS.mode, // pmMode,
          pmStDetails,
          "NEW",
          userTimeZoneString,
          pmMode
        )
      );
      return {
        CREATE: schedule.getBKFormat("CREATE"),
        DELETE_BK_PM_TS: deletedBackendTSId
      };
    }
  }
}

export default PmSchedule;
