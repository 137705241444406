<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: header.vue
Description: This file contains header component of the pondlogs customer end site. It contains language selection, location selection and profile selection menu
-->
<template>
  <div class="dashboard-header">
    <div class="dashboard-header-left">
      <portal-target name="destination"></portal-target>
    </div>
    <div class="dashboard-header-right">
      <portal-target name="rightdestination"></portal-target>
      <el-popover
        placement="bottom"
        width="350"
        :trigger="'hover'"
        v-model="notificationCountVisible"
        ref="h__featurePopper"
        :disabled="getNotificationCount === 0"
        class="feature-notification-popper-trigger"
      >
        <notification-view :item-list="getFeaturesList"></notification-view>
        <er-button class="btn-notification" slot="reference" type="text">
          <el-badge
            :value="getNotificationCount"
            :hidden="getNotificationCount === 0"
            :max="9"
            class="item"
          >
            <span class="notification-icon-container">
              <span
                :class="[
                  'material-icons-round',
                  'notification-active-icon',
                  isfeaturePopperVisible() && 'is-active'
                ]"
              >
                fact_check
              </span>
              <span
                :class="[
                  'material-icons-outlined',
                  'notification-icon',
                  !isfeaturePopperVisible() && 'is-active'
                ]"
              >
                fact_check
              </span>
            </span>
          </el-badge>
        </er-button>
      </el-popover>
      <er-dropdown
        placement="bottom"
        v-if="doUserHasLocations"
        trigger="click"
        :listOfItems="computedUserLocations"
        popper-class="locations-dropdown"
        @item-click="handleChangeInLocation"
      >
        <template slot="prefix">
          <span class="material-icons-outlined"> pin_drop </span>
        </template>
        <template slot="title">
          <p>{{ currUserLocation.name }}</p>
        </template>
        <template v-slot:item-title="{ item }">
          <router-link
            class="location-link"
            :to="{
              query: routeQuery({ location_id: item.value._id })
            }"
            tag="li"
          >
            {{ item.value.name }}
          </router-link>
        </template>
      </er-dropdown>
      <er-dropdown
        placement="bottom"
        @item-click="handleChangeInLanguage"
        trigger="click"
        :listOfItems="langs"
      >
        <template slot="prefix">
          <span class="material-icons-outlined"> g_translate </span>
        </template>
        <template slot="title">
          <p>{{ langSelected }}</p>
        </template>
      </er-dropdown>
      <er-dropdown
        placement="bottom"
        class="profile-drop-down"
        trigger="click"
        :listOfItems="profileDropDownItems"
        popper-class="profile-dropdown"
      >
        <template slot="prefix">
          <p class="header-profile-placeholder" v-if="!getProfileUrl">
            {{ getFirstCharsOfUserName }}
          </p>
          <el-avatar
            v-else
            class="header-profile-photo"
            :size="25"
            fit="fill"
            :src="getProfileUrl"
          >
            <!-- <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" /> -->
          </el-avatar>
        </template>
        <span class="el-dropdown-link" slot="title">{{ getUserName }}</span>
        <template slot="drop-down-items">
          <el-dropdown-item icon="el-icon-user-solid">
            <el-link
              class="truncate er-dropdown-item--profile"
              :underline="false"
              type="primary"
              href="/user/settings"
              :title="getUserName"
              >{{ $t("Usrs_profile") }}</el-link
            >
          </el-dropdown-item>
          <!-- Help section moved to bottom of side panel -->
          <!-- <el-dropdown-item icon="el-icon-s-help">
            <el-link
              class="truncate er-dropdown-item--profile"
              :underline="false"
              type="primary"
              @click="openHelpDialogBox"
              >{{ $t("Comn_help") }}
            </el-link>
          </el-dropdown-item> -->

          <el-dropdown-item class="er-dropdown-item--logout">
            <i class="el-icon-logout">
              <img class="logout-icon" src="@/assets/logout.svg" />
            </i>
            <er-button
              class="logout-button"
              @click="handleSignOutClick"
              type="text"
            >
              <el-row type="flex">
                <el-col tag="div" class="button-text">{{
                  getLogoutBtnText
                }}</el-col>
              </el-row>
            </er-button>
          </el-dropdown-item>
        </template>
      </er-dropdown>
      <component
        :is="`help`"
        :showDialog="dialogVisible"
        @close_dialog="closeDialog"
      ></component>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import { getImpersonationBy } from "@/middleware/auth";
import { unregisterObjOfStoreModules } from "@/utils/storeRegisterHandlerUtils";
import Help from "@/components/miscellaneous/help";
import notificationView from "@/components/index/notificationView";
import eventBus from "@/components/shared/eventBus";
import appConfig from "@/config/app"
export default {
  name: "Header",
  props: ["hideNavButton"],
  mixins: [errorHandlerMixin, filtersMixin],
  components: { notificationView, Help },
  data: function() {
    return {
      query: {
        location_id: null,
        biomass_units: "KG",
        include: [
          "title",
          "pond_mothers",
          "shrimp_talks",
          "pond_guards",
          "status"
        ]
      },
      dialogVisible: false,
      langs: { Español: "es", English: "en", Chinese: "zh", Portuguese: "pt" },
      revLangs: { es: "Español", en: "English", zh: "Chinese", pt: "Portuguese" },
      profileDropDownItems: {
        profile: "Profile",
        logout: "logout"
      },
      shouldNotificationContentDisplay: true,
      notificationCountVisible: false
    };
  },
  computed: {
    ...mapGetters("user", {
      userLocations: "getUserLocationsObj",
      getCurrUserLocation: "getCurrUserLocation",
      getProfileUrl: "getProfileUrl"
    }),
    ...mapGetters("header", {
      getSideNavStatus: "getSideNavStatus",
      getNotificationCount: "getNotificationCount",
      getNotificationData: "getNotificationData"
    }),
    getImpersonationBy,
    getLogoutBtnText() {
      const logoutButtonStr = {
        SUPER_ADMIN: this.$t("back_to.superadmin"),
        ACCOUNT_MANAGER: this.$t("back_to.account-manager"),
        DEALER: this.$t("back_to.dealer"),
        ACCOUNT_MANAGER_SUB_USER: this.$t("back_to_skretting.technician"),
        USER: this.$t("Comn_logout")
      };
      return this.getImpersonationBy === null
        ? logoutButtonStr.USER
        : logoutButtonStr[this.getImpersonationBy];
    },
    getFeaturesList() {
      // return [
      //   {
      //     title: this.$t("new_feature_text.gateway_alerts.title"),
      //     description: this.ftm__capitalize(
      //       this.$t("new_feature_text.gateway_alerts.description", {
      //         item: this.$t("Gateway_gateways"),
      //         source: this.$tc("Comn_location.case.lower", 1)
      //       })
      //     ),
      //     icon: "circle_notifications"
      //   },
      //   {
      //     title: this.$t("new_feature_text.gateway_alerts.title"),
      //     description: this.ftm__capitalize(
      //       this.$t("new_feature_text.gateway_alerts.description", {
      //         item: this.$t("Gateway_gateways"),
      //         source: this.$tc("Comn_location.case.lower", 1)
      //       })
      //     ),
      //     icon: "circle_notifications"
      //   }
      // ];
      return this.getNotificationData?.length > 0
        ? this.getNotificationData
        : [];
    },
    getFirstCharsOfUserName() {
      return (this.getUserName.match(/\b(\w)/g) || []).join("").slice(0, 2);
    },
    getUserName() {
      return (
        this.$store.getters["user/getUserProfile"] &&
        this.$store.getters["user/getUserProfile"].first_name +
          " " +
          this.$store.getters["user/getUserProfile"].last_name
      );
    },
    doUserHasLocations() {
      return Object.keys(this.computedUserLocations).length > 0;
    },
    computedUserLocations() {
      const sortedLocations = Object.values(this.userLocations).sort((a, b) =>
        this.$commonUtils.alphaNumericComparator(a.name, b.name)
      );
      const locationObj = {};
      sortedLocations.forEach((location) => {
        locationObj[location._id] = location;
      });
      return locationObj;
    },
    currUserLocation() {
      return this.getCurrUserLocation;
    },
    langSelected() {
      return this.revLangs[this.$i18n.locale];
    }
  },
  async created() {
    const payload = {
      type: "WEB"
    };
    await this.fetchReleaseNotificationCount(payload);
    const count = this.getNotificationCount;
    if (count > 0) {
      await this.fetchReleaseNotificationData(payload);
    }
    eventBus.$on("notification-item-clicked", async (args) => {
      this.shouldNotificationContentDisplay = false;
      this.notificationCountVisible = false;
    });
    eventBus.$on("refresh-release-notification", async () => {
      // this.shouldNotificationContentDisplay = false;
      await this.fetchReleaseNotificationCount(payload);
      await this.fetchReleaseNotificationData(payload);
    });
    console.log("getNotificationData", this.getNotificationData);
  },
  destroyed() {
    unregisterObjOfStoreModules(this.$route.meta.storeModules);
  },
  methods: {
    ...mapActions("user", {
      setCurrUserLocation: "updateCurrUserLocation",
      notifyBackendOnLogout: "notifyBackendOnLogout"
    }),
    ...mapActions("header", {
      changeSideNavStatus: "changeSideNavStatus",
      fetchReleaseNotificationCount: "fetchReleaseNotificationCount",
      fetchReleaseNotificationData: "fetchReleaseNotificationData",
      setReleaseNotificationWatchStatus: "setReleaseNotificationWatchStatus"
    }),
    routeQuery(newQuery) {
      return {
        ...this.$route.query,
        ...newQuery
      };
    },
    isfeaturePopperVisible() {
      if (!this.$refs.h__featurePopper) return false;
      return this.$refs.h__featurePopper.showPopper;
    },
    openHelpDialogBox() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    handleChangeInLanguage(value) {
      this.$i18n.locale = value;
      const indexPath = this.$route.meta.langKey;
      const appTitle = document.getElementById("appTitle");
      appTitle.innerHTML = `${this.$t(indexPath)} | PondLogs`;
      localStorage.setItem(`lang`, value);
      this.$root.$emit("handle-laungage-chnage", value);
    },
    handleChangeInLocation(updatedLocation) {
      this.setCurrUserLocation(updatedLocation);
    },
    toggleNavigation: function() {
      this.changeSideNavStatus(!this.getSideNavStatus);
    },
    async handleSignOutClick() {
      try {
        await this.notifyBackendOnLogout();
      } catch (err) {
        console.log("Error calling in logout api", err);
      }
      switch (this.getImpersonationBy) {
        case "SUPER_ADMIN":
          await this.handleLoginAsSuperAdmin();
          break;
        case "DEALER":
          await this.handleLoginAsDealer();
          break;
        case "ACCOUNT_MANAGER":
          await this.handleLoginAsAccountManager();
          break;
        case "ACCOUNT_MANAGER_SUB_USER":
          await this.handleLoginAsSkrettingTechnician();
          break;
        default:
          this.logout();
      }
    },
    logout() {
      const loading = this.$loading({
        fullscreen: true,
        text: this.$t("Comn_logout_loading")
      });
      this.$store.dispatch("auth/signOut");
      this.$router.push("/sign-in", () => {
        loading.close();
      });
    },
    async handleLoginAsSuperAdmin() {
      const loading = this.$loading({
        fullscreen: true,
        text: this.$t("Comn_logout_loading"),
        background: "white"
      });
      setTimeout(async () => {
        try {
          await this.$store.dispatch("superadmin/loginAsSuperAdmin");
          await this.$store.dispatch("auth/impersonationSignOut");
          location.replace(`${appConfig.V3_URL}/admin/customers`)
        } catch (err) {
          this.ehm__errorMessages(
            {
              response: {
                status: 401,
                data: { message: this.$t("failed-to-login") }
              }
            },
            false
          );
          loading.close();
        }
      }, 500);
    },
    async handleLoginAsDealer() {
      const loading = this.$loading({
        fullscreen: true,
        text: this.$t("Comn_logout_loading")
      });
      try {
        await this.$store.dispatch("dealer/loginAsDealer");
        await this.$store.dispatch("auth/impersonationSignOut");
        location.replace(`${appConfig.V3_URL}/dealer-subusers/dealer/users`)
      } catch (err) {
        this.ehm__errorMessages(
          {
            response: {
              status: 401,
              data: { message: this.$t("failed-to-login") }
            }
          },
          false
        );
      } finally {
        loading.close();
      }
    },
    async handleLoginAsAccountManager() {
      const loading = this.$loading({
        fullscreen: true,
        text: this.$t("Comn_logout_loading")
      });
      setTimeout(async () => {
        try {
          await this.$store.dispatch("accountManager/loginAsAccountManager");
          await this.$store.dispatch("auth/impersonationSignOut");
          location.replace(`${appConfig.V3_URL}/dealer-subusers/account-manager/users`)
        } catch (err) {
          this.ehm__errorMessages(
            {
              response: {
                status: 401,
                data: { message: this.$t("failed-to-login") }
              }
            },
            false
          );
        } finally {
          loading.close();
        }
      });
    },
    async handleLoginAsSkrettingTechnician() {
      const loading = this.$loading({
        fullscreen: true,
        text: this.$t("Comn_logout_loading")
      });
      setTimeout(async () => {
        try {
          await this.$store.dispatch(
            "skrettingTechnician/loginAsSkrettingTechnician"
          );
          // await this.$store.dispatch("skrettingTechnician/fetchUserProfile");
          await this.$store.dispatch("auth/impersonationSignOut");
          location.replace(`${appConfig.V3_URL}/dealer-subusers/skretting-technician/users`)
        } catch (err) {
          this.ehm__errorMessages(
            {
              response: {
                status: 401,
                data: { message: this.$t("failed-to-login") }
              }
            },
            false
          );
        } finally {
          loading.close();
        }
      });
    }
  }
};
</script>

<style lang="scss">
.er-dropdown-item--profile {
  text-align: left;
  justify-content: left;
  width: 100%;
}
.locations-dropdown {
  .router-link-active {
    text-decoration: none;
    &:before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      z-index: 1;
    }
  }
}
.er-dropdown-item--logout {
  position: relative;
  .el-icon-logout {
    @include icon-size(svg, prefix);
  }
  .logout-button {
    padding: 0px;
    @include link-text-size;
    height: 100%;
    &:before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      z-index: 1;
    }
  }
  img {
    transform: rotate(180deg);
  }
}

.dashboard-header {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .btn-notification {
    padding: 0px;
    color: $header-icon-color;
    .el-badge__content.is-fixed {
      top: 3.5px;
    }
    .notification-icon-container {
      display: inline-block;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      margin-top: 3.5px;
    }
    .notification-icon,
    .notification-active-icon {
      @include icon-size(icon-font);
      vertical-align: middle;
      background: white;
    }
    .notification-active-icon {
      position: absolute;
      transform: translateY(-23px);
      &.is-active {
        transform: translateY(0px);
      }
    }
  }
  .dashboard-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > img {
      margin-right: 35px;
    }
    .el-tabs__header {
      margin: 0px 15px;
    }
    .el-tabs__nav-wrap::after {
      background-color: unset;
    }
    .menu-icon {
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
    .el-tabs__item {
      height: var(--header-height);
      line-height: var(--header-height);
      font-size: $app_font_size_1;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    h1 {
      a {
        color: #333;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      img {
        width: 100%;
        box-sizing: border-box;
      }
    }
    .nav {
      float: right;
      list-style: none;
    }
    .portal-container {
      display: flex;
      .el-menu--horizontal {
        & > .el-submenu .el-submenu__title {
          height: var(--header-height);
          line-height: var(--header-height);
          color: #0a2463;
          font-weight: bold;
          border-bottom: 2px solid #0a2463;
          @include responsiveProperty(
            font-size,
            $app_font_size_1,
            $app_font_size_2,
            $app_font_size_3
          );
        }
        & > .el-menu-item {
          float: left;
          height: var(--header-height);
          line-height: var(--header-height);
          margin: 0;
          border-bottom: 2px solid transparent;
          opacity: 0.8;
          @include responsiveProperty(
            font-size,
            $app_font_size_1,
            $app_font_size_2,
            $app_font_size_3
          );
          a {
            text-decoration: none;
          }
        }
        & > .el-menu-item.is-active {
          color: #0a2463;
          border-bottom: 2px solid #0a2463;
          opacity: 1;
          font-weight: 600;
        }
        .el-menu-item:focus,
        .el-menu-item:hover,
        .el-submenu:focus,
        .el-submenu:hover {
          color: #0a2463;
          background-color: unset;
          opacity: 1;
        }
      }
    }
  }
  .dashboard-header-right {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > div,
    .feature-notification-popper-trigger {
      margin: 0px 10px;
      .el-badge__content {
        font-size: 10px !important;
        height: 12px !important;
        width: 12px !important;
        line-height: unset !important;
        padding: 3px 3px !important;
        text-align: center;
        white-space: nowrap;
        border: 1px solid #fff;

        @media screen and (max-width: 1365px) {
          font-size: 9px !important;
          height: 10px !important;
          width: 10px !important;
        }
        @media screen and (min-width: 1366px) and (max-width: 1536px) {
          font-size: 9px !important;
          height: 10px !important;
          width: 10px !important;
        }
        @media screen and (min-width: 1537px) and (max-width: 1919px) {
          font-size: 11px !important;
          height: 12px !important;
          width: 12px !important;
        }
        @media screen and (min-width: 1920px) {
          font-size: 11px !important;
          height: 12px !important;
          width: 12px !important;
        }
      }
    }

    .logout-button {
      @include link-text-size;
    }
    .location-picker {
      max-width: 130px;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
    .profile-drop-down {
      display: inline-flex;
      a {
        max-width: 200px;
        text-transform: capitalize;
        text-decoration: none;
        line-height: 24px;
        letter-spacing: 0.6494286px;
        color: #41516a;
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
      }
      .header-profile-placeholder {
        padding: 5px;
        background: #e0e4ea;
        clip-path: circle(47% at 50% 50%);
        text-align: center;
        display: inline-flex;
      }
      .header-profile-photo {
        img {
          display: inline-block;
        }
      }
      .logout__icon {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .user-menu {
      list-style: none;
    }
    .el-icon-logout {
      @include icon-size(svg, prefix);
      transform: rotate(180deg);
    }
    .user-logout-divider {
      @include responsiveProperty(
        font-size,
        1.5 * $app_font_size_1,
        1.5 * $app_font_size_2,
        1.5 * $app_font_size_3
      );
    }
    .el-icon-logout img {
      @include icon-size(svg, prefix);
    }
    .logout-button {
      .button-text {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
</style>
