<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erDownloadDropDown.vue
Description: This file is the extension of the element ui drop down functionality to provide selection for print, download option that is used all over the pondlogs site
-->
<template>
  <el-dropdown @command="handleActionForDownloadType" trigger="click">
    <er-button btnType="download" :showLabel="true" :showIcon="true">
      <span style="display:flex">
        <span style="padding-left:5px">
          {{ $t("Comn_download") }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </span>
    </er-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(downloadType, index) in downloadTypes"
        :key="index"
        :command="downloadTypesObj[downloadType].type"
        >{{ downloadTypesObj[downloadType].label }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  props: {
    downloadTypes: {
      required: true,
      default: () => ["PDF"],
      validator: function(value) {
        // The value must match one of these strings
        return value.every(
          type => ["EXCEL", "PDF", "ZIP"].indexOf(type) !== -1
        );
      }
    }
  },
  data: function() {
    return {};
  },
  computed: {
    downloadTypesObj: function() {
      return {
        EXCEL: {
          type: "EXCEL",
          label: this.$t("Comn_download_as_excel")
        },
        PDF: {
          type: "PDF",
          label: this.$t("Comn_download_as_pdf")
        },
        ZIP: {
          type: "ZIP",
          label: this.$t("Comn_download_as_zip")
        }
      };
    }
  },
  methods: {
    handleActionForDownloadType: function(command) {
      return this.$emit("download-action", command);
    }
  }
};
</script>

<style lang="scss">
.el-button--success {
  background: #155dbe;
  border-radius: 2px;
  border-color: #155dbe;
  padding: 8px;
  @include responsiveProperty(height, 32px, 37px, 45px);
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}

.el-button--success:focus,
.el-button--success:hover {
  background: #155dbe;
  border-color: #155dbe;
  color: #ffffff;
}
</style>
