<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erTabs.vue
Description: This file contains the tabs components which is extension of the element ui tabs component
-->
<template>
  <el-tabs v-bind="$attrs" v-on="inputListeners" class="er-tabs">
    <slot></slot>
  </el-tabs>
</template>

<script>
export default {
  inheritAttrs: false,
  data: function () {
    return {};
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          }
        }
      );
    }
  }
};
</script>

<style lang="scss">
.er-tabs {
  .el-tabs__item:focus,
  .el-tabs__item:focus:active {
    outline: none;
  }
  .el-tabs__item:focus.is-active.is-focus:not(:active) {
    box-shadow: unset;
  }
  .el-tabs__nav-scroll {
    background: white;
  }
  .el-tabs__nav {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .el-tabs__item.is-active {
    color: #0a2463;
    opacity: 1;
    font-weight: 600;
  }
  .el-tabs__active-bar {
    background-color: #0a2463;
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-tabs__item:hover {
    color: #0a2463;
    cursor: pointer;
  }
  .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 0px !important;
  }
  .el-tabs__content {
    padding: 12px;
    background: white;
  }
  .el-tabs__item {
    font-size: 13px;
    text-align: center;
    opacity: 0.8;
    color: #0a2463;
  }
}
</style>
