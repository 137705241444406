/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: localStore.js
Description: Contains stateless helper functions used for localStorage
*/
export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const getItem = key => {
  const value = localStorage.getItem(key);
  if (value === "undefined") return undefined;
  return JSON.parse(value);
};
export const removeItem = key => {
  return localStorage.removeItem(key);
};
export default {
  setItem,
  getItem,
  removeItem
};
