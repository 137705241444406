/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: main.js
Description: This is usually the JavaScript file that will initialise this root component into a element on your page. It is also responsible for setting up plugins and 3rd party components you may want to use in the app
*/
import Vue from "vue";
import "@/config/highcharts.js";
import "@/config/elementUi.js";
import i18n from "@/config/i18n";
import App from "./App.vue";
import router from "@/router";
import store from "./store/index";
import PortalVue from "portal-vue";
import VTooltip from "v-tooltip";
import {
  cloneDeep,
  get,
  set,
  result,
  capitalize,
  debounce,
  throttle
} from "lodash";
import commonUtils from "./utils/commonUtils";
import config from "./config/app";
import "@/components/base/globalComponentRegistration";
import "@/layouts/globalComponentRegistration";
import table from "@/constants/table";
import { deviceTypes } from "@/constants/deviceTypes";
import userAccessManagementMixin from "@/mixins/userAccessManagementMixin";
import documentTitleMixin from "@/mixins/documentTitleMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Embed from "v-video-embed";
import mixpanel from "mixpanel-browser";
mixpanel.init(config.MIXPANEL_ID, {
  debug: process.env.VUE_APP_STAGE !== "PROD"
});
store.watch(async state => {
  // register global properties of user(after login)
  const { user } = state.user;
  console.log("user", user);
  if (user._id) {
    mixpanel.identify(user._id);
    mixpanel.people.set({
      email: user.email,
      name: user.full_name
    });
    mixpanel.register({
      email: user.email,
      fullName: user.full_name
    });
  } else {
    mixpanel.reset();
  }
});
Vue.prototype.$mixpanel = mixpanel;

router.beforeEach(function(to, from, next) {
  console.log("beforeEach, to, from", to, from);
  const isImpressionLocal =
    JSON.parse(localStorage.getItem("is-impersonation")) || false;
  const impressionByLocal =
    JSON.parse(localStorage.getItem("is-impersonation-by")) || "";
  const userTypeLocal =
    JSON.parse(localStorage.getItem("curr-user-type")) || "";
  setTimeout(
    () =>
      mixpanel.track("Page view", {
        fromPath: from.path,
        toPath: to.path,
        fullPath: to.fullPath,
        name: to.name,
        user_type: isImpressionLocal ? impressionByLocal : userTypeLocal,
        tabName: new URLSearchParams(window.location.search).get("tab") || "",
        title: document.title || "",
        isImpersonation: isImpressionLocal,
        impersonationBy: impressionByLocal
      }),
    1000
  );
  next();
});

router.afterEach(function(to, from) {
  // send page view events for all tabs and pages
  const isImpressionLocal =
    JSON.parse(localStorage.getItem("is-impersonation")) || false;
  const impressionByLocal =
    JSON.parse(localStorage.getItem("is-impersonation-by")) || "";
  const userTypeLocal =
    JSON.parse(localStorage.getItem("curr-user-type")) || "";
  console.log("afterEach, to, from", to, from);
  setTimeout(
    () =>
      mixpanel.track("Page exit", {
        fromPath: from.path,
        toPath: to.path,
        fullPath: to.fullPath,
        user_type: isImpressionLocal ? impressionByLocal : userTypeLocal,
        name: from.name,
        tabName: new URLSearchParams(window.location.search).get("tab") || "",
        title: document.title || "",
        isImpersonation: isImpressionLocal,
        impersonationBy: impressionByLocal
      }),
    2000
  );
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// global mixins space
Vue.mixin(userAccessManagementMixin);
Vue.mixin(documentTitleMixin);
Vue.mixin(userPreferenceMixin);

// global plugins space
Vue.use(PortalVue);
Vue.use(VTooltip);

// global filters space
Vue.filter("commas", function(value, countryCode) {
  if (!value) {
    return value;
  }
  if (!isFinite(+value)) return value;
  return parseInt(value).toLocaleString(`en-${countryCode}`);
});

// global video player register
Vue.use(Embed);

// global variables space
Vue.config.productionTip = false;
Vue.prototype.$lodash = {
  cloneDeep,
  get,
  result,
  capitalize,
  debounce,
  throttle,
  set
};
Vue.prototype.$constants = { table, deviceTypes };
Vue.prototype.$commonUtils = commonUtils;
function beforeUnload(event) {
  const isImpressionLocal =
    JSON.parse(localStorage.getItem("is-impersonation")) || false;
  const impressionByLocal =
    JSON.parse(localStorage.getItem("is-impersonation-by")) || "";
  const userTypeLocal =
    JSON.parse(localStorage.getItem("curr-user-type")) || "";
  setTimeout(() =>
    mixpanel.track("Page exit", {
      fromPath: window.location.href,
      toPath: "",
      fullPath: window.location.href,
      user_type: isImpressionLocal ? impressionByLocal : userTypeLocal,
      name: document.title,
      tabName: new URLSearchParams(window.location.search).get("tab") || "",
      title: document.title || "",
      isImpersonation: isImpressionLocal,
      impersonationBy: impressionByLocal
    })
  );
}
// console.log("window", window);
window.addEventListener("beforeunload", beforeUnload);

const vueObj = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeDestroy() {
    window.removeEventListener("beforeunload", beforeUnload);
  }
});

vueObj.$mount("#app");
