<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erMenuItem.vue
Description: This file is the menu item component useful for allowing items as link, and to open new tab when clicked the menuitem by holding the control key
-->
<template>
  <router-link :to="route" class="er-menu__link">
    <li
      class="er-menu-item"
      :class="{ 'is-active': isActive }"
      :index="index"
      :route="route"
    >
      <slot>
        <p class="menu-title truncate">
          {{ label }}
        </p>
      </slot>
    </li>
  </router-link>
</template>

<script>
export default {
  props: ["label", "index", "route", "isActive"]
};
</script>

<style lang="scss">
.el-menu--horizontal {
  & > .er-menu__link > .er-menu-item {
    float: left;
    height: calc(var(--header-height) - 2px);
    line-height: calc(var(--header-height) - 2px);
    margin: 0;
    border-bottom: 2px solid transparent;
    opacity: 0.8;
    padding: 0px 10px;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    &.is-active {
      color: #0a2463;
      border-bottom: 2px solid #0a2463;
      opacity: 1;
      font-weight: 600;
    }
  }
  .el-submenu__title {
    padding: 0 0 0 10px;
  }
  .el-menu {
    > .er-menu__link {
      text-decoration: none;
    }
    .er-menu-item,
    .el-submenu__title {
      background-color: #fff;
      float: none;
      height: 36px;
      line-height: 36px;
      padding: 0 10px;
      text-decoration: none;
      opacity: 0.8;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
    .is-active {
      color: #0a2463;
      opacity: 1;
      font-weight: 600;
    }
  }
  .er-menu-item:focus,
  .er-menu-item:hover {
    color: #0a2463;
    background-color: unset;
    opacity: 1;
  }
}
</style>
