/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: table.js
Description: This file contains all the vuex store functions used in custom Element UI table
*/
// initial state
const state = {
  totalRows: 10,
  current_limit: 10,
  current_page: 1,
  order_by: "title",
  order_type: "asc"
};

const getters = {
  tableQueryParams: function(state) {
    return {
      limit: state.current_limit,
      page: state.current_page,
      order_by: state.order_by,
      order_type: state.order_type
    };
  },
  getTotalRowCount: function(state) {
    return state.totalRows;
  }
};

const mutations = {
  SET_CURRENT_PAGE: function(state, current_page) {
    state.current_page = current_page;
  },
  SET_CURRENT_LIMIT: function(state, current_limit) {
    state.current_limit = current_limit;
  },
  SET_SORT_PROPERTY: function(state, { order_by, order_type }) {
    state.order_by = order_by;
    state.order_type = order_type;
  },
  CLEAR_TABLE_QUERY_PARAMS: function(state) {
    state.current_limit = 10;
    state.current_page = 1;
    state.order_by = "title";
    state.order_type = "asc";
  },
  SET_TOTAL_ROW_COUNT: function(state, totalRowCount) {
    state.totalRows = totalRowCount;
  }
};

const actions = {
  changePage(context, { current_page }) {
    context.commit("SET_CURRENT_PAGE", current_page);
  },
  changeLimit(context, { current_limit }) {
    context.commit("SET_CURRENT_LIMIT", current_limit);
  },
  changeSort(context, { order_by, order_type }) {
    context.commit("SET_SORT_PROPERTY", { order_by, order_type });
  },
  clearTableQueryParams(context) {
    context.commit("CLEAR_TABLE_QUERY_PARAMS");
  },
  changeTotalRowCount(context, totalRowCount) {
    context.commit("SET_TOTAL_ROW_COUNT", totalRowCount);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
