<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erCard.vue
Description: This file contains the component which is extented from element ui card component as a base card component for all over the pondlogs.
-->
<template>
  <el-card class="er-card er-card--secondary" v-bind="$attrs" v-on="inputListeners">
    <template slot="header">
      <slot name="header"></slot>
    </template>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          }
        }
      );
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
@mixin component-font-size-large {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
.er-card {
  $heading-font-size: 12px;
  $primary-card-header-color: white;
  $primary-card-header-bg-color: #349aff;
  $secondary-card-header-bg-color: #ffffff;
  $secondary-card-header-color: #0a2463;
  $secondary-card-header-font-weight: 600;
  border-radius: 7px;
  .el-card__header {
    padding: 5px;
    font-weight: $secondary-card-header-font-weight;
    letter-spacing: 1px;
    background-color: $primary-card-header-bg-color;
    color: $primary-card-header-color;
    .card-title {
      // text-transform: capitalize;
      @include component-font-size-large;
    }
    .card-icon {
      @include icon-size(svg);
      margin-right: 10px;
    }
  }
  .el-card__body {
    padding: 5px 20px 10px 20px;
  }
  &.er-card--secondary {
    .el-card__header {
      padding: 5px 15px;
      font-weight: $secondary-card-header-font-weight;
      @include component-font-size-large;
      background-color: $secondary-card-header-bg-color;
      color: $secondary-card-header-color;
      border: unset;
    }
    .el-card__body {
      padding: 5px 10px 10px 10px;
    }
  }
}
</style>
