<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erActionButton.vue
Description: This file contains the variant of the button component implemented using div.
-->
.<template>
  <div v-if="this.type==='save'" @click="handleClick" :style="btnStyles" class="save-btn">
    <div class="img">
      <slot name="icon"></slot>
    </div>
    <div class="label">
      <slot name="label"></slot>
    </div>
  </div>
  <div v-else-if="this.type==='outline'" @click="handleClick" :style="btnStyles" class="save-btn">
    <div class="img">
      <slot name="icon"></slot>
    </div>
    <div class="label">
      <slot name="label"></slot>
    </div>
  </div>
  <div v-else-if="this.type==='cancel'" @click="handleClick" :style="btnStyles" class="save-btn">
    <div class="label">
      <slot name="label"></slot>
    </div>
  </div>
  <div v-else-if="this.type==='simple'" @click="handleClick" :style="btnStyles" class="save-btn">
    <div class="label">
      <slot name="label"></slot>
    </div>
  </div>
  <div :class="getBtnClass" @click="handleClick" v-else>
    <div class="img">
      <slot name="icon"></slot>
    </div>
    <div class="label">
      <slot name="label"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErActionButton",
  props: {
    disabled: Boolean,
    type: String,
    bgColor: String,
    width: {
      type: String,
      default: "76px"
    }
  },
  computed: {
    getBtnClass () {
      let btnClass = "";
      if (this.type === "solid") {
        btnClass = "action-btn solid-btn";
      } else {
        btnClass = "action-btn";
      }

      if (this.disabled) {
        btnClass = btnClass + " disabled";
        return btnClass;
      } else {
        return btnClass;
      }
    },
    btnStyles () {
      let style = {};

      if (this.type === "save") {
        style = {
          cursor: "pointer",
          background: this.bgColor,
          width: this.width,
          height: "28px",
          display: "flex",
          "border-radius": "3px",
          "align-items": "center",
          color: "white",
          "justify-content": "center"
          // border: "1px solid transparent"
        };
      } else if (this.type === "simple") {
        style = {
          cursor: "pointer",
          // width: "74px",
          height: "26px",
          color: "black",
          background: this.bgColor,
          "border-radius": "3px",
          display: "flex",
          // "align-items": "center",
          "justify-content": "center"
        };
      } else {
        style = {
          cursor: "pointer",
          width: this.width,
          height: "26px",
          color: this.bgColor,
          background: "#FFFFFF",
          border: "1px solid #D8D8D8",
          "border-radius": "6px",
          display: "flex",
          "align-items": "center",
          "justify-content": "center"
        };
      }

      if (this.disabled) {
        style["pointer-events"] = "none";
        style.opacity = "0.4";
        return style;
      } else {
        return style;
      }
    }
  },
  methods: {
    handleClick (val) {
      console.log(val);
      this.$emit("action-btn-click", val);
    }
  }
};
</script>

<style lang="scss">
.save-btn {
  img {
    width: 15px;
    height: 15px;
  }
  .img {
    display: flex;
    align-self: center;
    margin-right: 8px;
  }
  .label {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    display: flex;
    align-content: center;
  }
}
.action-btn {
  align-items: center !important;
  //   width: 75.02px;
  display: flex;
  cursor: pointer;
  .img {
    width: 12px;
    margin-right: 8px;
  }
  .label {
    text-transform: uppercase;
    font-size: 9px;
    // margin: 0px 0px !important;
    margin-top: 0px;
    margin-bottom: 0px;
    p {
      margin: 0px 0px !important;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.solid-btn {
  background: #155dbe;
  border-radius: 3px;
  color: white;
  height: 28px;
  padding-left: 6px;
  padding-right: 6px;
  .img {
    margin-right: 3px;
    margin-left: 3px;
  }
  .label {
    margin-right: 4px;
    margin-left: 4px;
  }
}
</style>
