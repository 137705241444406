<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erDateRangeInput.vue
Description: This file is the extension of the element ui date range component used for building the date picker similar to the google dashboards
-->
<template>
  <div
    class="el-date-editor el-range-editor el-input__inner"
    :class="[
      'el-date-editor--' + type
    ]"
    @click="handleRangeClick"
    @mouseenter="handleMouseEnter"
    @mouseleave="showClose = false"
    @keydown="handleKeydown"
    ref="reference"
    v-clickoutside="handleClose"
  >
    <i :class="['el-input__icon', 'el-range__icon', triggerClass]"></i>
    <input
      autocomplete="off"
      :placeholder="startPlaceholder"
      :value="displayValue && displayValue[0]"
      v-bind="firstInputId"
      :readonly="!editable || readonly"
      :name="name && name[0]"
      @input="handleStartInput"
      @change="handleStartChange"
      @focus="handleFocus"
      class="el-range-input"
    />
    <slot name="range-separator">
      <span class="el-range-separator">{{ rangeSeparator }}</span>
    </slot>
    <input
      autocomplete="off"
      :placeholder="endPlaceholder"
      :value="displayValue && displayValue[1]"
      v-bind="secondInputId"
      :readonly="!editable || readonly"
      :name="name && name[1]"
      @input="handleEndInput"
      @change="handleEndChange"
      @focus="handleFocus"
      class="el-range-input"
    />
    <i
      @click="handleClickIcon"
      v-if="haveTrigger"
      :class="[showClose ? '' + clearIcon : '']"
      class="el-input__icon el-range__close-icon"
    ></i>
  </div>
</template>
<script>
import Picker from "element-ui/packages/date-picker/src/picker.vue";
import {
  formatDate,
  parseDate,
  isDateObject,
  getWeekNumber,
  isDate
} from "element-ui/src/utils/date-util";
const DEFAULT_FORMATS = {
  date: "yyyy-MM-dd",
  month: "yyyy-MM",
  datetime: "yyyy-MM-dd HH:mm:ss",
  time: "HH:mm:ss",
  week: "yyyywWW",
  timerange: "HH:mm:ss",
  daterange: "yyyy-MM-dd",
  monthrange: "yyyy-MM",
  datetimerange: "yyyy-MM-dd HH:mm:ss",
  year: "yyyy"
};

const DATE_FORMATTER = function (value, format) {
  if (format === "timestamp") return value.getTime();
  return formatDate(value, format);
};
const DATE_PARSER = function (text, format) {
  if (format === "timestamp") return new Date(Number(text));
  return parseDate(text, format);
};
const RANGE_FORMATTER = function (value, format) {
  if (Array.isArray(value) && value.length === 2) {
    const start = value[0];
    const end = value[1];

    if (start && end) {
      return [DATE_FORMATTER(start, format), DATE_FORMATTER(end, format)];
    }
  }
  return "";
};
const RANGE_PARSER = function (array, format, separator) {
  if (!Array.isArray(array)) {
    array = array.split(separator);
  }
  if (array.length === 2) {
    const range1 = array[0];
    const range2 = array[1];

    return [DATE_PARSER(range1, format), DATE_PARSER(range2, format)];
  }
  return [];
};
const TYPE_VALUE_RESOLVER_MAP = {
  default: {
    formatter (value) {
      if (!value) return "";
      return "" + value;
    },
    parser (text) {
      if (text === undefined || text === "") return null;
      return text;
    }
  },
  week: {
    formatter (value, format) {
      const week = getWeekNumber(value);
      const month = value.getMonth();
      const trueDate = new Date(value);
      if (week === 1 && month === 11) {
        trueDate.setHours(0, 0, 0, 0);
        trueDate.setDate(
          trueDate.getDate() + 3 - ((trueDate.getDay() + 6) % 7)
        );
      }
      let date = formatDate(trueDate, format);

      date = /WW/.test(date)
        ? date.replace(/WW/, week < 10 ? "0" + week : week)
        : date.replace(/W/, week);
      return date;
    },
    parser (text, format) {
      // parse as if a normal date
      return TYPE_VALUE_RESOLVER_MAP.date.parser(text, format);
    }
  },
  date: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  datetime: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  daterange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  monthrange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  datetimerange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  timerange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  time: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  month: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  year: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  number: {
    formatter (value) {
      if (!value) return "";
      return "" + value;
    },
    parser (text) {
      const result = Number(text);

      if (!isNaN(text)) {
        return result;
      } else {
        return null;
      }
    }
  },
  dates: {
    formatter (value, format) {
      return value.map(date => DATE_FORMATTER(date, format));
    },
    parser (value, format) {
      return (typeof value === "string" ? value.split(", ") : value).map(date =>
        date instanceof Date ? date : DATE_PARSER(date, format)
      );
    }
  }
};

const parseAsFormatAndType = (
  value,
  customFormat,
  type,
  rangeSeparator = "-"
) => {
  if (!value) return null;
  const parser = (
    TYPE_VALUE_RESOLVER_MAP[type] || TYPE_VALUE_RESOLVER_MAP.default
  ).parser;
  const format = customFormat || DEFAULT_FORMATS[type];
  return parser(value, format, rangeSeparator);
};

const formatAsFormatAndType = (value, customFormat, type) => {
  if (!value) return null;
  const formatter = (
    TYPE_VALUE_RESOLVER_MAP[type] || TYPE_VALUE_RESOLVER_MAP.default
  ).formatter;
  const format = customFormat || DEFAULT_FORMATS[type];
  return formatter(value, format);
};

/*
 * Considers:
 *   1. Date object
 *   2. date string
 *   3. array of 1 or 2
 */
const valueEquals = function (a, b) {
  // considers Date object and string
  const dateEquals = function (a, b) {
    const aIsDate = a instanceof Date;
    const bIsDate = b instanceof Date;
    if (aIsDate && bIsDate) {
      return a.getTime() === b.getTime();
    }
    if (!aIsDate && !bIsDate) {
      return a === b;
    }
    return false;
  };

  const aIsArray = a instanceof Array;
  const bIsArray = b instanceof Array;
  if (aIsArray && bIsArray) {
    if (a.length !== b.length) {
      return false;
    }
    return a.every((item, index) => dateEquals(item, b[index]));
  }
  if (!aIsArray && !bIsArray) {
    return dateEquals(a, b);
  }
  return false;
};

/**
 * Component Script
 */
export default {
  name: "ErDateRangeInput",
  mixins: [Picker],
  props: {
    type: {
      type: String,
      default: "date"
    }
  },
  data: function () {
    return {
      isValidFormat: true
    };
  },
  watch: {
    pickerVisible (val) {
      if (val) {
        this.valueOnOpen = Array.isArray(this.value)
          ? [...this.value]
          : this.value;
      } else {
        this.emitChange(this.value);
        this.userInput = null;
        this.$emit("blur", this);
        this.blur();
      }
    }
  },
  computed: {
    pickerDisabled () {
      return true;
    }
  },
  methods: {
    mountPicker: function () {
      this.picker = { value: this.value };
    },
    focus () {
      if (!this.ranged) {
        this.$refs.reference.focus();
      } else {
        this.handleFocus();
      }
    },

    blur () {
      this.refInput.forEach(input => input.blur());
    },

    // {parse, formatTo} Value deals maps component value with internal Date
    parseValue (value) {
      const isParsed =
        isDateObject(value) ||
        (Array.isArray(value) && value.every(isDateObject));
      if (this.valueFormat && !isParsed) {
        return (
          parseAsFormatAndType(
            value,
            this.valueFormat,
            this.type,
            this.rangeSeparator
          ) || value
        );
      } else {
        return value;
      }
    },

    formatToValue (date) {
      const isFormattable =
        isDateObject(date) || (Array.isArray(date) && date.every(isDateObject));
      if (this.valueFormat && isFormattable) {
        return formatAsFormatAndType(
          date,
          this.valueFormat,
          this.type,
          this.rangeSeparator
        );
      } else {
        return date;
      }
    },

    // {parse, formatTo} String deals with user input
    parseString (value) {
      const type = Array.isArray(value)
        ? this.type
        : this.type.replace("range", "");
      return parseAsFormatAndType(value, this.format, type);
    },

    formatToString (value) {
      const type = Array.isArray(value)
        ? this.type
        : this.type.replace("range", "");
      return formatAsFormatAndType(value, this.format, type);
    },

    handleMouseEnter () {
      if (this.readonly || this.pickerDisabled) return;
      if (!this.valueIsEmpty && this.clearable) {
        this.showClose = true;
      }
    },

    handleChange () {
      if (this.userInput) {
        const value = this.parseString(this.displayValue);
        if (value) {
          if (this.isValidValue(value)) {
            this.emitInput(value);
            this.userInput = null;
          }
        }
      }
      if (this.userInput === "") {
        this.emitInput(null);
        this.emitChange(null);
        this.userInput = null;
      }
    },

    handleStartInput (event) {
      if (this.userInput) {
        this.userInput = [event.target.value, this.userInput[1]];
      } else {
        this.userInput = [event.target.value, null];
      }
    },

    handleEndInput (event) {
      if (this.userInput) {
        this.userInput = [this.userInput[0], event.target.value];
      } else {
        this.userInput = [null, event.target.value];
      }
    },

    handleStartChange (event) {
      const value = this.parseString(this.userInput && this.userInput[0]);
      if (value) {
        this.userInput = [this.formatToString(value), this.displayValue[1]];
        if (this.isValidValue(this.userInput)) {
          this.emitInput(this.userInput);
          this.userInput = null;
          return;
        }
      }
      this.isValidFormat = false;
      this.emitInput(this.value);
    },

    handleEndChange (event) {
      const value = this.parseString(this.userInput && this.userInput[1]);
      if (value) {
        this.userInput = [this.displayValue[0], this.formatToString(value)];
        if (this.isValidValue(this.userInput)) {
          this.emitInput(this.userInput);
          this.userInput = null;
          return;
        }
      }
      this.isValidFormat = false;
      this.emitInput(this.value);
    },

    handleClickIcon (event) {
      if (this.showClose) {
        event.stopPropagation();
        this.emitInput(null);
        this.emitChange(null);
        this.showClose = false;
      }
    },
    handleFieldReset (initialValue) {
      this.userInput = initialValue === "" ? null : initialValue;
    },
    handleFocus () {
      //   const type = this.type;
      this.$emit("focus", this);
    },

    handleKeydown (event) {
      const keyCode = event.keyCode;
      // ESC
      if (keyCode === 27) {
        event.stopPropagation();
        return;
      }

      // Tab
      if (keyCode === 9) {
        if (!this.ranged) {
          this.handleChange();
          this.blur();
          event.stopPropagation();
        } else {
          // user may change focus between two input
          setTimeout(() => {
            if (this.refInput.indexOf(document.activeElement) === -1) {
              this.blur();
              event.stopPropagation();
            }
          }, 0);
        }
        return;
      }

      // Enter
      if (keyCode === 13) {
        if (
          this.userInput === "" ||
          this.isValidValue(this.parseString(this.displayValue))
        ) {
          this.handleChange();
          this.blur();
        }
        event.stopPropagation();
        return;
      }

      // if user is typing, do not let picker handle key input
      if (this.userInput) {
        event.stopPropagation();
      }
    },

    handleRangeClick () {
      //   const type = this.type;
      this.$emit("focus", this);
    },
    emitChange (val) {
      // determine user real change only
      if (!valueEquals(val, this.valueOnOpen)) {
        this.$emit("change", val);
        if (this.validateEvent) {
          this.dispatch("ElFormItem", "el.form.change", val);
        }
      }
    },

    emitInput (val) {
      const formatted = this.formatToValue(val);
      if (!valueEquals(this.value, formatted) || !this.isValidFormat) {
        this.isValidFormat = true;
        this.$emit("input", formatted);
      }
    },

    isValidValue (value) {
      const parseToDate = this.parseString(value);
      return (
        Array.isArray(parseToDate) &&
        parseToDate &&
        parseToDate[0] &&
        parseToDate[1] &&
        isDate(parseToDate[0]) &&
        isDate(parseToDate[1]) &&
        parseToDate[0].getTime() <= parseToDate[1].getTime()
      );
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
