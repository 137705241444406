/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: shrimptalk.js
Description: This file contains the model, functions of  shrimptalk used all over the pondlogs customer site
*/
/* eslint-disable */
import { decodeDeviceCode, getType } from "@/utils/commonUtils";
class ShrimpTalk {
  constructor() {
    this._id = "";
    this.title = "";
    this.code = "";
    this.pond_id = "";
    this.shrimp_talk_id = "";
    this.main_pond_mother_id = "";
    this.pond_mothers = [];
    this.settings = {
      range: ["06:20", "20:00"],
      start_time: "06:00",
      end_time: "20:00",
      pause_start: "00:00",
      pause_end: "00:00",
      feed_limit: 70
      // feeding_level: 0,
    };
    this.supports = {
      pms_count: 2
    };
    this.status = "";
    this.user_id = "";
    this.created_at = "";
    this.updated_at = "";
  }

  castToSTObj(inputSTDetails) {
    const setValues = function(source, destination) {
      for (let key in source) {
        if (destination[key] === undefined) {
          destination[key] = {};
        }
        if (getType(source[key]) === "object") {
          setValues(source[key], destination[key]);
          continue;
        }
        destination[key] = source[key];
      }
    };
    setValues(inputSTDetails, this);
    this.code =
      inputSTDetails.shrimptalk_display_code || inputSTDetails.display_code;
    this.hex_code = inputSTDetails.code;
    this.title = inputSTDetails.title || this.display_code;
    this.main_pond_mother_id = (
      this.pond_mothers.filter(pm => pm.is_master)[0] || { _id: 0 }
    )._id;
    this.settings.range = [this.settings.start_time, this.settings.end_time];
    this.shrimp_talk_id = this._id;
  }
  getSTPropertiesObj() {
    const propertyNameList = ["title", "pond_id", "shrimp_talks_id", "code"];
    const properties = {};
    propertyNameList.forEach(property => {
      properties[property] = this[property];
    });
    return properties;
  }
  static v1Title(code) {
    return `ST${decodeDeviceCode(code)
      .toString()
      .padStart(5, "0")}`;
  }
}
export default ShrimpTalk;
