/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: releaseNotes.js
Description: This file contains all API service calls in relation to Ponds
*/
import { $axios } from "../config/axios";

export default {
  fetchReleaseList(params) {
    return $axios.get("/ui-versions", { params });
  },
  setNotificationWatchStatusService(params) {
    return $axios.post("/ui-version-notifications/add-watched-status", params);
  },
  fetchUserProductManualPDF(params) {
    return $axios.get("/product-manuals", {
      params
    });
  }
};
