<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erColumnSelector.vue
Description: This file contains the vue component which is used along with tables for allowing selection of columns by the user
-->
<template>
  <div class="er-column-selector">
    <el-popover
      placement="bottom"
      :title="title"
      width="max-content"
      v-model="visible"
      popper-class="er-column-selector-pop-container"
      ref="colPop"
    >
      <el-scrollbar
        viewClass="er-column-selector__view"
        wrapClass="er-column-selector__wrap"
      >
        <template v-if="Object.keys(columns).length > 0">
          <div class="er-column-selector__check-group">
            <el-checkbox
              v-if="type === 'ponds'"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >{{ $t("Comn_all") }}</el-checkbox
            >
            <el-checkbox
              v-if="type === 'pondInfo'"
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="columnHandleCheckAllChange"
              >{{ $t("Comn_all") }}</el-checkbox
            >

            <el-checkbox-group
              v-model="selectedHeaders"
              @change="handleChangeInSelectedColumns"
            >
            <template v-for="(column, key) in columns">
              <el-checkbox
                v-if="key !== 'actions'"
                :key="key"
                :disabled="!allowCheck && column[keyToDecideDefaultCol]"
                :label="column.label ? '' + key : column"
                >{{ column.label ? column.label : column }}</el-checkbox
              >
            </template>
            </el-checkbox-group>
          </div>
        </template>
        <template v-else>
          <center style="margin-bottom: 10px">
            <el-tag>{{ $t("empty") }}</el-tag>
          </center>
        </template>
        <er-button
          v-if="allowSave"
          class="btn-save-pref"
          @click="handleSaveDefault"
          btnType="save"
          size="mini"
          :showIcon="true"
          :showLabel="true"
        ></er-button>
      </el-scrollbar>
      <er-button
        v-if="disabled"
        class="btn-column-selector"
        type="text"
        slot="reference"
        size="mini"
        disabled
      >
        <img class="btn-icon-svg" :src="renderImg(srcImg)" />
      </er-button>
      <er-button
        v-else
        class="btn-column-selector"
        type="text"
        slot="reference"
        size="small"
      >
        <img class="btn-icon-svg" :src="renderImg(srcImg)" />
      </er-button>
    </el-popover>
  </div>
</template>

<script>
import COLUMN_ICON from "@/assets/colomn-icon.svg";

export default {
  props: {
    keyToStoreDefaults: {
      required: true
    },
    columns: {
      required: true,
      default: () => []
    },
    keyToDecideDefaultCol: {
      default: "default"
    },
    srcImg: {
      default: "COLUMN_ICON",
      color: "gray"
    },
    type: String,
    title: String,
    emptyText: String,
    loading: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    allowCheck: {
      default: false
    },
    allowSave: {
      default: true,
      type: Boolean
    }
  },
  data: function () {
    return {
      selectedHeaders: [],
      visible: false,
      checkAll: false,
      isIndeterminate: false
    };
  },
  watch: {
    "$i18n.locale": function (newValue, oldValue) {
      this.$emit("change-selection", this.selectedHeaders);
    },
    keyToDecideDefaultCol: {
      handler: function (newValue, oldValue) {
        if (this.storeColumns && this.storeColumns.length > 0) {
          this.$emit("change-selection", this.storeColumns);
          this.selectedHeaders = this.storeColumns;
        } else {
          this.selectedHeaders = Object.keys(this.columns).filter(
            (key) => this.columns[key][this.keyToDecideDefaultCol]
          );
          this.$emit("change-selection", this.selectedHeaders);
        }
      },
      immediate: true
    },
    columns: {
      handler: function (newValue, oldValue) {
        if (this.storeColumns && this.storeColumns.length > 0) {
          this.$emit("change-selection", this.storeColumns);
          this.selectedHeaders = this.storeColumns;
        } else {
          this.selectedHeaders = Object.keys(this.columns).filter(
            (key) => this.columns[key][this.keyToDecideDefaultCol]
          );
          this.$emit("change-selection", this.selectedHeaders);
        }
      }
    }
  },
  computed: {
    isChecked() {
      return (key) => {
        return (
          this.storeColumns.includes(key) ||
          this.columns[key][this.keyToDecideDefaultCol]
        );
      };
    },
    storeColumns: function () {
      const arrStoredColumns = JSON.parse(
        localStorage.getItem(this.localStorageKey) || "[]"
      );
      if (!this.loading) {
        const selectedHeaderKeys = Object.keys(this.columns);
        const foundKeyNotPresent = arrStoredColumns.find(
          (column) => !selectedHeaderKeys.includes(column)
        );
        if (foundKeyNotPresent) {
          localStorage.removeItem(this.localStorageKey);
          return this.selectedHeaderKeys;
        }
      }
      return arrStoredColumns;
    },
    localStorageKey: function () {
      return `${this.keyToStoreDefaults}_${this.keyToDecideDefaultCol}`;
    }
  },
  methods: {
    renderImg(data) {
      switch (data) {
        case "COLUMN_ICON":
          return COLUMN_ICON;
        default:
          return COLUMN_ICON;
      }
    },
    handleSaveDefault() {
      localStorage.setItem(
        this.localStorageKey,
        JSON.stringify(this.selectedHeaders)
      );
      this.visible = false;
      this.$notify({
        title: this.$t("Usrs_success_msg"),
        message:
          this.type === "ponds" || this.type === "pondInfo"
            ? this.$t("saved-selection-as-defaults")
            : this.$t("saved-selected-columns-as-default-columns"),
        duration: 5000,
        type: "success"
      });
      this.$emit("column-save", true)
    },
    handleChangeInSelectedColumns(value) {
      if (this.type === "ponds" || this.type === "pondInfo") {
        const checkedCount = value.length;
        this.checkAll = checkedCount === this.columns.length;
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.columns.length;
        this.$emit("change-selection", this.selectedHeaders);
      } else {
        this.$emit("change-selection", this.selectedHeaders);
      }
    },
    handleCheckAllChange(val) {
      this.selectedHeaders = val ? this.columns : [];
      this.$emit("change-selection", this.selectedHeaders);
      this.isIndeterminate = false;
    },
    columnHandleCheckAllChange(val) {
      this.selectedHeaders = val ? Object.keys(this.columns).filter(
            (key) => this.columns[key]
          ) : [];
      this.$emit("change-selection", this.selectedHeaders);
      this.isIndeterminate = false;
    }
  }
};
</script>

<style lang="scss">
.er-column-selector-pop-container {
  padding: 0px !important;
  @include er-checkbox-checked;
  .er-column-selector__wrap {
    max-height: 274px;
  }
  .el-checkbox {
    margin-bottom: 0.5rem;
  }
  .el-checkbox-group {
    display: grid;
  }
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #f2f6fc;
    border-color: #dcdfe6;
  }
  .el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: #c0c4cc;
    cursor: not-allowed;
  }
  .el-popover__title {
    padding: 8px;
    margin-bottom: 0px;
    border-bottom: 1px solid lightgray;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    font-weight: 700;
  }
  .er-column-selector__check-group {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
  }
  .er-column-selector__save-pref-row {
    border-top: 1px solid lightgray;
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    & > *:not(:first-child) {
      margin-left: 10px;
      font-size: 0.8em;
      color: #4c68ef;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .btn-save-pref {
    width: 100%;
    text-align: left;
  }
  .el-checkbox__label {
    font-size: 12px;
  }
}
.er-column-selector {
  align-items: center;
  & > span {
    display: flex;
    align-items: center;
  }
  .btn-column-selector {
    padding: 0px;
    vertical-align: middle;
    filter: invert(94%) sepia(6%) saturate(288%) hue-rotate(205deg)
      brightness(84%) contrast(66%);
  }
  .btn-column-selector:hover {
    // display: flex;
    padding: 0px;
    vertical-align: middle;
    filter: invert(10%) sepia(11%) saturate(493%) hue-rotate(205deg)
      brightness(94%) contrast(96%);
  }
}
</style>
