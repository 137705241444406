/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: googleMaps.js
Description: This file contains all the vuex store functions used in relation to Google Maps
*/
import { cloneDeep } from "lodash";
import dateUtils from "@/utils/dateUtils";
import Axios from "axios";
import PondGuardService from "@/services/PondGuardService";
import Pond from "@/model/pond";
import appConfig from "@/config/app";
import AlertsService from "@/services/DeviceHealthService.js";
import { errorAlertsFactory } from "@/model/errorAlerts.js";
import { allDevicesAlertsMetaData } from "@/constants/deviceTypes";
import FeedManagementService from "@/services/FeedManagementService.js";
import ScheduleService from "@/services/ScheduleService";
import WaterQualityService from "@/services/WaterQualityService";
const GOOGLE_MAPS_API = appConfig.GOOGLE_MAPS_API_KEY;
export default {
  namespaced: true,
  state: {
    pondsList: [],
    currentClickedPond: null,
    currentHoverPond: null,
    selectedPondWithDeviceDetails: {},
    currentCulture: {},
    slotWiseFeed: [],
    pondHourlyFeed: [],
    stHourlyFeed: [],
    pmSlotWiseFeed: [],
    manualFeedData: [],
    pmDetailsOfSelectedPondId: [],
    loading: false,
    loadingPondDrawnPlace: false,
    searchedPlaceName: "",
    mapMode: "INITIAL",
    arrPondHealthData: [],
    currDrawingPond: new Pond(),
    mapPondDrawnPlace: "",
    errorAlerts: [],
    pondSchedules: [],
    sizeAlert: false,
    permissions: {},
    arrSelectedPondPGs: [],
    showAccessDenied: false,
    latestNDoTempValues: []
  },
  getters: {
    getIsAccessDenied(state) {
      return state.showAccessDenied;
    },
    getDaysOfCultivationOnHoveredPond(state) {
      return state.currentHoverPond;
    },
    getArrAvailDeviceTypes: function(state, getters, rootState, rootGetters) {
      return rootGetters["user/getDeviceTypesEnabledForUser"];
    },
    getArrAvailDevicesMetaData: function(state, getters) {
      const deviceTypeToMetaData = allDevicesAlertsMetaData;
      // device wise priority is based on the order in the getter deviceTypeToMetaData
      return getters.getArrAvailDeviceTypes.map(
        deviceType => deviceTypeToMetaData[deviceType]
      );
    },
    getLatestNDOTempValues: function(state) {
      return state.latestNDoTempValues;
    },
    getPondIdToLatestNDOTempValues: (state, getters) => {
      const latestNDoTempValues = getters.getLatestNDOTempValues;
      const result = {};
      for (var j = 0; j < latestNDoTempValues.length; j++) {
        const devices = [];
        const { data } = latestNDoTempValues[j];
        for (var i = 0; i < data.length; i++) {
          const {
            dissolved_oxygen,
            do_alerts_config,
            pond_guard_id,
            temperature,
            temperature_alerts_config,
            pond_guard_code
          } = data[i];
          const {
            critical_lower_limit: do_critical_lower_limit,
            lower_limit: do_lower_limit
          } = do_alerts_config[0];
          const {
            lower_limit: temp_lower_limt,
            upper_limit: temp_upper_limit,
            tolerance: temp_tolerance
          } = temperature_alerts_config[0];
          devices.push({
            do: dissolved_oxygen,
            temp: temperature,
            doConfig: {
              critical_lower_limit: do_critical_lower_limit,
              lower_limit: do_lower_limit
            },
            tempConfig: {
              lower_limit: temp_lower_limt,
              upper_limit: temp_upper_limit,
              tolerance: temp_tolerance
            },
            pgID: pond_guard_id,
            pgCode: pond_guard_code
          });
        }

        result[latestNDoTempValues[j]._id] = devices;
      }
      return result;
    },
    getArrSelectedPondPGs: function(state) {
      const objSelectedPondPGs = state.arrSelectedPondPGs.reduce(
        (acc, curr) => {
          acc[curr.device_id] = curr;
          return acc;
        },
        {}
      );
      return Object.values(objSelectedPondPGs);
    },
    getArrPondDataWithHealth: (state, getters, rootState, rootGetters) => {
      return rootGetters["pond/getArrPondDataWithHealth"];
    },
    getCultureName: state => {
      return state.currentCulture.name;
    },
    getPermissions: function(state) {
      return state.permissions;
    },
    getCanUserEdit(state, getters, rootState, rootGetters) {
      const updatePermissionObj = rootGetters["user/getUpdatePermissions"];
      const userType = rootGetters["user/getCurrUserType"];
      const permissionRequired = getters.getPermissions;
      const keyToValidateAccess = permissionRequired.keyToValidateAccess;
      const methodToValidate =
        keyToValidateAccess === "PERMISSIONS"
          ? x => updatePermissionObj[x]
          : x => x === userType;
      return permissionRequired[keyToValidateAccess].some(methodToValidate);
    },
    getCultures: (state, getters, rootState, rootGetters) => {
      const pond = getters.getSelectedPond;
      const cultivationsArr = cloneDeep(pond.cultivations || []);
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      cultivationsArr.forEach(culture => {
        const startDate = dateUtils
          .castUserUTCToBrowserTZDate(culture.start_date, {
            timeZone: userTimeZoneString
          })
          .toISOString();
        const endDate = dateUtils
          .castUserUTCToBrowserTZDate(culture.end_date, {
            timeZone: userTimeZoneString
          })
          .toISOString();
        culture.start_date = startDate;
        culture.end_date = endDate;
        culture.doc = dateUtils.differenceInDays(
          dateUtils.parseISO(culture.end_date),
          dateUtils.parseISO(culture.start_date)
        );
      });
      const isPondInCulture = [
        pond => Boolean(pond),
        pond => pond.status === "ACTIVE",
        pond => Boolean(pond.cultivation_date),
        pond => pond.cultivation_date !== ""
      ].every(x => x(pond));
      if (isPondInCulture) {
        const currentCulture = {
          start_date: dateUtils
            .castUserUTCToBrowserTZDate(pond.cultivation_date, {
              timeZone: userTimeZoneString
            })
            .toISOString(),
          post_larva_stocked: pond.post_larva_stocked,
          end_date: dateUtils.formatTZToISO(
            dateUtils.endOfDay(
              dateUtils.getCurrDateInGivenTZ(userTimeZoneString)
            ),
            userTimeZoneString
          ),
          harvest_id: 0
        };
        currentCulture.doc = dateUtils.differenceInDays(
          dateUtils.parseISO(currentCulture.end_date),
          dateUtils.parseISO(currentCulture.start_date)
        );
        cultivationsArr.push(currentCulture);
      }
      return cultivationsArr;
    },
    getHoveredPondCultures: (state, getters, rootState, rootGetters) => {
      const pond = getters.getCurrentHoverPond;
      const cultivationsArr = cloneDeep(pond.cultivations || []);
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      cultivationsArr.forEach(culture => {
        const startDate = dateUtils
          .castUserUTCToBrowserTZDate(culture.start_date, {
            timeZone: userTimeZoneString
          })
          .toISOString();
        const endDate = dateUtils
          .castUserUTCToBrowserTZDate(culture.end_date, {
            timeZone: userTimeZoneString
          })
          .toISOString();
        culture.start_date = startDate;
        culture.end_date = endDate;
        culture.doc = dateUtils.differenceInDays(
          dateUtils.parseISO(culture.end_date),
          dateUtils.parseISO(culture.start_date)
        );
      });
      const isPondInCulture = [
        pond => Boolean(pond),
        pond => pond.status === "ACTIVE",
        pond => Boolean(pond.cultivation_date),
        pond => pond.cultivation_date !== ""
      ].every(x => x(pond));
      if (isPondInCulture) {
        const currentCulture = {
          start_date: dateUtils
            .castUserUTCToBrowserTZDate(pond.cultivation_date, {
              timeZone: userTimeZoneString
            })
            .toISOString(),
          post_larva_stocked: pond.post_larva_stocked,
          end_date: dateUtils.formatTZToISO(
            dateUtils.endOfDay(
              dateUtils.getCurrDateInGivenTZ(userTimeZoneString)
            ),
            userTimeZoneString
          ),
          harvest_id: 0
        };
        currentCulture.doc = dateUtils.differenceInDays(
          dateUtils.parseISO(currentCulture.end_date),
          dateUtils.parseISO(currentCulture.start_date)
        );
        cultivationsArr.push(currentCulture);
      }
      return cultivationsArr;
    },
    getCurrentClickedPond: state => {
      const pondObj = new Pond();
      if (state.currentClickedPond != null) {
        pondObj.castToPondObj(state.currentClickedPond);
      }
      return pondObj;
    },
    getCurrentCulture: (state, getters) => {
      const cultures = getters.getCultures;
      const totalNoOfCultures = cultures.length;
      return cultures[totalNoOfCultures - 1];
    },
    getHoveredPondCurrentCulture: (state, getters) => {
      const cultures = getters.getHoveredPondCultures;
      const totalNoOfCultures = cultures.length;
      return cultures[totalNoOfCultures - 1];
    },
    getCurrDrawingPond: state => state.currDrawingPond,
    getCurrentHoverPond: state => {
      const pondObj = cloneDeep(state.currentHoverPond || new Pond());
      return pondObj;
    },
    getLoadingPondDrawnPlace: state => state.loadingPondDrawnPlace,
    getLoadingStatus: state => state.loading,
    getManualFeedData: state => {
      const newMFeedRecord = {
        pond_id: "",
        date: "",
        feed_dispensed: 0,
        feed_id: "",
        modified: false
      };
      const manualFeedRecords = state.manualFeedData || [];
      const normalizeRecords = manualFeedRecords.reduce(
        (acc, { pond_id, date, feeds }) => {
          const arrFeeds = feeds.map(
            ({ feed_dispensed, feed_id: feed }, index) => {
              const newFeedRecord = cloneDeep(newMFeedRecord);
              newFeedRecord.date = date;
              newFeedRecord.modified = false;
              newFeedRecord.pond_feed_index = index;
              newFeedRecord.feed_id = feed._id;
              newFeedRecord.feed_name = feed.feed_type
                ? `${feed.name}-${feed.feed_type}`
                : feed.name;
              newFeedRecord.feed_dispensed = feed_dispensed;
              return newFeedRecord;
            }
          );
          acc.push(...arrFeeds);
          return acc;
        },
        []
      );
      return normalizeRecords;
    },
    getMapMode: state => state.mapMode,
    getObjPondIdPondHealthData: state => {
      const objPondIdPondHealthData = {};
      state.arrPondHealthData.forEach(pondHealthData => {
        objPondIdPondHealthData[pondHealthData._id] = pondHealthData;
      });
      return objPondIdPondHealthData;
    },
    getPmIdPmDetailsOfSelectedPondId: state => {
      const pmIdPmDetails = {};
      state.pmDetailsOfSelectedPondId.forEach(pm => {
        pmIdPmDetails[pm._id] = pm;
      });
      return pmIdPmDetails;
    },
    getPmSlotWiseFeedGraph: state => {
      return state.pmSlotWiseFeed;
    },
    getPondDrawnPlace: state => state.mapPondDrawnPlace,
    getPondHourlyFeedGraph: state => {
      return state.pondHourlyFeed;
    },
    getPondIdPondMap: (state, getters, rootState, rootGetters) => {
      return rootGetters["pond/getPondsObj"];
    },
    getPonds: state => state.pondsList,
    getPondScheduleGraphSlotWiseFeed: state => {
      return state.slotWiseFeed;
    },
    getSearchedPlaceName: state => state.searchedPlaceName,
    getSelectedPond: (state, getters, rootState, rootGetters) => {
      return getters.getCurrentClickedPond;
    },
    getSelectedPondId: (state, getters) => {
      return (
        getters.getSelectedPond || {
          _id: "no selected pond"
        }
      )._id;
    },
    getStHourlyFeedGraph: state => {
      return state.stHourlyFeed;
    },
    groupAlertsByDeviceType: (state, getters) => {
      const errorAlerts = getters.getArrAvailDeviceTypes.reduce((acc, x) => {
        acc[x] = [];
        return acc;
      }, {});
      getters.getArrAvailDevicesMetaData.reduce(
        (accAllDeviceAlerts, iterObj) => {
          const key = iterObj.bknd_alert_key;
          const device_type = iterObj.device_type;
          state.errorAlerts.forEach(pond => {
            if (pond[key] && pond[key].length !== 0) {
              const arrAlerts = pond[key].reduce((accDeviceAlerts, alert) => {
                const deviceCode = alert.display_code;
                const deviceData = {
                  device_code: deviceCode,
                  device_title: alert.device_title || deviceCode,
                  device_type: device_type,
                  device_id: alert.device_id,
                  battery_voltage: alert.errors_data.map(eachAlert => {
                    if (!eachAlert.details) return;
                    return iterObj.battery_voltage_func(
                      eachAlert.details.display_battery_voltage,
                      eachAlert.details.battery_voltage
                    );
                  })[0]
                };
                const arrAlerts = errorAlertsFactory(
                  pond,
                  alert.errors_data,
                  deviceData
                );
                if (arrAlerts.length > 0) {
                  accDeviceAlerts.push(...arrAlerts);
                }
                return accDeviceAlerts;
              }, []);
              accAllDeviceAlerts[device_type].push(...arrAlerts);
            }
          });
          return accAllDeviceAlerts;
        },
        errorAlerts
      );
      return Object.values(errorAlerts).flat(1);
    },
    getPondIdToErrorAlertsObj: (state, getters) => {
      return getters.groupAlertsByDeviceType.reduce((acc, alert) => {
        if (!acc[alert.pond_id]) {
          acc[alert.pond_id] = [];
        }
        acc[alert.pond_id].push(alert);
        return acc;
      }, {});
    },
    getSizeAlert: state => {
      return state.sizeAlert;
    },
    getExistArrScheds: function(state, getters, rootState, rootGetters) {
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      return state.pondSchedules
        .filter(sched => sched.status !== "DELETED")
        .map(sched => {
          const newSched = JSON.parse(JSON.stringify(sched));
          const date = dateUtils.utcToZonedTime(
            dateUtils.castUserUTCToBrowserTZDate(sched.date, {
              timeZone: userTimeZoneString
            }),
            userTimeZoneString
          );
          newSched.date_secs = date.getTime();
          newSched.time_slots.forEach(ts => {
            ts.pond_mothers.forEach(pm => {
              pm.feed_gap = +(pm.feed_gap / 60).toFixed(1);
            });
          });
          return newSched;
        });
    },
    getExistSchedDtSecsToPondIdToPondSchedDtls: function(state, getters) {
      const dtSecsToPondIdToPondSchedDtls = {};
      getters.getExistArrScheds.forEach(sched => {
        dtSecsToPondIdToPondSchedDtls[
          sched.date_secs + "_" + sched.pond_id
        ] = sched;
      });
      return dtSecsToPondIdToPondSchedDtls;
    },
    getExistSchedDtSecsToPmIdToPmSchedDtls: function(state, getters) {
      const dtSecsToPmIdToPmSchedDtls = {};
      const getObjPmIdPm = getters.getPmIdPmDetailsOfSelectedPondId;
      getters.getExistArrScheds.forEach(sched => {
        const pmObjTS = {};
        const groupByManagedBy = {};
        sched.time_slots.forEach(ts => {
          ts.pond_mothers.forEach(pm => {
            const pmTS = pm;
            pmTS.ts_id = ts._id;
            pmTS.s_time = ts.s_time;
            pmTS.e_time = ts.e_time;
            pmTS.s_time_secs = ts.s_time_in_seconds;
            pmTS.e_time_secs = ts.e_time_in_seconds;
            pmObjTS[pm.pond_mother_code] = pmObjTS[pm.pond_mother_code] || [];
            pmObjTS[pm.pond_mother_code].push(pmTS);
            groupByManagedBy[pm.pond_mother_code] = {
              managed_by: ts.managed_by,
              pond_mother_id: pmTS.pond_mother_id
            };
          });
        });
        Object.keys(pmObjTS).forEach(key => {
          const pmObj = {
            time_slots: [],
            feed: 0,
            dispensed_feed: 0,
            code: "",
            managed_by: ""
          };
          pmObj.code = key;
          pmObj.feed = pmObjTS[key]
            .map(pm => pm.feed)
            .reduce((curr, prev) => prev + curr);
          pmObj.dispensed_feed = pmObjTS[key]
            .map(pm => pm.dispensed_feed)
            .reduce((curr, prev) => prev + curr);
          pmObj.time_slots = pmObjTS[key].sort(
            (a, b) => a.s_time_secs - b.s_time_secs
          );
          const length = pmObj.time_slots.length;
          pmObj.modified =
            pmObjTS[key].find(
              ts => (ts.modified || { status: false }).status === true
            ) || (pmObj.time_slots[length - 1] || {}).modified;
          pmObj.managed_by = groupByManagedBy[key].managed_by;
          pmObj.pond_mother_id = groupByManagedBy[key].pond_mother_id;
          pmObj.title = (
            getObjPmIdPm[pmObj.pond_mother_id] || {
              title: null
            }
          ).title; //  pmTableData.push(pmObj);
          const dayPondIdPmId = `${sched.date_secs}_${sched.pond_id}_${pmObj.pond_mother_id}`;
          dtSecsToPmIdToPmSchedDtls[dayPondIdPmId] = pmObj;
        });
      });
      return dtSecsToPmIdToPmSchedDtls;
    }
  },
  mutations: {
    SET_POND_SCHEDULES(state, pondSchedules) {
      state.pondSchedules = pondSchedules;
    },
    SET_POND_SIZE_ALERT(state, sizeAlert) {
      state.sizeAlert = sizeAlert;
    },
    SET_MAP_MODE(state, mapMode) {
      state.mapMode = mapMode;
    },
    SET_SELECTED_POND_ID: function(state, selectedPondId) {
      state.selectedPondId = selectedPondId;
    },
    SET_CURRENT_CULTURE: function(state, cultureObj) {
      state.currentCulture = cultureObj;
    },
    SET_POND_SLOT_WISE_FEED: function(state, slotWiseLogs) {
      state.slotWiseFeed = slotWiseLogs;
    },
    SET_POND_HOURLY_WISE_FEED: function(state, hourlyFeedLogs) {
      state.pondHourlyFeed = hourlyFeedLogs;
    },
    SET_ST_HOURLY_WISE_FEED: function(state, hourlyFeedLogs) {
      state.stHourlyFeed = hourlyFeedLogs;
    },
    SET_PM_SLOT_WISE_FEED: function(state, slotWiseFeedLogs) {
      state.pmSlotWiseFeed = slotWiseFeedLogs;
    },
    SET_PM_DETAILS_OF_POND: function(state, pmsDetails) {
      state.pmDetailsOfSelectedPondId = pmsDetails || [];
    },
    SET_POND_MANUAL_FEED: function(state, manualFeedData) {
      state.manualFeedData = manualFeedData;
    },
    SET_PONDS_LIST: function(state, arrPonds) {
      state.pondsList = arrPonds;
    },
    SET_SEARCHED_PLACE_NAME(state, searchedPlaceName) {
      state.searchedPlaceName = searchedPlaceName;
    },
    SET_CURRENT_CLICKED_POND(state, pondData) {
      // created new object to prevent the change the value in ponds array
      const pondObj = new Pond();
      if (pondData != null) pondObj.castToPondObj(pondData);
      state.currentClickedPond = pondObj;
    },
    SET_CURRENT_HOVER_POND(state, pondData) {
      const pondObj = new Pond();
      if (pondData != null) pondObj.castToPondObj(pondData);
      state.currentHoverPond = pondObj;
    },
    SET_MAP_POND_DRAWN_PLACE(state, pondDrawnPlace) {
      state.mapPondDrawnPlace = pondDrawnPlace;
    },
    SET_LOADING_POND_DRAWN_PLACE(state, value) {
      state.loadingPondDrawnPlace = value;
    },
    UPDATE_CURRENT_CLICKED_POND(state, { coordinates, area }) {
      // created new object to prevent the change the value in ponds array
      const pondObj = new Pond();
      if (state.currentPond != null) pondObj.castToPondObj(state.currentPond);
      state.currentPond = pondObj;
      state.currentPond.geometry.coordinates = coordinates;
      state.currentPond.pond_coordinates = coordinates;
      state.currentPond.size = +area;
    },
    UPDATE_CURRENT_HOVER_POND(state, { coordinates, area }) {
      // created new object to prevent the change the value in ponds array
      const pondObj = new Pond();
      if (state.currentHoverPond === null) return;
      pondObj.castToPondObj(state.currentHoverPond);
      state.currentHoverPond = pondObj;
      state.currentHoverPond.geometry.coordinates = coordinates;
      state.currentHoverPond.pond_coordinates = coordinates;
      state.currentHoverPond.size = +area;
    },
    UPDATE_CURR_DRAWING_POND(state, { coordinates, area }) {
      const pondObj = new Pond();
      pondObj.geometry.coordinates = coordinates;
      pondObj.pond_coordinates = coordinates;
      pondObj.size = +area;
      state.currDrawingPond = Object.assign({}, state.currDrawingPond, pondObj);
    },
    INIT_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_LOADING_ICON(state, isLoading) {
      state.loading = isLoading;
    },
    SET_POND_HEALTH_DATA(state, arrPondHealthData) {
      state.arrPondHealthData = arrPondHealthData;
    },
    SET_CURR_DRAWING_POND(state, pond) {
      state.currDrawingPond = pond;
    },
    SET_ERROR_ALERTS(state, errorAlerts) {
      state.errorAlerts = errorAlerts;
    },
    SET_ACCESS_DENIED(state, status) {
      state.showAccessDenied = status;
    },
    SET_SELECTED_POND_PGS: function(state, listOfPondGuards) {
      state.arrSelectedPondPGs = listOfPondGuards;
    },
    SET_LATEST_DO_TEMP_VALUES: (state, latestNDOTempValues) => {
      state.latestNDoTempValues = latestNDOTempValues;
    }
  },
  actions: {
    fetchAPondDetails: async function(context, selectedPondId) {
      if (selectedPondId !== "") {
        await context.dispatch("pond/fetchAPond", selectedPondId, {
          root: true
        });

        const pms = await context.dispatch(
          "pondmother/fetchPondMothersOnPondId",
          selectedPondId,
          { root: true }
        );
        context.commit("SET_PM_DETAILS_OF_POND", pms);
      }
    },
    changeCulture: function(context, cultureObj) {
      context.commit("SET_CURRENT_CULTURE", cultureObj);
    },
    fetchAllPondDetails: async function(context, params) {
      await context.dispatch("pond/fetchAllPonds", params, { root: true });
      context.commit("SET_PONDS_LIST", context.rootGetters["pond/getPonds"]);
    },
    fetchSlotWiseFeed: async function(context, { deviceType, params }) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId !== undefined) {
        const response = await FeedManagementService.fetchSlotWisePondFeed(
          pondId,
          {
            ...params
          }
        );
        if (deviceType === "pond") {
          context.commit(
            "SET_POND_SLOT_WISE_FEED",
            response.data.pond_slotwise_feed_logs
          );
        } else if (deviceType === "pond_mother") {
          context.commit(
            "SET_PM_SLOT_WISE_FEED",
            response.data.pond_slotwise_feed_logs
          );
        }
      }
    },
    fetchHourWisePondFeed: async function(context, { deviceType, params }) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId !== undefined) {
        const response = await FeedManagementService.fetchHourWisePondFeed(
          pondId,
          { ...params }
        );
        if (deviceType === "pond") {
          context.commit(
            "SET_POND_HOURLY_WISE_FEED",
            response.data.pond_hourly_feed_logs
          );
        } else if (deviceType === "shrimp_talk") {
          context.commit(
            "SET_ST_HOURLY_WISE_FEED",
            response.data.pond_hourly_feed_logs
          );
        }
      }
    },
    fetchManualFeedData: async function(context, { deviceType, params }) {
      const pondId = context.getters.getSelectedPondId;
      if (pondId !== undefined) {
        const response = await context.dispatch(
          "pond/fetchAPondManualFeedingRecords",
          { pondId, params: { ...params } },
          { root: true }
        );
        context.commit("SET_POND_MANUAL_FEED", response);
      }
    },
    changePondWithDeviceDetails: async function(
      context,
      pondWithDeviceDetails
    ) {
      context.commit(
        "SET_SELECTED_POND_WITH_DEVICE_DETAILS",
        pondWithDeviceDetails
      );
    },
    setMapMode(context, mapMode) {
      context.commit("SET_MAP_MODE", mapMode);
    },
    currDrawingPondInMap: (context, pond) => {
      if (pond != null) {
        context.commit("SET_CURR_DRAWING_POND", pond);
      }
    },
    searchedPlaceName(context, searchedPlaceName) {
      context.commit("SET_SEARCHED_PLACE_NAME", searchedPlaceName);
    },
    currentHoverPondInMap: async (context, pondId) => {
      context.commit("SET_LOADING_ICON", true);
      const pond = cloneDeep(context.getters.getPondIdPondMap[pondId]); // context.getters.getObjPondDataWithHealth[pondId];
      let latest4Records = [
        { abw: 0, awg: 0 },
        { abw: 0, awg: 0 },
        { abw: 0, awg: 0 },
        { abw: 0, awg: 0 }
      ];
      try {
        latest4Records = await context.dispatch(
          "pond/fetchPondLatestABWValues",
          pondId,
          { root: true }
        );
      } catch (err) {
        console.error("failed to fetch abw values");
      } finally {
        context.commit("SET_LOADING_ICON", false);
        const pondHealthData = context.getters.getPondIdToLatestNDOTempValues[
          pondId
        ] || [
          {
            do: undefined,
            temp: undefined,
            ph: 0,
            days_of_cultivation: 0,
            abw: 0,
            awg: [0, 0, 0, 0],
            feed_dispensed: 0,
            total_feed: 0
          }
        ];
        Object.keys(pondHealthData[0]).forEach(key => {
          if (key !== "title") pond[key] = pondHealthData[0][key];
        });
        pond.abw = (latest4Records[0] || { abw: 0 }).abw;
        pond.awg = latest4Records.map(x => ({ awg: x.awg, date: x.date }));
        if (pondId != null) {
          context.commit("SET_CURRENT_HOVER_POND", pond);
        } else {
          console.error("pond id is empty");
        }
      }
    },
    currentClickedPondInMap: async (context, pondId) => {
      context.commit("SET_LOADING_ICON", true);
      const pond = cloneDeep(context.getters.getPondIdPondMap[pondId]); // context.getters.getObjPondDataWithHealth[pondId];
      let latest4Records = [{ awg: 0 }, { awg: 0 }, { awg: 0 }, { awg: 0 }];
      try {
        latest4Records = await context.dispatch(
          "pond/fetchPondLatestABWValues",
          pondId,
          { root: true }
        );
      } catch (err) {
        console.error("failed to fetch abw values");
      } finally {
        context.commit("SET_LOADING_ICON", false);
        const pondHealthData = context.getters.getPondIdToLatestNDOTempValues[
          pondId
        ] || [
          {
            do: undefined,
            temp: undefined,
            ph: 0,
            days_of_cultivation: 0,
            abw: 0,
            awg: [0, 0, 0, 0],
            feed_dispensed: 0,
            total_feed: 0
          }
        ];
        Object.keys(pondHealthData[0]).forEach(key => {
          if (key !== "title") pond[key] = pondHealthData[0][key];
        });
        pond.abw = (latest4Records[0] || { abw: 0 }).abw;
        pond.awg = latest4Records.map(x => ({ awg: x.awg, date: x.date }));

        if (pondId != null) {
          context.commit("SET_CURRENT_CLICKED_POND", pond);
        } else {
          console.error("pond id is empty");
        }
      }
    },
    updateToCurrentHoverPondInMap: (context, { coordinates, area }) => {
      context.commit("UPDATE_CURRENT_HOVER_POND", { coordinates, area });
    },
    updateCurrDrawingPond: (context, { coordinates, area }) => {
      context.commit("UPDATE_CURR_DRAWING_POND", { coordinates, area });
    },
    fetchPondGuardData: async function(context, { pondId, pgId, params }) {
      const response = await WaterQualityService.fetchAllParamsOfWaterQuality(
        pondId,
        pgId,
        params
      );
      return response.data;
    },
    fetchAquaLabPondGuardData: async function(context, { pgId, params }) {
      const response = await WaterQualityService.fetchAquaLabAllParamsOfWaterQuality(
        pgId,
        params
      );
      return response.data;
    },
    fetchPondPGsInCurrCulture: async function(context, { pondId, params }) {
      const response = await PondGuardService.fetchPGsUsedInCurrCulture(
        pondId,
        { get_all: true, ...params }
      );
      context.commit("SET_SELECTED_POND_PGS", response.data.pond_guards);
    },
    async fetchPondHealthData(context, params) {
      await context.dispatch("pond/fetchPondHealthData", params, {
        root: true
      });
      context.commit("SET_POND_HEALTH_DATA", context.rootState.pond.healthData);
    },
    async setMapPondDrawnPlaceLatLngStr(context, pondDrawnPlaceLatLngStr) {
      context.commit("SET_LOADING_POND_DRAWN_PLACE", true);
      let response = await Axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pondDrawnPlaceLatLngStr}&key=${GOOGLE_MAPS_API}`
      );
      response = response.data;
      if (response.status === "REQUEST_DENIED") {
        context.commit("SET_LOADING_POND_DRAWN_PLACE", false);
        throw new Error("Google place api error");
      }
      if (response.status === "OK") {
        const pluscode = response.plus_code.compound_code;
        let place = "Pond Group";
        if (pluscode) {
          place = pluscode
            .split(",")[0]
            .split("+")[1]
            .substr(3)
            .trim();
        } else {
          place = response.results.reverse()[0].formatted_address;
        }
        context.commit("SET_MAP_POND_DRAWN_PLACE", {
          name: place.toLowerCase()
        });
      } else {
        context.commit(
          "SET_MAP_POND_DRAWN_PLACE",
          context.rootGetters["user/getCurrUserLocation"]
        );
      }
      context.commit("SET_LOADING_POND_DRAWN_PLACE", false);
    },
    fetchLatestDoAndTemp: async (context, params = {}) => {
      const response = await WaterQualityService.fetchLatestDOAndTemp(params);
      context.commit(
        "SET_LATEST_DO_TEMP_VALUES",
        response.data.pond_water_quality_summary
      );
    },
    async fetchErrorAlerts(context, params) {
      const pond_id = context.getters.getSelectedPondId;
      const response = await AlertsService.fetchPondAlerts({
        pond_id: pond_id,
        params
      });
      context.commit("SET_ERROR_ALERTS", [response.data.data]);
    },
    async fetchPondSchedules(context, params) {
      const selectedPondId = context.getters.getSelectedPondId;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const userTimeObj = dateUtils.getCurrDateInGivenTZ(userTimeZoneString);
      const selectedDay = dateUtils.formatTZ(userTimeObj, "yyyy-MM-dd", {
        timeZone: userTimeZoneString
      });
      const location_id = context.rootGetters["user/getCurrUserLocation"]._id;
      const response = await ScheduleService.fetchAScheduleByPondId({
        pondId: selectedPondId,
        params: {
          location_id: location_id,
          get_all: true,
          date: selectedDay
        }
      });
      context.commit(
        "SET_POND_SCHEDULES",
        response.data.schedules.length > 0 &&
          response.data.schedules[0].time_slots
          ? response.data.schedules
          : []
      );
    }
  }
};
