import { UAM_UI_STATES } from "@/constants/uiStates";
import { UAM_FILTER_TYPES } from "@/constants/filterTypes";
import { cloneDeep } from "lodash";
import User from "../../model/user";
import UserService from "@/services/userService";
import {
  customerUserTypes,
  customerUserTypeToLangKey,
  groupPermissions
} from "@/middleware/pageAccessManager";
import { lowercase } from "@/utils/filterUtils";
import i18n from "@/config/i18n";
import localStore from "@/utils/localStore";

const INIT_STATE = {
  sidePanel: {
    UI_STATE: UAM_UI_STATES.NO_USER_SELECTED,
    userDetails: new User()
  },
  filters: {
    [UAM_FILTER_TYPES.USER_TYPE]: [...customerUserTypes],
    [UAM_FILTER_TYPES.SEARCH_STRING]: ""
  },
  UAMList: [],
  noAccessSubUsersInAllLocations: []
};
export default {
  namespaced: true,
  state: cloneDeep(INIT_STATE),
  getters: {
    getLocalStoreValue() {
      const selectedFiltersData = JSON.parse(
        localStorage.getItem(
          `${localStore.getItem(
            localStore.getItem("curr-user-type") + "-id"
          )}_userFilterOptions`
        )
      );
      const filtersData = {
        [UAM_FILTER_TYPES.USER_TYPE]: selectedFiltersData,
        [UAM_FILTER_TYPES.SEARCH_STRING]: ""
      };
      console.log("selectedFiltersData---", filtersData);
      return filtersData;
    },
    getUAMListArray: (state, getters) => {
      const currUserProfile = getters.getCurrUserProfile;
      const fitleredUsers = state.UAMList.filter(
        user => user.user_type !== "NO_ACCESS"
      );
      const userPriorityOrder = customerUserTypes.reduce((acc, curr, index) => {
        acc[curr] = index + 2;
        return acc;
      }, {});
      const getUserPriorityOrder = function(user, currUser) {
        if (user._id === currUser._id) {
          return 1;
        }
        return userPriorityOrder[user.user_type];
      };
      return [
        ...fitleredUsers,
        ...getters.getNoAccessSubUsersInAllLocations
      ].map(user => {
        const tempUser = cloneDeep(user);
        tempUser.user_type_lang = i18n.availableLocales.map(locale => {
          return i18n.tc(
            customerUserTypeToLangKey[tempUser.user_type],
            1,
            locale
          );
        });
        tempUser.ui_type =
          user.user_type === "NO_ACCESS"
            ? i18n.t("Comn_inactive")
            : i18n.t("Comn_active");

        tempUser.sort_user_type_key = `${getUserPriorityOrder(
          tempUser,
          currUserProfile
        )}|${user.full_name} | ${tempUser.user_type_lang}`;
        tempUser.timezone = cloneDeep(currUserProfile.timezone);
        tempUser.country = cloneDeep(currUserProfile.country);
        tempUser.parsedPermissions = groupPermissions(tempUser.permissions);
        return tempUser;
      });
    },
    getCurrUserProfile(state, getters, rootState, rootGetters) {
      return rootGetters["user/getUserProfile"];
    },
    getUAMListGroupByUserId: (state, getters) => {
      return getters.getUAMListArray.reduce((acc, user) => {
        acc[user._id] = user;
        return acc;
      }, {});
    },
    getNoAccessSubUsersInAllLocations: state => {
      return state.noAccessSubUsersInAllLocations;
    },
    getAllActiveFilters: function(state, getters) {
      // const filterToValidator = {
      //   [UAM_FILTER_TYPES.USER_TYPE]: (data) => data.length > 0,
      //   [UAM_FILTER_TYPES.SEARCH_STRING]: (value) => value.length > 0,
      // };
      state.filters = getters.getLocalStoreValue;

      return [
        {
          filterType: UAM_FILTER_TYPES.USER_TYPE,
          data:
            getters.getLocalStoreValue.length > 0
              ? getters.getLocalStoreValue
              : state.filters[UAM_FILTER_TYPES.USER_TYPE],
          filterFunc: (allowedUserTypes, user) => {
            return allowedUserTypes.includes(user.user_type);
          }
        },
        {
          filterType: UAM_FILTER_TYPES.SEARCH_STRING,
          data: state.filters[UAM_FILTER_TYPES.SEARCH_STRING],
          filterFunc: (searchString, user, properties) => {
            return properties.some(property => {
              if (Array.isArray(user[property])) {
                return user[property].some(x =>
                  lowercase(x).includes(searchString)
                );
              }
              return lowercase(user[property]).includes(searchString);
            });
          }
        }
      ].filter(({ filterType, data }) => {
        return data;
      });
    },
    getFilteredUAMList: (state, getters) => {
      const filters = getters.getAllActiveFilters;
      return getters.getUAMListArray.reduce((accSubUsers, user) => {
        const filterTypeToParams = {
          [UAM_FILTER_TYPES.USER_TYPE]: filter => [filter.data, user],
          [UAM_FILTER_TYPES.SEARCH_STRING]: filter => [
            filter.data,
            user,
            ["user_type_lang", "email", "full_name"]
          ]
        };
        const isPassedFilter = filters.every(filter =>
          filter.filterFunc(...filterTypeToParams[filter.filterType](filter))
        );
        if (isPassedFilter) {
          accSubUsers.push(user);
        }
        return accSubUsers;
      }, []);
    },
    getFilteredUAMListObj: (state, getters) => {
      return getters.getFilteredUAMList.reduce((acc, user) => {
        acc[user._id] = user;
        return acc;
      }, {});
    },
    getFirstUserInFilteredUAMList: (state, getters) => {
      return getters.getFilteredUAMList[0];
    },
    getSidePanelState(state) {
      return state.sidePanel;
    },
    getSidePanelUser(state, getters) {
      return getters.getSidePanelState.userDetails;
    },
    getDoSidePanelHasValidUser(state, getters) {
      const sidePanelUser = getters.getSidePanelState.userDetails;
      return Boolean(sidePanelUser);
    },
    getIsSidePanelUserFiltered(state, getters) {
      const sidePanelUser = getters.getSidePanelState.userDetails;
      return (
        !getters.getFilteredUAMListObj[sidePanelUser._id] &&
        getters.getUAMListGroupByUserId[sidePanelUser._id]
      );
    },
    getUserCurrLocation(state, getters, rootState, rootGetters) {
      return rootGetters["user/getCurrUserLocation"];
    }
  },
  mutations: {
    SET_SIDE_PANEL_UI_STATE(state, sidePanelUIState) {
      state.sidePanel.UI_STATE = sidePanelUIState;
    },
    SET_SIDE_PANEL_USER_DETAILS(state, userDetails) {
      state.sidePanel.userDetails = userDetails;
    },
    SET_UAM_LIST(state, UAMList) {
      state.UAMList = UAMList;
    },
    SET_NO_ACCESS_SUB_USERS_IN_ALL_LOCATIONS(state, UAMList) {
      state.noAccessSubUsersInAllLocations = UAMList;
    },
    SET_FILTERS(state, newFilter) {
      state.filters[newFilter.filter_type] = newFilter.data;
      state.filters = Object.assign({}, state.filters);
      console.log("filters", state.filters);
    },
    INIT_STORE_STATE: function(state) {
      Object.assign(state, cloneDeep(INIT_STATE));
    }
  },
  actions: {
    clearStoreState: function(context) {
      context.commit("INIT_STORE_STATE");
    },
    changeSidePanelState: function(
      { dispatch, commit, getters },
      { ui_state, user }
    ) {
      const uiStateToDefaultUser = {
        [UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS]:
          getters.getFirstUserInFilteredUAMList,
        [UAM_UI_STATES.CREATE_NEW_SUB_USER_DETAILS]: new User(),
        [UAM_UI_STATES.NO_USER_SELECTED]: new User(),
        [UAM_UI_STATES.EDIT_SUB_USER_DETAILS]: getters.getSidePanelUser,
        [UAM_UI_STATES.UPDATE_SUB_USER_PASSWORD]: getters.getSidePanelUser
      };
      const isUsersListEmpty = !getters.getFilteredUAMList.length;
      if (isUsersListEmpty) {
        ui_state = UAM_UI_STATES.NO_USER_SELECTED;
      }
      switch (ui_state) {
        case UAM_UI_STATES.NO_USER_SELECTED:
          dispatch("changeSidePanelUser", uiStateToDefaultUser[ui_state]);
          dispatch("changeSidePanelUIState", UAM_UI_STATES.NO_USER_SELECTED);
          break;
        case UAM_UI_STATES.CREATE_NEW_SUB_USER_DETAILS:
          dispatch("changeSidePanelUser", uiStateToDefaultUser[ui_state]);
          dispatch(
            "changeSidePanelUIState",
            UAM_UI_STATES.CREATE_NEW_SUB_USER_DETAILS
          );
          break;
        default:
          dispatch(
            "changeSidePanelUser",
            user || uiStateToDefaultUser[ui_state]
          );
          dispatch("changeSidePanelUIState", ui_state);
      }
    },
    changeSidePanelUIState(context, sidePanelUIState) {
      context.commit("SET_SIDE_PANEL_UI_STATE", sidePanelUIState);
    },
    changeFilters(context, newFilter) {
      context.commit("SET_FILTERS", newFilter);
      if (
        context.getters.getDoSidePanelHasValidUser &&
        context.getters.getIsSidePanelUserFiltered
      ) {
        context.dispatch("changeSidePanelState", {
          ui_state: UAM_UI_STATES.NO_USER_SELECTED
        });
      } else if (!context.getters.getDoSidePanelHasValidUser) {
        context.dispatch("changeSidePanelState", {
          ui_state: UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS
        });
      }
    },
    changeSidePanelUser(context, user) {
      context.commit("SET_SIDE_PANEL_USER_DETAILS", user);
    },
    async createSubUser(context, userData) {
      const response = await UserService.createSubUser(userData);
      return response.data.user_details;
    },
    async updateSubUser(context, { userId, payload }) {
      await UserService.updateSubUser(userId, payload);
    },
    async updatePasswordSubUser(context, { userId, payload }) {
      await UserService.updatePasswordSubUser(userId, payload);
    },
    async fetchNoAccessSubUserDetails(context, params) {
      const response = await UserService.fetchSubUserDetails(params);
      const userList = [...response.data.sub_users];
      console.log("userList", userList);
      context.commit("SET_NO_ACCESS_SUB_USERS_IN_ALL_LOCATIONS", userList);
    },
    async fetchSubUserDetails(context, params) {
      if (!params.location_id) {
        context.commit("SET_UAM_LIST", []);
        return;
      }
      const response = await UserService.fetchSubUserDetails(params);
      const mainContainer = [
        ...response.data.sub_users,
        ...response.data.skretting_technicians
      ];
      console.log("userList", mainContainer);
      const userList = mainContainer.map(respUser => {
        const user = new User();
        user.castToUserObj(respUser);
        return user;
      });
      const userIdIndex = userList
        .map(x => x._id)
        .indexOf(context.getters.getCurrUserProfile._id);
      if (userIdIndex > -1) {
        userList.splice(userIdIndex, 1);
      }
      userList.unshift(context.rootGetters["user/getUserProfile"]);
      context.commit("SET_UAM_LIST", userList);
    },
    async restoreToIntialState(
      { getters, dispatch, commit },
      { params, sidePanelUIState, sidePanelUserId, shallFetchData = true }
    ) {
      if (shallFetchData) {
        const tempParams = {
          fetchSubUserDetails: {
            location_id: getters.getUserCurrLocation._id,
            get_all: true
          },
          fetchNoAccessSubUserDetails: {
            user_type: "NO_ACCESS",
            get_all: true
          }
        };
        ["fetchSubUserDetails", "fetchNoAccessSubUserDetails"].forEach(key => {
          if (params && params[key]) {
            tempParams[key] = params[key];
          }
        });
        await Promise.all([
          dispatch("fetchSubUserDetails", tempParams.fetchSubUserDetails),
          dispatch(
            "fetchNoAccessSubUserDetails",
            tempParams.fetchNoAccessSubUserDetails
          )
        ]);
      }
      dispatch("changeSidePanelState", {
        ui_state: sidePanelUIState,
        user: getters.getFilteredUAMListObj[sidePanelUserId]
      });
    }
  }
};
