/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: releaseNotes.js
Description: This file contains all the vuex store functions used in release notes page
*/

import ReleaseNotesService from "../../services/releaseNotes";

const state = {
  releaseListContainer: [],
  userProductManualsData: []
};

const getters = {
  getReleaseList: function(state) {
    return state.releaseListContainer;
  },
  getUserProductManualsData: function(state) {
    return state.userProductManualsData;
  }
};

const mutations = {
  SET_RELEASE_LIST: function(state, releaseList) {
    state.releaseListContainer = releaseList;
  },
  SET_USER_PRODUCT_MANUALS_DATA: function(state, productManualsData) {
    state.userProductManualsData = productManualsData;
  }
};

const actions = {
  fetchReleaseList: async (context, params = {}) => {
    const response = await ReleaseNotesService.fetchReleaseList(params);
    context.commit("SET_RELEASE_LIST", response.data.data);
  },
  setNotificationWatchStatus: async (context, params = {}) => {
    const response = await ReleaseNotesService.setNotificationWatchStatusService(
      params
    );
    console.log("response from status", response);
    // context.commit("SET_RELEASE_LIST", response.data.data);
  },
  fetchUserProductManualPDF: async (context, params = {}) => {
    const response = await ReleaseNotesService.fetchUserProductManualPDF(
      params
    );
    context.commit("SET_USER_PRODUCT_MANUALS_DATA", response.data.data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
