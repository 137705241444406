<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: help.vue
Description: This file displays the list of the items related to the abbrevations used in the application for the user to educate them the meaning of that abbrevation.
-->
<template>
  <er-dialog
    width="60%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog"
    @open="handleOpenDialog"
    :title="$t('Comn_abbreviation')"
    custom-class="help_dialog"
    class="help_view"
  >
    <el-scrollbar
      class="dialog_scroll"
      viewClass="er-column-selector__view"
      wrapClass="er-column-selector__wrap"
    >
      <div class="onHover_div" v-for="(data, index) in myJson" :key="index">
        <div style="display: flex; padding: 10px">
          <div class="abbr_header">
            {{ $t(data.key) }}
          </div>

          <div class="abbr_content">
            {{ $t(data.value) }}
          </div>
        </div>
      </div>
    </el-scrollbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import Help from "@/model/help.json";
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin],
  props: {
    showDialog: {
      default: false
    }
  },
  data: function () {
    return {
      myJson: Help
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    }
  },
  methods: {
    handleOpenDialog() {},
    handleCloseDialog(event) {
      this.$emit("close_dialog", event);
    }
  }
};
</script>

<style lang="scss">
// .help_view {
//   .er-dialog .el-dialog__title {
//     font-size: 20px;
//   }
//   .el-dialog-header {
//     font-size: 20px;
//   }
// }

// .er-dialog .el-dialog__title {
//   font-size: 13px;
// }
.er-dialog .el-dialog__header .el-dialog__title {
  color: #0a2463;
  opacity: 0.9;
  @include responsiveProperty(
    font-size,
    $app_font_size_2/1,
    $app_font_size_3/1,
    $app_font_size_xl/1
  );
}
.er-dialog .el-dialog__headerbtn .el-dialog__close {
  vertical-align: middle;
}

.help_dialog {
  border-radius: 10px;
  .dialog_scroll {
    overflow-y: scroll;
    @include responsiveProperty(height, 350px, 450px, 600px);
    .onHover_div {
      &:hover {
        z-index: 10;
        background-color: #d7dadd;
        border-color: #dddddd;
        transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
        margin: 0 -10px;
        border-radius: 4px;
        margin-left: 0px;
        // @include responsiveProperty(width, 100px, 120px, 130px);
        width: 100%;
      }

      .abbr_header {
        @include responsiveProperty(min-width, 37px, 50px, 55px);
        height: 40px;
        background: #fff;
        border: 1px solid #e4e4e4;
        border: 1px solid #e4e4e4;
        box-shadow: 1px 1px 1px 1px #c7c2c259;
        border-radius: 3px;
        // box-shadow: 0 2px 10px 0 #b3afaf;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-family: "Lato", "HelveticaNeue", "Arial", "sans-serif";
        font-weight: 500;
        @include responsiveProperty(
          font-size,
          $font_size_2/1,
          $app_font_size_2/1,
          $app_font_size_3/1
        );

        color: #343434;
        padding: 0 5px;
      }
      .abbr_content {
        font-family: "Lato", "HelveticaNeue", "Arial", "sans-serif";
        font-weight: 400;
        @include responsiveProperty(
          font-size,
          $font_size_2/1,
          $app_font_size_2/1,
          $app_font_size_3/1
        );
        color: #343434;
        padding: 0 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
