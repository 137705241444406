<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erForm.vue
Description: This file contains component which  is the extension of the element ui form components to use as a base component
-->
<template >
  <el-form ref="elForm" class="er-form" v-bind="$attrs" v-on="inputListeners">
    <slot></slot>
  </el-form>
</template>

<script>
export default {
  inheritAttrs: false,
  data: function () {
    return {};
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          }
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/mixins";
.er-form {
  &.el-form {
    width: 100%;
  }
  &.el-form-item {
    margin: 10px 0px;
    .el-form-item__label {
      width: 20%;
    }
    .el-form-item__content {
      margin: 0px !important;
      width: 80%;
      float: right;
    }
  }
}
</style>
