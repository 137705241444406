<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erLegendItem.vue
Description: This file contains component which is use as an item in the erLegend component.
-->
<script>
export default {
  props: {
    legendTitle: String,
    legendSymbol: String,
    legendSymbolColor: String
  },
  methods: {
    getLegendSymbol (h) {
      switch (this.legendSymbol) {
        case "CIRCLE":
          return h("circle", {
            attrs: {
              fill: this.legendSymbolColor,
              r: "6",
              cx: "50%",
              cy: "50%"
            }
          });
        case "SQUARE":
          return h("rect", {
            attrs: {
              x: 4,
              y: 4,
              rx: "10%",
              width: "50%",
              height: "50%",
              fill: this.legendSymbolColor
            }
          });
      }
    }
  },
  render (h) {
    return h("span", { class: ["er-legend-item"] }, [
      h(
        "svg",
        {
          class: {
            "is-red": this.isRed
          },
          attrs: {
            viewBox: "0 0 12 12",
            width: "12px",
            height: "12px"
          }
        },
        [this.getLegendSymbol(h)]
      ),
      this.$slots.default,
      h("p", { class: ["er-legend-item__title"] }, this.$slots.title)
    ]);
  }
};
</script>

<style lang="scss" scoped>
.er-legend-item {
  display: inline-flex;
  align-items: center;
  .er-legend-item__title {
    padding-left: 5px;
    @include responsiveProperty(align-self, center, center, baseline);
    @include small-text;
  }
}
</style>
