/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: newRelease.js
Description: This file contains all API service calls in relation to Superadmin New Releases
*/

import { $superAdminAxios } from "@/config/axios";
import newAxios from "axios";
const richDataAxios = newAxios.create({});

export default {
  fetchReleaseList({ params }) {
    return $superAdminAxios.get(`admin/ui-versions`, {
      params
    });
  },
  createReleaseVersion({ params }) {
    return $superAdminAxios.post(`admin/ui-versions`, params);
  },
  uploadImageService({ params }) {
    console.log(params, params.name);
    return $superAdminAxios.post(`admin/ui-versions-file-upload`, {
      file_name: params.name
    });
  },
  updateReleaseVersion({ params }) {
    const _id = params._id;
    delete params._id;
    return $superAdminAxios.patch(`admin/ui-versions/${_id}`, {
      ...params
    });
  },
  deleteReleaseVersionService({ params }) {
    const _id = params.paylaod._id;
    return $superAdminAxios.delete(`admin/ui-versions/${_id}`);
  },
  savePictureToTargetUrlService({ target_url, file }) {
    console.log(target_url, file);
    return richDataAxios.put(target_url, file);
  }
};
