<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erListFilter.vue
Description: This file contains the component which is comprised of a search input and list of items useful for selection, filter purposes
-->
<template>
  <el-popover
    :placement="placement"
    class="er-list-filter"
    v-model="visible"
    :popper-class="computedPopperClass"
    ref="colPop"
    @hide="handlePopOverHide"
  >
    <el-row class="er-list-filter__search-wrapper" v-show="allowSearch">
      <el-input
        v-model="searchText"
        size="mini"
        class="er-list-filter__search"
        :placeholder="$t('Comn_search')"
      ></el-input>
    </el-row>
    <el-scrollbar
      viewClass="er-list-filter__view"
      wrapClass="er-list-filter__wrap"
    >
      <el-tree
        v-if="filterElementType === 'NESTED'"
        ref="erListFilterTree"
        :data="treeData"
        :default-expand-all="true"
        :default-checked-keys="defaultCheckedKeys"
        :props="props"
        show-checkbox
        :node-key="nodeKey"
        @check="handleCheckClick"
      ></el-tree>
      <el-row class="er-list-filter__wrapper" v-else>
        <el-checkbox
          v-show="this.searchText === ''"
          :indeterminate="isIndeterminate"
          class="el-checkbox"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >{{ $t("select_all") }}</el-checkbox
        >
        <el-checkbox-group
          v-model="checkedItems"
          @change="handleFilterItemsChanged($event, 'CHANGE')"
        >
          <el-checkbox
            v-for="item in listData"
            :label="item[valueKey]"
            :key="item[valueKey]"
          >
            <slot name="label" :item="item">{{ item[labelKey] }}</slot>
          </el-checkbox>
          <!-- <el-checkbox disabled>No Items Found</el-checkbox> -->
        </el-checkbox-group>
      </el-row>
    </el-scrollbar>
      <er-button
        v-if="allowSave"
        class="btn-save-pref"
        btnType="save"
        size="mini"
        @click="handleSaveDefault"
        :showIcon="true"
        :showLabel="true"
      ></er-button>
    <p v-show="listData.length === 0" class="er-list-filter__empty">
      {{ $t("Comn_no_matches_found") }}
    </p>
    <er-button
      type="text"
      class="btn-list-trigger"
      slot="reference"
      size="small"
      ref="popover-trigger"
    >
      <span class="material-icons-outlined"> filter_alt </span>
    </er-button>
  </el-popover>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    placement: {
      default: "bottom-end"
    },
    props: {
      default: () => ({})
    },
    filterElementType: {
      default: "SINGLE",
      validator: function (value) {
        // The value must match one of these strings
        return ["NESTED", "SINGLE"].indexOf(value) !== -1;
      }
    },
    nodeKey: {
      default: "nodeKey"
    },
    defaultCheckedKeys: {
      default: () => []
    },
    labelKey: {
      default: "title"
    },
    valueKey: {
      default: "id"
    },
    allowSearch: {
      default: true
    },
    allowSave: {
      default: false
    },
    searchFunction: {
      default: undefined
    },
    searchOnKey: {
      default: "title"
    },
    popperClass: {
      default: ""
    }
  },
  data: function () {
    return {
      defaultProps: {
        label: "resource_name"
      },
      visible: false,
      searchText: "",
      isIndeterminate: false,
      checkedItems: this.defaultCheckedKeys,
      checkAll: false
    };
  },
  watch: {
    defaultCheckedKeys: {
      handler: function (newValue, oldValue) {
        this.checkedItems = newValue;
        this.handleFilterItemsChanged(newValue, "INIT");
      },
      immediate: true
    }
  },
  computed: {
     ...mapGetters("user", {
      getUserProfile: "getUserProfile",
    }),
    treeData: function () {
      const treeData = this.data.map((x) => ({ ...x }));
      return treeData;
    },
    listData: function () {
      const listData = this.data.filter((item) =>
        this.defaultSearchFunction(item)
      );
      return listData;
    },
    computedPopperClass() {
      return [this.popperClass, "er-list-filter-pop-container"].join(" ");
    },
    localStorageKey: function () {
      return `${this.getUserProfile._id || 'SuperUser'}_userFilterOptions`;
    }
  },
  methods: {
    async handleCheckClick(...args) {
      const checkedNodes = this.$lodash.cloneDeep(args[1].checkedNodes);
      await this.$emit("select-change", checkedNodes);
    },
    defaultSearchFunction(data) {
      if (!data[this.searchOnKey]) return false;
      return data[this.searchOnKey]
        .toLowerCase()
        .includes(this.searchText.toLowerCase());
    },
    handleCheckAllChange(val, eventType) {
      this.checkedItems = val ? this.data.map((x) => x[this.valueKey]) : [];
      this.isIndeterminate = false;
      this.$emit("check-change", this.checkedItems);
    },
    handleFilterItemsChanged(value, eventType) {
      if (!value) return;
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.data.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.data.length;
      if (eventType === "INIT") return;
      this.$emit("check-change", value);
    },
    handlePopOverHide() {
      this.searchText = "";
    },
    handleSaveDefault() {
      localStorage.removeItem(this.localStorageKey);
      localStorage.setItem(
        this.localStorageKey,
        JSON.stringify(this.checkedItems)
      );
      this.visible = false;
      this.$notify({
        title: this.$t("Usrs_success_msg"),
        message:
          this.type === "ponds"
            ? this.$t("saved-selection-as-defaults")
            : this.$t("saved-selected-columns-as-default-columns"),
        duration: 5000,
        type: "success"
      });
      this.$emit('save-filter', true)
    },
  }
};
</script>

<style lang="scss">
.er-list-filter-pop-container {
  padding: 0px !important;
  min-width: 200px;
  .er-list-filter__wrap {
    max-height: 274px;
    // padding: 5px;
  }
  .el-tree-node__content {
    @include responsiveProperty(height, 26px, 30px, 35px);
    padding-right: 10px;
  }
  .el-tree-node__label {
    font-size: 12px;
  }
  @include er-checkbox-checked;
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #f2f6fc;
    border-color: #dcdfe6;
  }
  .el-checkbox__input.is-disabled + span.el-checkbox__label {
    color: #c0c4cc;
    cursor: not-allowed;
  }
  .el-popover__title {
    padding: 8px;
    margin-bottom: 0px;
    border-bottom: 1px solid lightgray;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    font-weight: 700;
  }
  .er-list-filter__save-pref-row {
    border-top: 1px solid lightgray;
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    & > *:not(:first-child) {
      margin-left: 10px;
      font-size: 0.8em;
      color: #4c68ef;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .btn-save-pref {
    width: 100%;
    text-align: left;
  }
  .el-checkbox__label {
    font-size: 12px;
  }
  .er-list-filter__search-wrapper {
    padding: 5px;
  }
  .er-list-filter__empty {
    padding: 10px 0;
    margin: 0;
    text-align: center;
    color: #999;
    font-size: 14px;
  }
  .er-list-filter__wrapper {
    .el-checkbox {
      width: calc(100% - 10px);
      margin-right: unset;
      padding: 0px 5px;
    }
    .el-checkbox {
      @include responsiveProperty(height, 28px, 30px, 32px);
      @include responsiveProperty(line-height, 28px, 30px, 32px);
      &:hover {
        background-color: $hover-color;
        border-color: $hover-color;
      }
    }
    .el-checkbox-group {
      display: flex;
      flex-direction: column;
    }
  }
}
.er-list-filter {
  display: flex;
  & > span {
    display: flex;
    align-items: center;
  }
  .btn-list-trigger {
    // display: flex;
    padding: 0px;
    vertical-align: middle;
    opacity: 0.3;
    color: black;
  }
  .btn-list-trigger:hover {
    // display: flex;
    padding: 0px;
    vertical-align: middle;
    opacity: 1;
  }
}
</style>
