import pmStatsService from "@/services/superadmin/pmStats.js";

const state = {
  selectedSoftwareVersions: [],
  selectedHardwareVersions: "Any",
  hardwareVersionsData: [],
  softwareVersionsData: [],
  pmStatsData: [],
  pmStatsRawData: [],
  loading: true
};
const getters = {
  getSelectedSoftwareVersions(state) {
    return state.selectedSoftwareVersions;
  },
  getSelectedHardwareVersions(state) {
    return state.selectedHardwareVersions;
  },
  getHardwareVersionsData(state) {
    return state.hardwareVersionsData;
  },
  getSoftwareVersionsData(state) {
    return state.softwareVersionsData;
  },
  getPmStatsData(state) {
    return state.pmStatsData;
  },
  getPmStatsRawData(state) {
    return state.pmStatsRawData;
  },
  getLoading(state) {
    return state.loading;
  }
};
const mutations = {
  SET_SOFTWARE_VERSIONS: function(state, softwareVersions) {
    state.selectedSoftwareVersions = softwareVersions;
  },
  SET_HARD_VERSIONS: function(state, hardwareVersions) {
    state.selectedHardwareVersions = hardwareVersions;
  },
  SET_SOFTWARE_VERSION_DATA: function(state, softwareVersionsData) {
    state.softwareVersionsData = softwareVersionsData.map(value => {
      return {
        value: value,
        label: value
      };
    });
  },
  SET_HARDWARE_VERSION_DATA: function(state, hardwareVersionsData) {
    state.hardwareVersionsData = hardwareVersionsData.map(value => {
      return {
        value: value,
        label: value
      };
    });
    state.hardwareVersionsData.splice(0, 0, { value: "Any", label: "Any" });
  },
  SET_PM_STATS_DATA: function(state, pmStatsData) {
    state.pmStatsData = pmStatsData;
  },
  SET_PM_STATS_RAW_DATA: function(state, pmStatsRawData) {
    state.pmStatsRawData = pmStatsRawData;
  },
  SET_LOADING: function(state, loading) {
    state.loading = loading;
  }
};
const actions = {
  changeSoftwareVersions(context, softwareVersions) {
    context.commit("SET_SOFTWARE_VERSIONS", softwareVersions);
  },
  changeHardwareVersions(context, hardwareVersions) {
    context.commit("SET_HARD_VERSIONS", hardwareVersions);
  },
  async fetchVersionValues(context) {
    try {
      const response = await pmStatsService.fetchVersionValues();
      context.commit(
        "SET_SOFTWARE_VERSIONS",
        response.data.data.software_versions.slice(0, 3)
      );
      context.commit("SET_HARD_VERSIONS", "Any");
      context.commit(
        "SET_SOFTWARE_VERSION_DATA",
        response.data.data.software_versions
      );
      context.commit(
        "SET_HARDWARE_VERSION_DATA",
        response.data.data.hardware_versions
      );
      actions.fetchPMStatsData(context);
      context.commit("SET_LOADING", false);
    } catch (e) {
      console.log("error", e);
    }
  },
  async fetchPMStatsData(context, checked) {
    context.commit("SET_LOADING", true);
    try {
      const response = await pmStatsService.fetchPMStatsData({
        params: {
          software_versions: state.selectedSoftwareVersions,
          communicated_in_last_24_hours: checked === true ? checked : false,
          hardware_versions:
            state.selectedHardwareVersions !== "Any"
              ? [state.selectedHardwareVersions]
              : undefined
        }
      });
      context.commit("SET_PM_STATS_DATA", response.data.data);
      context.commit("SET_PM_STATS_RAW_DATA", response.data.raw_data);
    } catch (e) {
      console.log("error", e);
    } finally {
      context.commit("SET_LOADING", false);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
