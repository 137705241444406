<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: dummy.vue
Description:this file is no longer in use
-->
<template>
  <div>
    <!-- <el-button circle icon="el-icon-arrow-left" @click="handlePrevDay"></el-button>-->

    <!-- <el-date-picker
        type="daterange"
        v-model="x"
        :value-format="valueFormat"
        format
      ></el-date-picker> -->
    <!-- <el-button circle icon="el-icon-arrow-right" @click="handleNextDay"></el-button> -->
    <er-button btnType="play" :showIcon="true" :fab="true"></er-button>
    <!-- <er-list-filter
        :data="data"
        :props="props"
        :allowPersist="true"
      ></er-list-filter> -->
    <!-- <er-date-range :value="value" @pick="handlePick" /> -->
    <er-date-range-picker
      v-model="value1"
      :unlink-panels="true"
      value-format="dd-MM-yyyy HH:mm"
      :format="$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME"
      type="cudatetimerange"
    >
    </er-date-range-picker>
  </div>
</template>

<script>
import dummyData from "@/dummyData";

import erDateRangePicker from "@/components/base/erDateRangePicker.js";

export default {
  components: { erDateRangePicker },
  data: function () {
    return {
      x: ["", ""],
      data: dummyData.data,
      props: {
        label: "resource_name"
      },
      dateRanges: [
        [new Date(), new Date()],
        [new Date(), new Date()]
      ],
      value1: [new Date(), new Date()]
    };
  },
  mounted() {},
  computed: {
    dateFormat() {
      return "YYYY-MM-DD HH:mm";
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          const isSelectedTimeBetweenCultureDate = this.$moment(
            time.getTime()
          ).isBetween(
            this.$lodash
              .cloneDeep(this.getMomentObjArrOfCultureDateRange[0])
              .subtract(1, "days"),
            this.getMomentObjArrOfCultureDateRange[1]
          );
          return !isSelectedTimeBetweenCultureDate;
        },
        shortcuts: [
          { no: 30, unit: "minutes", text: this.$t("last_30minutes") },
          { no: 1, unit: "hours", text: this.$t("last_1hour") },
          { no: 3, unit: "hours", text: this.$t("last_3hours") },
          { no: 6, unit: "hours", text: this.$t("last_6hours") },
          { no: 12, unit: "hours", text: this.$t("last_12hours") },
          { no: 1, unit: "days", text: this.$t("Comn_yesterday") },
          { no: 6, unit: "days", text: this.$t("last_7days") },
          { no: 14, unit: "days", text: this.$t("last_15days") },
          { no: 1, unit: "months", text: this.$t("last_30days") },
          { no: 3, unit: "months", text: this.$t("last_3months") },
          { no: 6, unit: "months", text: this.$t("last_6months") }
        ].map((shortCut) => {
          return {
            text: shortCut.text,
            onClick: (picker) => {
              const end = this.$moment().utcOffset(this.calculatedTimezone);
              const start = this.$moment().utcOffset(this.calculatedTimezone);
              start.subtract(shortCut.no, shortCut.unit);
              picker.$emit(
                "pick",
                [start, end].map((el) => el.format("YYYY-MM-DD HH:mm"))
              );
            }
          };
        })
      };
    }
  },
  methods: {
    handlePick(date) {
      console.log(date);
      this.value = date;
    }
  }
};
</script>

<style lang="scss" scoped></style>
