/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pond.js
Description: This file contains all API service calls in relation to Superadmin Ponds
*/
import { $superAdminAxios } from "@/config/axios";

export default {
  // location
  fetchAllLocationsOfUser({ user_id, params }) {
    return $superAdminAxios.get(`admin/users/${user_id}/locations`, { params });
  },
  // ponds
  fetchAllPonds(params) {
    return $superAdminAxios.get("/admin/ponds", { params });
  },
  fetchAllPondsofUser: async function({ user_id, params }) {
    return $superAdminAxios.get(`admin/${user_id}/ponds`, {
      params
    });
  }
};
