/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: header.js
Description: This file contains all the vuex store functions used in Side Drawer component
*/
import headerService from "../../services/headerService";
export default {
  namespaced: true,
  state: {
    sideNavStatus: false,
    notificationCount: 0,
    notificationData: []
  },
  getters: {
    getSideNavStatus: state => {
      return state.sideNavStatus;
    },
    getNotificationCount: state => {
      return state.notificationCount;
    },
    getNotificationData: state => {
      return state.notificationData;
    }
  },
  mutations: {
    UPDATE_SIDE_NAV_STATUS(state, sideNavStatus) {
      state.sideNavStatus = sideNavStatus;
    },
    SET_NOTIFICATION_COUNT(state, count) {
      state.notificationCount = count;
    },
    SET_NOTIFICATION_DATA(state, data) {
      state.notificationData = data;
    }
  },
  actions: {
    changeSideNavStatus({ commit }, sideNavStatus) {
      commit("UPDATE_SIDE_NAV_STATUS", sideNavStatus);
    },
    async fetchReleaseNotificationCount(context, params) {
      const response = await headerService.fetchReleaseNotificationCountService(
        params
      );
      context.commit("SET_NOTIFICATION_COUNT", response?.data?.data?.count);
      return response?.data;
    },
    async fetchReleaseNotificationData(context, params) {
      const response = await headerService.fetchReleaseNotificationDataService(
        params
      );
      context.commit("SET_NOTIFICATION_DATA", response?.data?.data);
      return response?.data;
    },
    async setReleaseNotificationWatchStatus(context, params) {
      return await headerService.setReleaseNotificationWatchStatusService(
        params
      );
    }
  }
};
