<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: layoutPondlogs.vue                  Description:This file gives the  abstract file(template layout file) used across the n number of components
-->
<template>
  <el-container class="pondlogs-default-layout" direction="vertical">
    <template v-if="['card', 'card-footer'].indexOf(containerType) > -1">
      <el-scrollbar
        class="card-layout-scroll"
        viewClass="card-layout-scroll-view"
        wrapClass="card-layout-scroll-wrap"
      >
        <el-card :class="`card-layout ${subHeader ? 'has-sub-header' : ''}`">
          <template slot="header">
            <layout-toolbar>
              <slot name="custom-title">
                <el-col :span="6">
                  <p class="layout-title">
                    <slot name="title"></slot>
                  </p>
                </el-col>
              </slot>
              <div class="filler"></div>
              <slot name="header"></slot>
            </layout-toolbar>
            <el-row class="el-card__sub-header" v-if="subHeader">
              <slot name="sub-header"></slot>
            </el-row>
          </template>
          <slot name="layout-pondlogs-scrollable-main"></slot>
          <el-footer
            class="el-card__footer"
            v-if="containerType === 'card-footer'"
          >
            <slot name="card-footer"></slot>
          </el-footer>
        </el-card>
      </el-scrollbar>
    </template>
    <template v-else>
      <el-header v-if="headerType === 'card'">
        <el-card shadow="never" class="pondlogs-default-layout-header">
          <slot name="header"></slot>
        </el-card>
      </el-header>
      <el-header v-if="headerType === 'custom'">
        <slot name="header"></slot>
      </el-header>
      <el-main>
        <el-scrollbar
          class="pondlogs-default-layout-scroll"
          viewClass="pondlogs-default-layout-scroll-view"
          wrapClass="pondlogs-default-layout-scroll-wrap"
          v-if="$slots['layout-pondlogs-scrollable-main']"
        >
          <slot name="layout-pondlogs-scrollable-main"></slot>
        </el-scrollbar>
        <slot
          name="layout-pondlogs-main"
          v-if="$slots['layout-pondlogs-main']"
        ></slot>
      </el-main>
    </template>
  </el-container>
</template>

<script>
export default {
  props: {
    headerType: {
      default: undefined
    },
    mainType: {
      default: undefined
    },
    containerType: {
      default: undefined
    },
    subHeader: {
      default: false
    }
  }
};
</script>

<style lang="scss">
.pondlogs-default-layout {
  height: 100%;
  background-color: $pondlogs-background-color;
  padding: 10px;
  .el-header {
    height: unset !important;
    padding: 0px;
    // margin-right: 10px;
    // margin-bottom: 10px;
    // border-radius: 10px;
    h3 {
      font-weight: 600;
    }
  }

  .el-main {
    padding: 0px;
  }
  .card-layout {
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    & > .el-card__header {
      padding: 10px 10px 10px 20px;
      border-bottom: unset;
      position: absolute;
      // margin-top: 0px;
      width: 99.9%;
      background-color: white;
      z-index: 1100;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      line-height: 1.5;
      border-radius: 1px;
      margin-left: -0.5px;
      margin-top: -0.5px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      p.layout-title {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        font-weight: 700;
        color: #0a2463;
      }
      & > .el-card__sub-header {
        padding-top: 10px;
      }
    }
    &.has-sub-header {
      .el-card__body {
        @include responsiveProperty(margin-top, 90px, 90px, 100px);
      }
    }
    & > .el-card__body {
      padding: 0px 20px 0px 20px;
      overflow: hidden;
      @include responsiveProperty(margin-top, 50px, 50px, 60px);
      & > .el-card__footer {
        height: unset !important;
        display: flex;
        // padding-top: 10px;
        font-size: 9px;
        padding-left: 0px;
        padding-right: 0px;
      }
      > *:last-child {
        margin-bottom: 10px;
      }
    }

    .el-input--small {
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
  }
  .pondlogs-default-layout-scroll,
  .card-layout-scroll {
    background: transparent;
    height: 100%;
  }

  .pondlogs-default-layout-scroll-view {
    padding-right: 10px;
    // padding-bottom: 20px;
  }
  .pondlogs-default-layout-scroll-wrap,
  .card-layout-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
}
</style>
