<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: indexAccountManager.vue
Description:This file is main file when Account manager login and gives the functionality over account manger logins.And skrettingTechnicianHeader is the child component of indexAccountManager.vue.
-->
<template>
  <el-container class="account-container">
    <el-header class="header__nav">
      <skrettingTechnicianHeader></skrettingTechnicianHeader>
    </el-header>
    <el-container direction="vertical" class="account-main-container">
      <router-view></router-view>
    </el-container>
  </el-container>
</template>

<script>
import skrettingTechnicianHeader from "@/components/skrettingTechnician/skrettingTechnicianHeader";
export default {
  components: {
    skrettingTechnicianHeader
  }
};
</script>

<style lang="scss">
.account-container {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .account-main-container {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    padding: 10px;
    background-color: #ecf3f9;
  }
  .header__nav {
    height: var(--header-height) !important;
    position: relative;
    border-bottom: 1px solid #dcdfe6;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .header--padding {
    margin-left: 100px;
    margin-right: 100px;
  }
  .el-header {
    padding: 0px 10px;
  }
}
</style>
