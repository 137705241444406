/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondguard.js
Description:  Contains all vuex store functions of pondguard
*/
import { $axios } from "../../config/axios";
import PondGuard from "../../model/pondguard";

const state = {
  pondGuards: [],
  pondGuardSensorData: []
};
const getters = {
  getPondGuards: state => state.pondGuards,
  getPgIdPGObj: state => {
    const pgIdPgObj = {};
    state.pondGuards.forEach(pg => {
      pgIdPgObj[pg._id] = pg;
    });
    return pgIdPgObj;
  },
  getPgIdPGSensorData: state => {
    const pgIdPgObj = {};
    state.pondGuardSensorData.forEach(pg => {
      pgIdPgObj[pg._id] = pg;
    });
    return pgIdPgObj;
  }
};
const mutations = {
  SET_POND_GUARD_DETAILS(state, pondGuardData) {
    if (pondGuardData != null) {
      const pgArr = [];
      pondGuardData.forEach(pondguard => {
        const pgObj = new PondGuard();
        pgObj.castToPGObj(pondguard);
        pgArr.push(pgObj);
      });
      state.pondGuards = pgArr;
    } else {
      state.pondGuards = null;
    }
  },
  SET_POND_GUARD_SENSOR_DATA(state, pondGuardData) {
    if (pondGuardData != null) {
      const pgArr = [];
      pondGuardData.forEach(pondguard => {
        const pgObj = new PondGuard();
        pgObj.castToPGObj(pondguard);
        pgArr.push(pgObj);
      });
      state.pondGuardSensorData = pgArr;
    } else {
      state.pondGuardSensorData = null;
    }
  }
};
const actions = {
  fetchAllPondGuards: function(context, queryParams) {
    return new Promise((resolve, reject) => {
      $axios
        .get("/ponds/pond-guards", {
          params: queryParams
        })
        .then(response => {
          context.commit("SET_POND_GUARD_DETAILS", response.data.pond_guards);
          resolve({
            message: response.data.message,
            totalPGs: response.data.total,
            page: response.data.page
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPondGuardData: function(context, { pondGuardId, params }) {
    return new Promise((resolve, reject) => {
      $axios
        .get(`/ponds/pond-guards/${pondGuardId}/sensor-data`, { params })
        .then(response => {
          context.commit("SET_POND_GUARD_DETAILS", response.data.pond_guards);
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAPondGuard: function(context, pondGuardId) {
    return new Promise((resolve, reject) => {
      $axios
        .get("/ponds/pond-guards/" + pondGuardId)
        .then(response => {
          // context.commit('SET_POND_MOTHER_DATA',response.data.pondGuard)
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addPondGuardDetails: function(context, pondGuard) {
    return new Promise((resolve, reject) => {
      $axios
        .post("/ponds/pond-guards", pondGuard)
        .then(response => {
          // context.commit('SET_POND_MOTHER_DATA',response.data.pondGuard)
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateArrPondGuardDetails: function(context, payload) {
    return new Promise((resolve, reject) => {
      // console.log(pondMotherId)
      $axios
        .patch(`/ponds/pond-guards/settings`, payload)
        .then(response => {
          // context.commit('SET_POND_MOTHER_DATA',response.data.pondGuard)
          // console.log(response.data);
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  unAssignPondGuard: function(context, pondguard) {
    return new Promise((resolve, reject) => {
      // console.log(pondguard);
      $axios
        .delete(`/ponds/pond-guards/${pondguard}`)
        .then(response => {
          // context.commit('SET_POND_MOTHER_DATA',response.data.pondgsuard)
          // console.log(response.data);
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
