/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: units.js
Description: This file contains the constants used for user preferences
*/
const countryToDefaultUnits = {
  india: {
    abw_and_growth_units: "g",
    biomass_per_area_units: { biomass_units: "kg", pond_area_units: "ha" },
    biomass_units: "kg",
    date_format: "dd MMM, yy",
    device_name: "alias_name",
    do_units: "mg/L",
    feed_units: "kg",
    language: "en",
    pond_area_units: "ac",
    temperature_units: "c",
    time_format: "HH:mm",
    feed_gap: "HIDE"
  },
  other: {
    abw_and_growth_units: "g",
    biomass_per_area_units: { biomass_units: "lb", pond_area_units: "ha" },
    biomass_units: "lb",
    date_format: "dd MMM, yy",
    device_name: "alias_name",
    do_units: "mg/L",
    feed_units: "kg",
    language: "es",
    pond_area_units: "ha",
    temperature_units: "c",
    time_format: "HH:mm",
    feed_gap: "HIDE",
    default_dashboard_tab: "dashboardTab"
  }
};

export default countryToDefaultUnits;
