<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erSelectWithOptions.vue
Description: This file contains the select component which is used for generating the selection with options by accepting the array of items as props
-->
<template>
  <el-select
    :class="['er-select-with-options', $attrs.styleType]"
    v-bind="$attrs"
    :value-key="isValueObject ? valueKey : 'value'"
    :popper-class="popperClass"
    v-on="inputListeners"
    ref="erSelect"
    size="small"
  >
    <template slot="prefix">
      <slot name="prefix"></slot>
    </template>
    <slot name="option-prefix">
      <li
        v-if="showSelectAll"
        :class="
          `el-select-dropdown__item select-all ${hoverSelectAll} ${clickedSelectAll}`
        "
        @click="handleSelectAll"
        @mouseenter="hoverSelectAll = 'hover'"
        @mouseleave="hoverSelectAll = ''"
      >
        <span class="item-name">{{ $t("select_all") }}</span>
      </li>
    </slot>
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :value="getOptionValue(item)"
      :label="labelKey ? item[labelKey] : item"
    >
      <slot name="option" :value="item">{{
        labelKey ? item[labelKey] : item
      }}</slot>
    </el-option>
    <slot> </slot>
  </el-select>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      required: true,
      type: [Array, Object],
      default: () => []
    },
    valueKey: {
      type: String,
      default: "value"
    },
    valueType: {
      default: "string"
    },
    labelKey: {
      type: String,
      default: "label"
    },
    showSelectAll: {
      default: false
    }
  },
  data: function() {
    return {
      isIndeterminate: false,
      clickedSelectAll: "",
      hoverSelectAll: "",
      selectAll: false
    };
  },
  computed: {
    getOptionValue() {
      return (item) => {
        if (
          this.$commonUtils.getType(item) === "object" &&
          this.valueType === "string"
        ) {
          return item[this.valueKey];
        }
        return item;
      };
    },
    isValueString() {
      return this.valueType === "string";
    },
    isValueObject() {
      return this.valueType === "object";
    },
    popperClass: function() {
      return this.$attrs["popper-class"] && this.$attrs["popper-class"] === ""
        ? "er-select-with-options__dropdown"
        : "er-select-with-options__dropdown " + this.$attrs["popper-class"];
    },
    inputListeners: function() {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function(event) {
            vm.$emit("input", event);
          }
        }
      );
    }
  },
  // watch: {
  //   "$attrs.value": function() {

  //   },
  // },
  updated() {
    this.$nextTick(() => {
      if (this.showSelectAll) {
        if (this.$refs.erSelect.options.length > 0) {
          this.selectAll =
            this.$attrs.value.length === this.$refs.erSelect.options.length;
          if (this.selectAll) {
            this.clickedSelectAll = "selected";
          } else {
            this.clickedSelectAll = "";
          }
        } else {
          this.selectAll = false;
          this.clickedSelectAll = "";
        }
      }
    });
  },
  methods: {
    handleSelectAll() {
      this.selectAll = !this.selectAll;
      let selectedValues = [];
      if (this.selectAll) {
        this.clickedSelectAll = "selected";
        selectedValues = this.$refs.erSelect.options.map((x) => x.value);
      } else {
        this.clickedSelectAll = "";
        selectedValues = [];
      }
      this.$emit("input", selectedValues);
      this.$emit("selected-all", selectedValues);
    }
  }
};
</script>

<style lang="scss">
.er-select-with-options__dropdown {
  &.is-multiple .el-select-dropdown__item.selected,
  .el-select-dropdown__item.selected {
    color: $selected-color;
  }

  .el-select-dropdown__item {
    @include select-drop-down-item-style;
    @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
    );
    padding: 0 15px;
    text-transform: capitalize;
    &.select-all:not(:hover) {
      position: sticky;
      // color: #1966a1;
      top: 0;
      z-index: 1;
      background: white;
    }
  }
}
.er-select-with-options {
  width: 100%;
  .el-input__inner {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }
  .el-input .el-select__caret {
    // color: #1f2041;
    font-weight: bolder;
  }
  &.no-border {
    .el-input__inner {
      border: none;
    }
    .el-input__suffix-inner i {
      color: $primary-color;
    }
  }
}
</style>
