/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: redirectsMixin.js
Description: This mixin contains the common functionality related to the redirection functionality in components
*/
const redirectsMixin = {
  methods: {
    /**
     * Navigate to sign in page
     */
    rdm__navigateToSignIn() {
      this.$router.push("/sign-in");
    },

    /**
     * Navigate to signup page
     */
    rdm__navigateToSignUp() {
      this.$router.push("/sign-up");
    },

    /**
     * Navigate to forgot password page
     */
    rdm__navigateToForgotPassword() {
      this.$router.push("/forgot-password");
    },

    /**
     * Navigate to give path
     * @param {String} path path
     */
    rdm__navigateToPath(path) {
      this.$router.push(`/${path}`);
    },
    /**
     * Navigate to a path with given query params
     */
    rdm__navigateToPathWithLocationId({ path, query = {} }) {
      if (path) {
        this.$router.push({
          path,
          query: {
            location_id: this.$route.query.location_id,
            ...query
          }
        });
        return;
      }
      this.$router.push({
        query: {
          location_id: this.$route.query.location_id,
          ...query
        }
      });
    },
    rdm__navigateToPathWithCustomerId({ path, query = {} }) {
      if (path) {
        this.$router.push({
          path,
          query: { ...query }
        });
        return;
      }
      this.$router.push({
        query: { ...query }
      });
    }
  }
};

export default redirectsMixin;
