<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name:view404.vue
Description:This file gives the functionality when we redirect the user   to this component when user enters wrong url
-->
<template>
  <div class="view404">
    <div class="content">{{errorCode}}</div>
  </div>
</template>

<script>
export default {
  computed: {
    errorCode () {
      return this.$route.query.errorCode || "Not Found 404";
    }
  }
};
</script>

<style lang="scss">
.view404 {
  position: relative;
  height: 100%;
  .content {
    width: 50%;
    height: 50%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 40%;
  }
}
</style>
