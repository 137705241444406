<template>
  <el-radio-group
    v-model="timerState"
    @change="handleChangeInTimerState"
    size="mini"
    class="er-auto-refresh"
  >
    <el-radio-button :label="true">
      <span class="material-icons-round"> sync </span>
    </el-radio-button>
    <el-radio-button :label="false">
      <span class="material-icons-round"> sync_disabled </span>
    </el-radio-button>
  </el-radio-group>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
      default: () => () => {}
    },
    refreshIntervalInMins: {
      type: Number,
      default: 2
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      timerState: this.value,
      timerToken: undefined
    };
  },
  beforeDestroy () {
    this.cancelAutoUpdate();
  },
  methods: {
    cancelAutoUpdate () {
      clearInterval(this.timerToken);
    },
    handleChangeInTimerState (value) {
      if (value) {
        this.activateTimer();
        return;
      }
      this.cancelAutoUpdate();
    },
    activateTimer () {
      this.timerToken = setInterval(
        this.callback,
        this.refreshIntervalInMins * 60 * 1000
      );
    }
  }
};
</script>

<style lang="scss">
.er-auto-refresh {
  .material-icons-round {
    @include icon-size(icon-font);
  }
  .el-radio-button--mini .el-radio-button__inner {
    padding: 3px 4px;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: $button-color;
    border-color: $button-color;
    -webkit-box-shadow: -1px 0 0 0 $button-color;
    box-shadow: -1px 0 0 0 $button-color;
  }
}
</style>
