<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erLegend.vue
Description: This file contains component which is use for generating the legend if array of item are provided to the component
-->
<template>
  <el-card class="er-legend-container">
    <template>
      <slot></slot>
      <er-legend-item
        :legendSymbol="item.legendType"
        :legend-symbol-color="item.color"
        v-for="(item, key) in legendItems"
        :key="key"
      >
        <template slot="title">
          <slot name="legend-title" v-bind:item="item">
            {{ item.title }}
          </slot>
        </template>
      </er-legend-item>
    </template>
  </el-card>
</template>

<script>
import erLegendItem from "./erLegendItem.vue";
export default {
  components: { erLegendItem },
  props: {
    legendItems: { default: () => [] }
  }
};
</script>

<style lang="scss">
.er-legend-container {
  display: flex;
  justify-content: center;
  .el-card__body {
    @include responsiveProperty(padding, 2px 5px, 2px 5px, 5px 5px);
    @include responsiveProperty(width, 14rem, 15rem, 18rem);
    @media only screen and (min-width: 1028px) and (max-width: 1270px) {
      width: 17rem;
      text-align: center;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 35rem;
      text-align: center;
    }
  }
  .er-legend-item + .er-legend-item {
    padding-left: 5px;
  }
}
</style>
