<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erDateRangeTable.vue
Description: This file is the extension of the element ui date table component used for building the date picker similar to the google dashboards
-->
<script>
import DateTable from "element-ui/packages/date-picker/src/basic/date-table";
import {
  getFirstDayOfMonth,
  getDayCountOfMonth,
  getWeekNumber,
  nextDate,
  clearTime as _clearTime
} from "element-ui/src/utils/date-util";
import { arrayFind, coerceTruthyValueToArray } from "element-ui/src/utils/util";

const getDateTimestamp = function (time) {
  if (typeof time === "number" || typeof time === "string") {
    return _clearTime(new Date(time)).getTime();
  } else if (time instanceof Date) {
    return _clearTime(time).getTime();
  } else {
    return NaN;
  }
};
export default {
  extends: DateTable,
  props: {
    multipleRange: {
      default: false
    },
    currentSelectedRange: {
      default: 0
    }
  },
  watch: {
    rangeState: {
      handler (newV, oldV) {
        const nStartDate = newV[this.currentSelectedRange].startDate;
        const nEndDate = newV[this.currentSelectedRange].endDate;
        const oStartDate = oldV[this.currentSelectedRange].startDate;
        const oEndDate = oldV[this.currentSelectedRange].endDate;
        if (nEndDate) {
          this.markRange(nStartDate, nEndDate);
        } else if (
          getDateTimestamp(nStartDate) !== getDateTimestamp(oStartDate)
        ) {
          this.markRange(nStartDate, nEndDate);
        } else if (getDateTimestamp(nEndDate) !== getDateTimestamp(oEndDate)) {
          this.markRange(nStartDate, nEndDate);
        }
      },
      deep: true
    }
  },
  computed: {
    rows () {
      // TODO: refactory rows / getCellClasses
      const date = new Date(this.year, this.month, 1);
      let day = getFirstDayOfMonth(date); // day of first day
      const dateCountOfMonth = getDayCountOfMonth(
        date.getFullYear(),
        date.getMonth()
      );
      const dateCountOfLastMonth = getDayCountOfMonth(
        date.getFullYear(),
        date.getMonth() === 0 ? 11 : date.getMonth() - 1
      );
      day = day === 0 ? 7 : day;
      const offset = this.offsetDay;
      const rows = this.tableRows;
      let count = 1;
      const startDate = this.startDate;
      const disabledDate = this.disabledDate;
      const cellClassName = this.cellClassName;
      const selectedDate =
        this.selectionMode === "dates"
          ? coerceTruthyValueToArray(this.value)
          : [];
      const now = getDateTimestamp(new Date());
      for (let i = 0; i < 6; i++) {
        const row = rows[i];
        if (this.showWeekNumber) {
          if (!row[0]) {
            row[0] = {
              type: "week",
              text: getWeekNumber(nextDate(startDate, i * 7 + 1))
            };
          }
        }
        for (let j = 0; j < 7; j++) {
          let cell = row[this.showWeekNumber ? j + 1 : j];
          if (!cell) {
            cell = {
              row: i,
              column: j,
              type: "normal",
              inRange: false,
              rangeId: 0,
              moreThanOneRange: 0,
              start: false,
              end: false
            };
          }
          cell.type = "normal";
          const index = i * 7 + j;
          const time = nextDate(startDate, index - offset).getTime();
          const response = this.getIsCellIsInAnyRange(time);
          cell.inRange = response.inRange;
          cell.start = response.start;
          cell.end = response.end;
          cell.rangeId = response.rangeId;
          const isToday = time === now;
          if (isToday) {
            cell.type = "today";
          }
          if (i >= 0 && i <= 1) {
            const numberOfDaysFromPreviousMonth =
              day + offset < 0 ? 7 + day + offset : day + offset;
            if (j + i * 7 >= numberOfDaysFromPreviousMonth) {
              cell.text = count++;
            } else {
              cell.text =
                dateCountOfLastMonth -
                (numberOfDaysFromPreviousMonth - (j % 7)) +
                1 +
                i * 7;
              cell.type = "prev-month";
            }
          } else {
            if (count <= dateCountOfMonth) {
              cell.text = count++;
            } else {
              cell.text = count++ - dateCountOfMonth;
              cell.type = "next-month";
            }
          }
          const cellDate = new Date(time);
          cell.disabled =
            typeof disabledDate === "function" && disabledDate(cellDate);
          cell.selected = arrayFind(
            selectedDate,
            (date) => date.getTime() === cellDate.getTime()
          );
          cell.customClass =
            typeof cellClassName === "function" && cellClassName(cellDate);
          this.$set(row, this.showWeekNumber ? j + 1 : j, cell);
        }
        if (this.selectionMode === "week") {
          const start = this.showWeekNumber ? 1 : 0;
          const end = this.showWeekNumber ? 7 : 6;
          const isWeekActive = this.isWeekActive(row[start + 1]);
          row[start].inRange = isWeekActive;
          row[start].start = isWeekActive;
          row[end].inRange = isWeekActive;
          row[end].end = isWeekActive;
        }
      }
      return rows;
    }
  },
  methods: {
    t (...args) {
      return this.$t(...args);
    },
    getIsCellIsInAnyRange (currentCellTime) {
      for (const eachRange in this.rangeState) {
        const paramStartDate = this.rangeState[eachRange].startDate;
        const paramEndDate = this.rangeState[eachRange].endDate;
        const rangeId = this.rangeState[eachRange].rangeId;
        const isInRange =
          currentCellTime >= getDateTimestamp(paramStartDate) &&
          currentCellTime <= getDateTimestamp(paramEndDate);
        const isCellEqualStartDate =
          paramStartDate &&
          currentCellTime === getDateTimestamp(paramStartDate);
        const isCellEqualEndDate =
          paramEndDate && currentCellTime === getDateTimestamp(paramEndDate);
        if (isInRange) {
          return {
            inRange: true,
            rangeId,
            start: isCellEqualStartDate,
            end: isCellEqualEndDate
          };
        }
      }
      return {
        inRange: false,
        rangeId: -1,
        start: false,
        end: false
      };
    },
    getCellClasses (cell) {
      const selectionMode = this.selectionMode;
      const defaultValue = this.defaultValue
        ? Array.isArray(this.defaultValue)
          ? this.defaultValue
          : [this.defaultValue]
        : [];
      const classes = [];
      if ((cell.type === "normal" || cell.type === "today") && !cell.disabled) {
        classes.push("available");
        if (cell.type === "today") {
          classes.push("today");
        }
      } else {
        classes.push(cell.type);
      }
      if (
        cell.type === "normal" &&
        defaultValue.some((date) => this.cellMatchesDate(cell, date))
      ) {
        classes.push("default");
      }
      if (
        selectionMode === "day" &&
        (cell.type === "normal" || cell.type === "today") &&
        this.cellMatchesDate(cell, this.value)
      ) {
        classes.push("current");
      }
      if (
        cell.inRange &&
        (cell.type === "normal" ||
          cell.type === "today" ||
          this.selectionMode === "week")
      ) {
        classes.push(`in-range in-range-${cell.rangeId}`);
        if (cell.start) {
          classes.push(`start-date start-date-${cell.rangeId}`);
        }
        if (cell.end) {
          classes.push(`end-date end-date-${cell.rangeId}`);
        }
      }
      if (cell.disabled) {
        classes.push("disabled");
      }
      if (cell.selected) {
        classes.push("selected");
      }
      if (cell.customClass) {
        classes.push(cell.customClass);
      }
      return classes.join(" ");
    },
    handleMouseMove (event) {
      if (!this.rangeState[this.currentSelectedRange].selecting) return;
      let target = event.target;
      if (target.tagName === "SPAN") {
        target = target.parentNode.parentNode;
      }
      if (target.tagName === "DIV") {
        target = target.parentNode;
      }
      if (target.tagName !== "TD") return;
      const row = target.parentNode.rowIndex - 1;
      const column = target.cellIndex;
      // can not select disabled date
      if (this.rows[row][column].disabled) return;
      // only update rangeState when mouse moves to a new cell
      // this avoids frequent Date object creation and improves performance
      if (row !== this.lastRow || column !== this.lastColumn) {
        this.lastRow = row;
        this.lastColumn = column;
        this.changeRangeState(this.getDateOfCell(row, column), "endDate", true);
      }
    },
    changeRangeState (date, dateKey, selecting) {
      this.rangeState[this.currentSelectedRange].selecting = selecting;
      this.rangeState[this.currentSelectedRange][dateKey] = date;
    },
    handleClick (event) {
      let target = event.target;
      if (target.tagName === "SPAN") {
        target = target.parentNode.parentNode;
      }
      if (target.tagName === "DIV") {
        target = target.parentNode;
      }
      if (target.tagName !== "TD") return;
      const row = target.parentNode.rowIndex - 1;
      const column = this.selectionMode === "week" ? 1 : target.cellIndex;
      const cell = this.rows[row][column];
      if (cell.disabled || cell.type === "week") return;
      const newDate = this.getDateOfCell(row, column);
      if (this.selectionMode === "multiple-range") {
        if (!this.rangeState[this.currentSelectedRange].selecting) {
          this.changeRangeState(newDate, "startDate", true);
          this.$emit("pick", { minDate: newDate, maxDate: null });
        } else {
          if (newDate >= this.rangeState[this.currentSelectedRange].startDate) {
            this.changeRangeState(newDate, "endDate", false);
            this.$emit("pick", {
              minDate: this.rangeState[this.currentSelectedRange].startDate,
              maxDate: newDate
            });
          } else {
            const maxDate = this.rangeState[this.currentSelectedRange]
              .startDate;
            this.changeRangeState(newDate, "startDate", false);
            this.$emit("pick", { minDate: newDate, maxDate });
          }
        }
      }
    },
    markRange (minDate, maxDate) {
      minDate = getDateTimestamp(minDate);
      maxDate = getDateTimestamp(maxDate) || minDate;
      [minDate, maxDate] = [
        Math.min(minDate, maxDate),
        Math.max(minDate, maxDate)
      ];
      const startDate = this.startDate;
      const rows = this.rows;
      for (let i = 0, k = rows.length; i < k; i++) {
        const row = rows[i];
        for (let j = 0, l = row.length; j < l; j++) {
          if (this.showWeekNumber && j === 0) continue;
          const cell = row[j];
          const index = i * 7 + j + (this.showWeekNumber ? -1 : 0);
          const time = nextDate(startDate, index - this.offsetDay).getTime();
          if (cell.rangeId === this.currentSelectedRange) {
            cell.inRange = minDate && time >= minDate && time <= maxDate;
            cell.start = minDate && time === minDate;
            cell.end = maxDate && time === maxDate;
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
