/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: filterUtils.js
Description: Contains stateless helper functions used for filtering
*/
import { getType } from "@/utils/commonUtils";

import "@/config/elementUi.js";
import { get } from "lodash";
var measures = {
  area: require("../utils/units/area"),
  mass: require("../utils/units/mass")
};
export const emptyPlaceHolder = function() {
  return "--";
};
export const objectValueByPath = function(
  object,
  path,
  symbolToDisplay = emptyPlaceHolder()
) {
  return get(object, path, symbolToDisplay);
};
// digitPrecision
export const digitPrecision = function(
  value,
  precision = 2,
  roundOfType = "round"
) {
  if (value != null && +value === 0) return 0;
  if (!value) return undefined;
  if (!isFinite(value)) return value;
  const roundOfFunctionsMap = {
    ceil: Math.ceil,
    round: Math.round,
    floor: Math.floor
  };
  const roundOfFunction = roundOfType
    ? roundOfFunctionsMap[roundOfType]
    : value => value;
  if (precision === 0) return roundOfFunction(value);
  value = Number(value);
  return (+value.toFixed(precision)).toString();
};
// truncateThousandStr
export const truncateThousandStr = function(
  valueToBeFormatted,
  precision = 1,
  roundOfType = undefined
) {
  let repString;
  const roundOfFunctions = {
    ceil: Math.ceil,
    round: Math.round,
    floor: Math.floor
  };
  const roundOfFunction = roundOfType
    ? roundOfFunctions[roundOfType]
    : value => value;
  if (isNaN(+valueToBeFormatted)) return undefined;
  if (+valueToBeFormatted < 1000) {
    repString = (+valueToBeFormatted).toFixed(0);
  } else if (valueToBeFormatted >= 1000 && valueToBeFormatted < 10000) {
    repString = +(valueToBeFormatted / 1000).toFixed(1).toString() + "k";
  } else {
    repString =
      (+roundOfFunction(valueToBeFormatted / 1000).toFixed(
        precision
      )).toString() + "k";
  }
  return repString.toString();
};
// formatEmpty
export const formatEmpty = function(
  value,
  showSymbolForZero = false,
  symbolToDisplay = emptyPlaceHolder(),
  allowNegative
) {
  const valueDateType = getType(value);
  switch (valueDateType) {
    case "string":
      if (showSymbolForZero && +value === 0) return symbolToDisplay;
      if (value.length === 0) return symbolToDisplay;
      return value;
    case "number":
      if (!isFinite(+value)) return symbolToDisplay;
      if (+value === 0 && !showSymbolForZero) return value;
      if (allowNegative || +value > 0) return value;
      return symbolToDisplay;
    case "object":
      if (Object.keys(value).length === 0) return symbolToDisplay;
      return value;
    case "array":
      if (value.length === 0) return symbolToDisplay;
      return value;
    case "null":
    case "undefined":
      return symbolToDisplay;
    default:
      return value;
  }
};
// formatNumber
export const formatNumber = function(value, countryCode) {
  if (!value) {
    return value;
  }
  return Number(value).toLocaleString(`en-${countryCode}`);
};
export const getUnit = function(abbr) {
  var found;
  Object.entries(measures).forEach(([measure, systems]) => {
    Object.entries(systems).forEach(([system, units]) => {
      Object.entries(units).forEach(([value2, unit]) => {
        if (value2 === abbr) {
          found = {
            abbr: abbr,
            measure: measure,
            system: system,
            unit: unit
          };
        }
      });
    });
  });
  return found;
};
// convertUnits
export const convertUnits = function(val, origin, destination) {
  if (!origin || !destination) return val;
  var result, transform;
  const orginObj = getUnit(origin.toLowerCase());
  const desObj = getUnit(destination.toLowerCase());
  // Don't change the value if origin and destination are the same
  if (orginObj.abbr === desObj.abbr) {
    return val;
  }
  // You can't go from liquid to mass, for example
  if (desObj.measure !== orginObj.measure) {
    throw new Error(
      "Cannot convert incompatible measures of " +
        desObj.measure +
        " and " +
        orginObj.measure
    );
  }
  /**
   * Convert from the source value to its anchor inside the system
   */
  result = val * orginObj.unit.to_anchor;
  /**
   * For some changes it's a simple shift (C to K)
   * So we'll add it when convering into the unit (later)
   * and subtract it when converting from the unit
   */
  if (orginObj.unit.anchor_shift) {
    result -= orginObj.unit.anchor_shift;
  }
  /**
   * Convert from one system to another through the anchor ratio. Some conversions
   * aren't ratio based or require more than a simple shift. We can provide a custom
   * transform here to provide the direct result
   */
  if (orginObj.system !== desObj.system) {
    transform = measures[orginObj.measure]._anchors[orginObj.system].transform;
    if (typeof transform === "function") {
      result = transform(result);
    } else {
      result *= measures[orginObj.measure]._anchors[orginObj.system].ratio;
    }
  }
  /**
   * This shift has to be done after the system conversion business
   */
  if (desObj.unit.anchor_shift) {
    result += desObj.unit.anchor_shift;
  }
  /**
   * Convert to another unit inside the destination system
   */
  return result / desObj.unit.to_anchor;
};
export const formatLakhStr = function(
  valueToBeFormatted,
  precision = 1,
  roundOfType = undefined
) {
  let repString;
  const roundOfFunctions = {
    ceil: Math.ceil,
    round: Math.round,
    floor: Math.floor
  };
  const roundOfFunction = roundOfType
    ? roundOfFunctions[roundOfType]
    : value => value;
  if (isNaN(+valueToBeFormatted)) return 0;
  if (+valueToBeFormatted < 100000) {
    repString = (+valueToBeFormatted).toFixed(0);
  } else if (valueToBeFormatted >= 100000 && valueToBeFormatted < 1000000) {
    repString = +(valueToBeFormatted / 100000).toFixed(1).toString() + "L";
  } else {
    repString =
      (+roundOfFunction(valueToBeFormatted / 100000).toFixed(
        precision
      )).toString() + "L";
  }
  return repString.toString();
};
export const capitalize = function(string) {
  if (!string) return "";
  return string.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
};
export const uppercase = function(string) {
  return string.toLocaleUpperCase();
};
export const lowercase = function(string) {
  return string.toLocaleLowerCase();
};

export const userTypeToText = function(userType) {
  if (!userType) return "";

  const userMap = {
    SUPER_ADMIN: "Super Admin",
    ADMIN: "Admin",
    POND_OWNER: "Owner",
    POND_MANAGER: "Manager",
    POND_TECHNICIAN: "Pond Technician",
    POND_WORKER: "Pond Worker",
    DEALER: "Dealer",
    ACCOUNT_MANAGER: "Account Manager",
    ACCOUNT_MANAGER_SUB_USER: "Skretting Technician"
  };
  return userMap[userType] || "-";
};

export default {
  emptyPlaceHolder,
  digitPrecision,
  formatEmpty,
  truncateThousandStr,
  convertUnits,
  formatLakhStr,
  objectValueByPath,
  formatNumber,
  capitalize,
  uppercase,
  lowercase,
  userTypeToText
};
