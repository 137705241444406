/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: resourceLog.js
Description: This file contains all the vuex store functions used in PondMother schedules page
*/
/* eslint-disable */
import { $axios } from "../../config/axios";
import FeedTemplate from "../../model/feedtemplate";
import { cloneDeep } from "lodash";
import bkPermissions from "@/constants/bkPermissions";
import ResourceManagmentService from "@/services/ResourceManagementService"; // Include the labtest, resources operation

const state = {
  currentFeedTemplateId: "",
  arrSchedules: [],
  arrFts: [],
  mapftIdFT: new Map(),
  mapPondIdFT: null,
  pondsInScheduleMode: [],
  pondsInFT: new Object()
};
const getters = {
  getMapScheduleIdSchedules(state) {
    let mapScheduleIdSchedules = {};
    state.arrSchedules.forEach(schedule => {
      mapScheduleIdSchedules[schedule._id] = schedule;
    });
    return mapScheduleIdSchedules;
  },
  getArrFeedTemplates: (state, getters) => {
    return Object.values(getters["getMapftIdFT"]);
  },
  getPayloadArrFts: (state, getters) => {
    return state.arrFts;
  },
  getMapftIdFT: (state, getters, rootState, rootGetters) => {
    let ftsObj = {};
    state.mapftIdFT &&
      state.mapftIdFT.forEach((value, key) => {
        let template = cloneDeep(value);
        ftsObj[key] = template;
      });
    return ftsObj;
  },
  getMapPondIdFT: (state, getters, rootState, rootGetters) => {
    let pondIdFT = {};
    getters["getArrFeedTemplates"].forEach(template => {
      template.ponds.forEach(({ _id: pondId }) => {
        if (typeof pondId !== "undefined") {
          pondIdFT[pondId] = cloneDeep(template);
        }
      });
    });
    return pondIdFT;
  },
  getPondsHavingTemplates: (state, getters) => {
    return Object.keys(getters["getMapPondIdFT"]);
  },
  getCurrentFeedTemplateId: state => {
    return state.currentFeedTemplateId;
  },
  getArrSchedules: (state, getters, rootState, rootGetters) => {
    return state.arrSchedules;
  },
  getMapPondIdSchedules: (state, getters, rootState, rootGetters) => {
    let mapPondIdSchedules = {};
    getters["getArrSchedules"].forEach(schedule => {
      if (mapPondIdSchedules[schedule.pond_id] === undefined) {
        mapPondIdSchedules[schedule.pond_id] = [];
      }
      mapPondIdSchedules[schedule.pond_id].push(cloneDeep(schedule));
    });
    return mapPondIdSchedules;
  },

  getPondsInScheduleMode: (state, getters, rootState, rootGetters) => {
    return Object.values(
      rootGetters["pond/getObjPondIdPondWithPmsAndMode"]
    ).filter(pond => pond.mode.length === 1 && pond.mode[0] === "FARMER");
  },
  getPondsInBasicMode: (state, getters, rootState, rootGetters) => {
    return Object.values(
      rootGetters["pond/getObjPondIdPondWithPmsAndMode"]
    ).filter(pond => pond.mode.length === 1 && pond.mode[0] === "MANUAL");
  },
  getPondsInAutomaticMode: (state, getters, rootState, rootGetters) => {
    return Object.values(
      rootGetters["pond/getObjPondIdPondWithPmsAndMode"]
    ).filter(pond => pond.mode.length === 1 && pond.mode[0] === "SHRIMP_TALK");
  },
  getPondsInTemplate: (state, getters) => {
    return Object.keys(getters["getMapPondIdFT"]);
  },
  getPondsNotInTemplate: (state, getters, rootState, rootGetters) => {
    return rootGetters["pond/getPonds"].filter(
      pond => getters["getPondsInTemplate"].indexOf(pond._id) === -1
    );
  },
  getPondsInFT: state => state.pondsInFT
};
const mutations = {
  SET_ARR_SCHEDULES: function(state, arrSchedules) {
    state.arrSchedules = arrSchedules;
  },
  PUSH_NEW_FEED_TEMPLATE: function(state, ftObj) {
    if (!state.mapftIdFT.get(ftObj.ft_id)) {
      state.mapftIdFT.set(ftObj.ft_id, ftObj);
    }
  },
  PUSH_POND_FT: function(state, pond) {
    if (!state.pondsInFT.hasOwnProperty(pond._id))
      state.pondsInFT[pond._id] = pond;
  },
  SET_MAP_FTID_FT(state, feedTemplateList) {
    let mapftIdFT = new Map();
    if (feedTemplateList != null) {
      feedTemplateList.forEach(feedTemplate => {
        let ft = FeedTemplate.castToFeedTemplateObj(feedTemplate);
        mapftIdFT.set(ft.ft_id, ft);
      });
      state.mapftIdFT = mapftIdFT;
    } else {
      state.mapftIdFT = null;
    }
  },
  SET_ARR_FTS(state, fts) {
    state.arrFts = fts;
  },
  SET_MAP_PONDID_FT(state, feedTemplateList) {
    let mapPondIdFT = {};
    if (feedTemplateList != null) {
      feedTemplateList.forEach(feedTemplate => {
        let ft = FeedTemplate.castToFeedTemplateObj(feedTemplate);
        feedTemplate.ponds.forEach(pond => {
          mapPondIdFT[pond] = ft;
        });
      });
      state.mapPondIdFT = mapPondIdFT;
    } else {
      state.mapPondIdFT = null;
    }
  },
  SET_CURRENT_FEED_TEMPLATE_ID: function(state, currFeedTemplateId) {
    if (state.mapftIdFT.get(currFeedTemplateId)) {
      state.currentFeedTemplateId = currFeedTemplateId;
    } else if (currFeedTemplateId === "newSet") {
      state.currentFeedTemplateId = "newSet";
    }
  }
};
const actions = {
  fetchAllFeedTemplates: function(context, params) {
    const requiredPermissions = bkPermissions["/schedules/templates"].get;
    const canViewTemplate = context.rootGetters[
      "user/canUserAccessApiByBkPermissions"
    ](requiredPermissions);

    if (!canViewTemplate) {
      console.error("No access to call this api");
      return;
    }
    if (location === "") {
      location = context.rootGetters["user/getCurrUserLocation"];
    }
    return new Promise((resolve, reject) => {
      $axios
        .get("/schedules/templates", { params })
        .then(response => {
          const templates = response.data.templates;
          context.commit("SET_MAP_FTID_FT", templates);
          context.commit("SET_ARR_FTS", templates);
          context.commit("SET_MAP_PONDID_FT", templates);
          let currentTemplateId = context.getters["getCurrentFeedTemplateId"];
          let templatesObj = context.getters["getMapftIdFT"];
          if (
            (currentTemplateId === "" ||
              currentTemplateId === "newSet" ||
              templatesObj[currentTemplateId] === null) &&
            templates.length > 0
          ) {
            context.commit(
              "SET_CURRENT_FEED_TEMPLATE_ID",
              response.data.templates[0]._id
            );
          } else {
            context.commit("SET_CURRENT_FEED_TEMPLATE_ID", "newSet");
          }
          resolve({ message: response.data.message });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createFeedTemplate: function(context, feedTemplate) {
    return new Promise((resolve, reject) => {
      $axios
        .post("/schedules/templates", feedTemplate.getPayloadFormat())
        .then(response => {
          // to make the new added template as the current template
          context
            .dispatch("fetchAllFeedTemplates", {
              location_id: feedTemplate.location_id,
              get_all: true
            })
            .then(response2 => {
              context.commit(
                "SET_CURRENT_FEED_TEMPLATE_ID",
                response.data.template._id
              );
              resolve({ message: response.data.message });
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateFeedTemplate: function(context, feedTemplate) {
    return new Promise((resolve, reject) => {
      $axios
        .patch(
          `/schedules/templates/${feedTemplate._id}`,
          feedTemplate.getPayloadFormat()
        )
        .then(response => {
          // setting the current feed template object as the updated feed template object
          context
            .dispatch("fetchAllFeedTemplates", {
              location_id: feedTemplate.location_id,
              get_all: true
            })
            .then(response2 => {
              context.commit("SET_CURRENT_FEED_TEMPLATE_ID", feedTemplate._id);
              resolve({ message: response.data.message });
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteFeedTemplate: function(context, feedTemplateId) {
    return new Promise((resolve, reject) => {
      $axios
        .delete("/schedules/templates/" + feedTemplateId)
        .then(response => {
          // pushing the new element to the feed templates array
          // context.commit("PUSH_NEW_FEED_TEMPLATE", feedTemplate);
          // setting the current feed template object as the updated feed template object
          // context.commit('REMOVE_FEED_TEMPLATE', feedTemplateId)
          context.dispatch("fetchAllFeedTemplates").then(response2 => {
            resolve({ message: response.data.message });
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setCurrentFeedTemplateId: function(context, currFeedTemplateId) {
    context.commit("SET_CURRENT_FEED_TEMPLATE_ID", currFeedTemplateId);
  },
  fetchAllSchedules: function(context, params = {}) {
    return new Promise((resolve, reject) => {
      $axios
        .get("/ponds/schedules", { params })
        .then(response => {
          context.commit("SET_ARR_SCHEDULES", response.data.schedules);

          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createSchedules: async function(context, payloadSchedules) {
    try {
      const response = await $axios.post("/ponds/schedules", payloadSchedules);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  createPMSchedules: async function(context, payloadSchedules) {
    try {
      const response = await $axios.post(
        "/pond-mothers/schedules",
        payloadSchedules
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  updateSchedules: function(context, payloadSchedules) {
    return new Promise((resolve, reject) => {
      $axios
        .put("/ponds/schedules", payloadSchedules)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePmTimeSlots: function(context, payloadSchedules) {
    return new Promise((resolve, reject) => {
      $axios
        .patch("/ponds/time-slots/pond-mothers", payloadSchedules)
        .then(response => {
          // TODO: need properly handle the response schedule data
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateTodaySchedules: function(context, payloadSchedules) {
    return new Promise((resolve, reject) => {
      $axios
        .put("/ponds/schedules/today", payloadSchedules)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deletePondScheduleByScheduleId: async function(context, scheduleId) {
    try {
      const response = await $axios.delete(`/ponds/schedules/${scheduleId}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  deleteSchedules: async function(context, arrScheduleIds) {
    try {
      const payload = {
        schedule_ids: arrScheduleIds
      };
      const response = await $axios.delete("/ponds/schedules", {
        data: payload
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  deletePmSchedules: async function(context, { pmIds, params }) {
    try {
      const payload = {
        pond_mothers: pmIds
      };
      const response = await $axios.delete("/pond-mothers/schedules", {
        params,
        data: payload
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  deletePmTSFromSchedules: async function(context, payload) {
    try {
      // const payload = {
      //   pond_mothers: arrPmIds,
      // };
      const response = await $axios.delete(
        "/ponds/schedules/time-slots/pond-mothers",
        {
          data: payload
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  deletePondTSFromSchedules: async function(context, payload) {
    try {
      // const payload = {
      //   pond_mothers: arrPmIds,
      // };
      const response = await $axios.delete("/ponds/schedules/time-slots", {
        data: payload
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  changePondMotherStatusInTimeSlot: async function(
    context,
    { statusChangesPayload, status }
  ) {
    try {
      const newStatusToUrlMap = {
        STOPPED: `/ponds/schedules/time-slots/pond-mothers/stop`,
        RUNNING: "/ponds/schedules/time-slots/pond-mothers/resume",
        PAUSED: "/ponds/schedules/time-slots/pond-mothers/pause"
      };
      const response = await $axios.post(
        newStatusToUrlMap[status],
        statusChangesPayload
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  changeSchedStatus: async function(context, { statusChangesPayload, status }) {
    try {
      const newStatusToUrlMap = {
        STOPPED: `/ponds/schedules/stop`,
        RUNNING: "/ponds/schedules/resume",
        PAUSED: "/ponds/schedules/pause"
      };
      const response = await $axios.post(
        newStatusToUrlMap[status],
        statusChangesPayload
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  },
  addFeedToPonds: async function(context, resource) {
    try {
      const response = await ResourceManagmentService.addResourceToPond(
        resource
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteFeedFromPond: async function(context, params) {
    try {
      const response = await ResourceManagmentService.deleteResourceFromPond(
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
