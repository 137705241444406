/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: dealerService.js
Description: This file contains all API service calls in related to dealer
*/
import { $superDealerAxios } from "@/config/axios";
export default {
  fetchAllSubscriptions(params) {
    return $superDealerAxios.get(`/dealer/shrimp-talk-subscription-plans`, {
      get_all: true,
      ...params
    });
  },
  fetchSubscriptionReceivables(params) {
    /*  https://dev-admin-api.eruvaka.dev/1.0/dealer/pond-subscription-shrimp-talks/60b6075c9ec31020607f1ee9?start_date=2021-01-01T00:00:00.000Z&end_date=2021-12-31T00:00:00.000Z
     */

    /* https://dev-admin-api.eruvaka.dev/1.0/dealer/users-shrimp-talk-subscription-receivables?order_by=first_name&order_type=asc&from_date=2021-01-01T00:00:00.000Z&to_date=2021-12-31T00:00:00.000Z&limit=30&page=1&include_device_counts=true
     */

    /*  https://dev-admin-api.eruvaka.dev/1.0/dealer/users-shrimp-talk-subscription-receivables?page=1&limit=30&include_device_counts=true&order_by=first_name&order_type=asc&start_date=2021-01-01T00:00:00.000Z&end_date=2021-12-31T00:00:00.000Z */
    return $superDealerAxios.get(
      `/dealer/users-shrimp-talk-subscription-receivables`,
      {
        params
      }
    );
  },
  fetchCustomerSubscriptionReceivables(customerId, params) {
    /*  https://dev-admin-api.eruvaka.dev/1.0/dealer/users-shrimp-talk-subscription-receivables?page=1&limit=30&include_device_counts=true&order_by=first_name&order_type=asc&start_date=2021-01-01T00:00:00.000Z&end_date=2021-12-31T00:00:00.000Z */
    return $superDealerAxios.get(
      `/dealer/user-shrimp-talk-subscription-receivables/${customerId}`,
      {
        params
      }
    );
  },
  fetchCustomerPondShrimptalks(pondId, params) {
    /*
    https://dev-admin-api.eruvaka.dev/1.0/dealer/user-shrimp-talk-subscription-receivables/5e706b613408bd9112b3952a?order_by=title&get_all=true&start_date=2021-01-01T00:00:00.000Z&end_date=2021-12-31T00:00:00.000Z
    */
    return $superDealerAxios.get(
      `/dealer/pond-subscription-shrimp-talks/${pondId}`,
      {
        params
      }
    );
  }
};
