/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: WaterQualityService.js
Description: This file contains all API service calls in relation to Water Quality
*/
import { $axios } from "@/config/axios";

export default {
  fetchDO(params) {
    return $axios.get("ponds/hourly-feed-and-pond-guard-data", { params });
  },
  fetchDOSummary(params) {
    return $axios.get("ponds/dissolved-oxygen-summary", { params });
  },
  fetchTempSummary(params) {
    return $axios.get("ponds/temperature-summary", { params });
  },
  fetchAllParamsOfWaterQuality(pondId, pgId, params) {
    return $axios.get(`ponds/${pondId}/pond-guards/${pgId}/sensor-data`, {
      params
    });
  },
  fetchAquaLabAllParamsOfWaterQuality(pgId, params) {
    return $axios.get(`ponds/pond-guards/${pgId}/sensor-data`, {
      params
    });
  },
  fetchLatestDOAndTemp(params) {
    return $axios.get("ponds/do-temperature", { params });
  },
  fetchDoRangeStartDate(params) {
    return $axios.get(
      `ponds/pond-guards/${params.pond_guard_id}/${params.parameter}/range-start-time`,
      { params }
    );
  },
  fetchDoInDateRange(params, pgCode) {
    return $axios.get(`ponds/pond-guards/${pgCode}/sensor-data`, {
      params
    });
  }
};
