/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Resource Management
*/
import { $axios } from "../config/axios";

export default {
  saveResourceDetails(payload) {
    return $axios.post("/ponds/medicines-and-minerals", payload);
  },
  fetchResourcesByPondId(pondId, params) {
    return $axios.get(`/ponds/${pondId}/medicines-and-minerals`, { params });
  },
  fetchAllResources() {
    return $axios.get("/ponds/medicines-and-minerals");
  },
  addMedicinesToPonds(params) {
    return $axios.post("/ponds/medicines-and-minerals", params);
  },
  deleteMedicinesAndMineralsInPonds(record_id) {
    return $axios.delete(`/ponds/medicines-and-minerals/${record_id}`);
  },
  fetchLabTests(pond_id, params) {
    return $axios.get(`/ponds/${pond_id}/lab-tests`, { params });
  },
  addLabTestsToPond(payload) {
    console.log(payload);
    return $axios.post("/lab-tests", payload);
  },
  deleteLabTestFromPond(record_id) {
    return $axios.delete(`/lab-tests/${record_id}`);
  },
  fetchLabTestsByLocation(params) {
    console.log(params);
    return $axios.get(`/lab-tests`, { params });
  },
  addResourceToPond(params) {
    return $axios.post("/ponds/feed-type", params);
  },
  deleteResourceFromPond(pond_id) {
    return $axios.delete(`/ponds/${pond_id}/feed-type`);
  },
  fetchAllLabs(params) {
    return $axios.get("/labs", { params });
  },
  deleteLab(labId) {
    return $axios.delete(`/labs/${labId}`);
  },
  // resources actions
  fetchResources(params) {
    return $axios.get("/resources", { params });
  },
  fetchAResource(resourceId) {
    return $axios.get(`/resources/${resourceId}`);
  },
  addResources(resource) {
    return $axios.post("/resources", resource);
  },
  updateResources(resource) {
    return $axios.patch(`/resources/${resource._id}`, resource);
  },
  deleteResource(resourceIds) {
    return $axios.delete(`/resources/${resourceIds}`);
  },
  fetchAllVendors(params) {
    return $axios.get("/resources/vendors", { params });
  },
  fetchAVendor(vendorId) {
    return $axios.get(`/resources/vendors/${vendorId}`);
  },
  addVendor(vendor) {
    return $axios.post("/resources/vendors", vendor);
  },
  AddLab(lab) {
    return $axios.post("/labs", lab);
  },
  updateLab(lab) {
    return $axios.patch(`/labs/${lab._id}`, lab);
  },
  updateVendor(vendor) {
    return $axios.patch(`/resources/vendors/${vendor._id}`, vendor);
  },
  deleteVendor(vendorIds) {
    return $axios.delete(`/resources/vendors/${vendorIds}`);
  }
};
