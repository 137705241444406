<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name:setupScreen.vue
Description:This file gives the functionality when we redirect the user  from pondlogs.com to v2pondlogs.com
-->
<template>
  <div v-loading="loading" class="loading-screen"></div>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false
    };
  },
  mounted () {
    this.initComponent();
  },
  methods: {
    initComponent: async function () {
      this.loading = true;
      setTimeout(async () => {
        await this.$store.dispatch("auth/redirectedUserSignOut");
        this.$router.push({
          path: "/sign-in",
          query: this.$route.query
        });
      }, 1000);
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-screen {
  width: 100vw;
  height: 100vh;
}
</style>
