/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: app.js
Description: This file contains the access keys, versions, backend app url for the pondlogs site
*/
const appVersion = "pondlogs-0.1.0";

const config = {
  DEV: {
    APP_VERSION: appVersion,
    AUTH_KEY_NAME: "dev-" + appVersion,
    GOOGLE_MAPS_API_KEY: "AIzaSyBSzykUt6G-o9KCBqZFJLiC3v8rVfsABAs",
    GOOGLE_MAPS_API_IMAGE_KEY: "AIzaSyDf-4_1HCDClZCXzpbdxpQl6ptGbznLP8k",
    BK_APP_URL: process.env.VUE_APP_BK_APP_URL,
    BK_ADMIN_URL: process.env.VUE_APP_BK_ADMIN_URL,
    BK_DEALER_URL: process.env.VUE_APP_BK_DEALER_URL,
    BK_ACCOUNT_URL: process.env.VUE_APP_BK_DEALER_URL,
    VUE_APP_STAGE: process.env.VUE_APP_STAGE,
    MIXPANEL_ID: "75d8126e3046b75d81e91422e47487c7",
    V3_URL: process.env.VUE_APP_V3_BASE_URL
  },
  STAGING: {
    APP_VERSION: appVersion,
    AUTH_KEY_NAME: "staging-" + appVersion,
    GOOGLE_MAPS_API_KEY: "AIzaSyBSzykUt6G-o9KCBqZFJLiC3v8rVfsABAs",
    GOOGLE_MAPS_API_IMAGE_KEY: "AIzaSyDf-4_1HCDClZCXzpbdxpQl6ptGbznLP8k",
    BK_APP_URL: process.env.VUE_APP_BK_APP_URL,
    BK_ADMIN_URL: process.env.VUE_APP_BK_ADMIN_URL,
    BK_DEALER_URL: process.env.VUE_APP_BK_DEALER_URL,
    BK_ACCOUNT_URL: process.env.VUE_APP_BK_DEALER_URL,
    VUE_APP_STAGE: process.env.VUE_APP_STAGE,
    MIXPANEL_ID: "57acde979c672e6e5aea991c1705d615",
    V3_URL: process.env.VUE_APP_V3_BASE_URL
  },
  PROD: {
    APP_VERSION: appVersion,
    AUTH_KEY_NAME: "prod-" + appVersion,
    GOOGLE_MAPS_API_KEY: "AIzaSyBSzykUt6G-o9KCBqZFJLiC3v8rVfsABAs",
    GOOGLE_MAPS_API_IMAGE_KEY: "AIzaSyDf-4_1HCDClZCXzpbdxpQl6ptGbznLP8k",
    BK_APP_URL: process.env.VUE_APP_BK_APP_URL,
    BK_ADMIN_URL: process.env.VUE_APP_BK_ADMIN_URL,
    BK_DEALER_URL: process.env.VUE_APP_BK_DEALER_URL,
    BK_ACCOUNT_URL: process.env.VUE_APP_BK_DEALER_URL,
    VUE_APP_STAGE: process.env.VUE_APP_STAGE,
    MIXPANEL_ID: "622c31b0148dd24af01d62f8c5dee93c",
    V3_URL: process.env.VUE_APP_V3_BASE_URL
  },
  BETA: {
    APP_VERSION: appVersion,
    AUTH_KEY_NAME: "beta-" + appVersion,
    GOOGLE_MAPS_API_KEY: "AIzaSyBSzykUt6G-o9KCBqZFJLiC3v8rVfsABAs",
    GOOGLE_MAPS_API_IMAGE_KEY: "AIzaSyDf-4_1HCDClZCXzpbdxpQl6ptGbznLP8k",
    BK_APP_URL: process.env.VUE_APP_BK_APP_URL,
    BK_ADMIN_URL: process.env.VUE_APP_BK_ADMIN_URL,
    BK_DEALER_URL: process.env.VUE_APP_BK_DEALER_URL,
    BK_ACCOUNT_URL: process.env.VUE_APP_BK_DEALER_URL,
    VUE_APP_STAGE: process.env.VUE_APP_STAGE,
    MIXPANEL_ID: "75d8126e3046b75d81e91422e47487c7",
    V3_URL: process.env.VUE_APP_V3_BASE_URL
  }
};

const env =
  process.env && process.env.VUE_APP_STAGE ? process.env.VUE_APP_STAGE : "DEV";
export default config[env];
