export const UAM_UI_STATES = {
  DISPLAY_SUB_USER_DETAILS: "DISPLAY_SUB_USER_DETAILS",
  CREATE_NEW_SUB_USER_DETAILS: "CREATE_NEW_SUB_USER_DETAILS",
  EDIT_SUB_USER_DETAILS: "EDIT_SUB_USER_DETAILS",
  UPDATE_SUB_USER_PASSWORD: "UPDATE_SUB_USER_PASSWORD",
  EMPTY_LOCATIONS: "EMPTY_LOCATIONS",
  NO_USER_SELECTED: "NO_USER_SELECTED",
  LOADING: "LOADING"
};
export const ADMIN_USER_UI_STATES = {
  DISPLAY_SUB_USER_DETAILS: "Display",
  CREATE_NEW_SUB_USER_DETAILS: "Create",
  EDIT_SUB_USER_DETAILS: "Edit",
  UPDATE_SUB_USER_PASSWORD: "EditPassword",
  NO_USER_SELECTED: "NoUser",
  LOADING: "LOADING"
};
export const superAdminAccess = {
  customers: "CUSTOMERS",
  devices: "DEVICES",
  gateways: "GATEWAY",
  feedblowers: "FEEDBLOWER",
  pondmothers: "PONDMOTHER",
  pondguards: "PONDGUARD",
  shrimptalks: "SHRIMPTALK",
  shrimpsnaps: "SHRIMPSNAP",
  clients: "CLIENT",
  dealers: "DEALER",
  account: "ACCOUNT_MANAGER",
  "activity-log": "ACTIVITY_LOG",
  AdminHelp: "HELP",
  help: "HELP",
  "inquiry-log": "INQUIRY_LOG",
  subscriptions: "SUBSCRIPTIONS",
  users: "USERS"
};
export default {
  UAM_UI_STATES,
  ADMIN_USER_UI_STATES
};
