<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: subMenuCulture.vue
Description: This file is the part of navigation menu which contains menu items related to culture
-->
<template>
  <el-submenu index="Comn_culture.capitalize" v-if="canShowSubMenuItem">
    <template slot="title">{{ $tc("Comn_culture.capitalize", 1) }}</template>
    <router-link
      v-for="(item, key) in filterMenuItemsByPermissions"
      :key="key"
      :to="item.route"
      class="menu-item-wrapper"
    >
      <li
        class="el-menu-item"
        :class="{ 'is-active': $route.meta.langKey === item.langKey }"
        :index="item.langKey"
        :route="item.route"
      >
        <img class="menu-image" :src="renderMenuIcon(item.imgKey)" />
        <p class="menu-title truncate" v-if="getSideNavStatus">
          {{ $t(item.langKey) }}
        </p>
      </li>
    </router-link>
  </el-submenu>
</template>

<script>
import { mapGetters } from "vuex";
import renderMenuIcon from "./renderMenuIcon";
import { permissionsToStringMap as permissions } from "@/middleware/pageAccessManager";
export default {
  computed: {
    ...mapGetters("header", {
      getSideNavStatus: "getSideNavStatus"
    }),
    menuItems: function () {
      const getRouteObject = (path, newQuery = {}) => {
        return {
          path,
          query: { location_id: this.$route.query.location_id, ...newQuery }
        };
      };
      return {
        FEEDING: {
          langKey: "Comn_feeding",
          route: getRouteObject("/user/feeding", {
            // tab: "pmFeedingTab"
          }),
          imgKey: "FEEDING",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [
            permissions.MANAGE_CULTURE,
            permissions.MANAGE_SCHEDULES,
            permissions.MANAGE_DEVICES
          ]
        },
        MEDICINE: {
          langKey: "Comn_culture_medicine",
          route: getRouteObject("/user/culture-medicine"),
          imgKey: "MEDICINE",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [permissions.MANAGE_MEDICINE]
        },
        ABW: {
          langKey: "ABW_abw",
          route: getRouteObject("/user/abw"),
          imgKey: "ABW",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [permissions.MANAGE_ABW]
        },
        LAB_TESTS: {
          langKey: "LabTst_lab_test",
          route: getRouteObject("/user/lab-tests"),
          imgKey: "LAB_TESTS",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [permissions.MANAGE_LAB_TESTS]
        },
        YIELD: {
          langKey: "Comn_yield",
          route: getRouteObject("/user/yield"),
          imgKey: "YIELD",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [permissions.MANAGE_HARVEST]
        },
        STOCK: {
          langKey: "Stock_stock",
          route: getRouteObject("/user/stock", { tab: "feed" }),
          imgKey: "STOCK",
          keyToValidateAccess: "PERMISSIONS",
          PERMISSIONS: [permissions.MANAGE_STOCK]
        }
      };
    },
    filterMenuItemsByPermissions: function () {
      return this.$gblUAMFilterItemsUserCanView(this.menuItems);
    },
    canShowSubMenuItem: function () {
      return Object.keys(this.filterMenuItemsByPermissions).length > 0;
    }
  },
  methods: {
    renderMenuIcon
  }
};
</script>

<style></style>
