<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erDialog.vue
Description: This file is the extension of the element ui dialog box component to use it as base component all over the pondlogs site
-->
<template>
  <el-dialog v-on="inputListeners" v-bind="$attrs" class="er-dialog">
    <template slot="title">
      <slot name="header"></slot>
    </template>
    <slot></slot>
    <template slot="footer">
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          }
        }
      );
    }
  }
};
</script>

<style lang="scss">
.er-dialog {
  .el-dialog__header {
    padding: 10px 20px;
    .el-dialog__title {
      color: #0a2463;
      opacity: 0.9;
    }
    svg + .el-dialog__title {
      margin-left: 10px;
    }
    border-bottom: 1px solid #f0f0f0;
  }
  .el-dialog__footer {
    border-top: 1px solid #f0f0f0;
    padding: 10px;
  }
  .el-dialog__body {
    padding: 10px 20px;
  }
  .el-button.el-button--mini:not(.is-circle) {
    // padding: 7px 12px;
    // @include responsiveProperty(
    //   font-size,
    //   $app_font_size_1,
    //   $app_font_size_2,
    //   $app_font_size_3
    // );
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    display: flex;
    // word-break: break-word;
    // align-items: center;
    .el-form-item__label {
      @include small-text;
    }
    .el-input__inner {
      background-color: #fffbfb;
    }
  }
  .el-input--small .el-input__inner {
    @include responsiveProperty(height, 32px, 32px, 40px);
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #f54e5e;
    @include responsiveProperty(
      font-size,
      $app_font_size_2,
      $app_font_size_3,
      $app_font_size_xl
    );
  }
  .el-dialog__headerbtn {
    top: 10px;
  }
  .el-form-item--small .el-form-item__error {
    padding-top: 2px;
    // display: grid;
  }

  .el-form-item--small.el-form-item .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    align-items: center;
    display: grid;
    // width: min-content;
  }

  .el-input--small {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
  .el-dialog__title {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
  }
}
.el-select-dropdown__item {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}

.el-select-dropdown__item {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
</style>
