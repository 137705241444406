/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: storeRegisterHandlerUtils.js
Description: This file contains definitions of stateless helper functions used in vuex store registration
*/
import store from "@/store/index";
import { cloneDeep } from "lodash";
export const handleStoreRegistration = function(to, from) {
  const currStoreModules = to.meta.storeModules;
  const prevStoreModules = from.meta.storeModules;
  const prevChildStoreModules = from.meta.childStoreModules;
  unregisterObjOfStoreModules(prevStoreModules);
  if (prevChildStoreModules) {
    unregisterObjOfStoreModules(prevChildStoreModules);
  }
  registerObjOfStoreModules(currStoreModules);
};
export const registerObjOfStoreModules = function(storeModules) {
  for (const key in storeModules) {
    if (storeModules[key] && !store.hasModule(storeModules[key].name)) {
      store.registerModule(
        storeModules[key].name,
        cloneDeep(storeModules[key].module),
        { preserveState: false }
      );
    }
  }
};
export const unregisterObjOfStoreModules = function(storeModules) {
  if (!storeModules) return;
  for (const key in storeModules) {
    if (storeModules[key] && store.hasModule(storeModules[key].name)) {
      store.dispatch("CANCEL_PENDING_REQUESTS");
      store.unregisterModule(storeModules[key].name);
    }
  }
};
export default {
  handleStoreRegistration,
  registerObjOfStoreModules,
  unregisterObjOfStoreModules
};
