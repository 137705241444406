<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: navigationMenu.vue
Description: This file is the parent component of the navigation menus.
-->
<template>
  <a
    v-on:mouseover="handleNavStatus(true)"
    v-on:mouseleave="handleNavStatus(sidemove)"
  >
    <el-menu
      class="navigation-menu"
      :default-openeds="openedSubItems"
      @open="handleSubMenuOpen"
      @close="handleSubMenuClose"
    >
      <el-scrollbar class="navigation-menu-scroll">
        <ul class="nav__header">
          <li class="company-logo">
            <router-link to="/user/dashboard?tab=dashboardTab">
              <pondlogs-logo class="menu-icon" color="#0A2463"></pondlogs-logo>
            </router-link>
          </li>

          <li class="nav__header-title">
            <router-link to="/user/dashboard?tab=dashboardTab">
              <p>PondLogs</p>
            </router-link>
          </li>
          <li class="nav__checkbox">
            <el-button
              size="mini"
              type="text"
              @click="freezethenavigation(checked)"
            >
              <div v-if="this.checked">
                <img
                  width="25px;"
                  height="15px;"
                  ref="lock"
                  :src="otherImage"
                  @mouseover="setCloseNewImage()"
                  @mouseleave="setCloseOldImage()"
                />
              </div>
              <div v-else>
                <img
                  width="25px;"
                  height="15px;"
                  ref="unlock"
                  :src="myImage"
                  @mouseover="setOpenNewImage()"
                  @mouseleave="setOpenOldImage()"
                />
              </div>
            </el-button>
          </li>
        </ul>

        <hr class="divider" />
        <sub-menu-overview></sub-menu-overview>
        <sub-menu-culture></sub-menu-culture>
        <sub-menu-manage></sub-menu-manage>
        <router-link
          class="menu-item-wrapper help-section-menu-item  truncate"
          index="Comn_help-section"
          :to="{
            path: '/user/help',
            query: mergeQueryParams({ tab: 'releaseNotes' })
          }"
        >
          <li
            class="el-menu-item el-menu-item-help"
            index="Comn_helpSection"
            :class="{
              'is-active': $route.meta.langKey === 'Comn_help-section'
            }"
            :to="{
              path: '/user/help',
              query: mergeQueryParams({ tab: 'releaseNotes' })
            }"
          >
            <img src="@/assets/help/Help_icon.svg" />
            <p class="menu-title truncate" v-if="getSideNavStatus">
              {{ $tc("Comn_help-section", 2) }}
            </p>
          </li>
        </router-link>
        <router-link
          class="menu-item-wrapper settings-menu-item truncate"
          index="Comn_settings"
          :to="{
            path: '/user/settings',
            query: mergeQueryParams({ tab: 'userProfile' })
          }"
        >
          <li
            class="el-menu-item"
            index="Comn_settings"
            :class="{ 'is-active': $route.meta.langKey === 'Comn_settings' }"
            :to="{
              path: '/user/settings',
              query: mergeQueryParams({ tab: 'userProfile' })
            }"
          >
            <img src="@/assets/navigation/nav-settings.svg" />
            <p class="menu-title truncate" v-if="getSideNavStatus">
              {{ $tc("Comn_settings", 2) }}
            </p>
          </li>
        </router-link>
        <!-- <el-menu-item
          class="truncate settings-menu-item"
          index="Comn_settings"
          route="/user/settings?tab=userProfile"
        >
          <img src="@/assets/navigation/nav-settings.svg" />

          <el-link
            slot="title"
            :underline="false"
            v-if="this.getSideNavStatus"
          >{{ $tc("Comn_settings", 2) }}</el-link>
        </el-menu-item> -->
      </el-scrollbar>
    </el-menu>
  </a>
</template>

<script>
import pondlogsLogo from "./pondlogsLogo";
import subMenuOverview from "./subMenuOverview";
import subMenuCulture from "./subMenuCulture";
import subMenuManage from "./subMenuManage";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isCollapse: true,
      sidemove: true,
      checked: false,
      opened: new Set([
        "Comn_overview",
        "Comn_culture.capitalize",
        "Comn_manage"
      ]),
      src: this.myImage,
      myImage: require("@/assets/dashboard/unlock.svg"),
      otherImage: require("@/assets/dashboard/lock.svg")
    };
  },
  components: {
    pondlogsLogo,
    subMenuOverview,
    subMenuCulture,
    subMenuManage
  },
  computed: {
    ...mapGetters("header", {
      getSideNavStatus: "getSideNavStatus"
    }),
    openedSubItems() {
      return [...this.opened];
    }
  },
  methods: {
    ...mapActions("header", {
      changeSideNavStatus: "changeSideNavStatus"
    }),
    mergeQueryParams(newQueryParams) {
      return {
        location_id: this.$route.query.location_id,
        ...newQueryParams
      };
    },
    freezethenavigation(value) {
      if (!value) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      if (this.checked) {
        this.sidemove = true;
      } else {
        this.sidemove = false;
      }
    },
    handleNavStatus(value) {
      if (value) {
        if (this.checked) {
          this.sidemove = true;
          localStorage.setItem("navigationMenu", true);
        } else {
          this.sidemove = false;
          localStorage.setItem("navigationMenu", false);
        }
      }
      this.changeSideNavStatus(value);
    },
    handleSubMenuOpen(index) {
      this.opened.add(index);
    },
    handleSubMenuClose(index) {
      this.opened.delete(index);
    },
    handleMenuSelect(menuObjItem, indexPath, item) {
      const appTitle = document.getElementById("appTitle");
      appTitle.innerHTML = `${this.$t(menuObjItem)} | PondLogs`;
    },
    setOpenNewImage() {
      console.log(this.$refs.unlock);
      this.$refs.unlock.src = this.otherImage;
    },
    setOpenOldImage() {
      this.$refs.unlock.src = this.myImage;
    },
    setCloseNewImage() {
      this.$refs.lock.src = this.myImage;
    },
    setCloseOldImage() {
      this.$refs.lock.src = this.otherImage;
    }
  },
  mounted() {
    const value = localStorage.getItem("navigationMenu");
    if (value === "true") {
      this.changeSideNavStatus(true);
      this.checked = true;
    } else {
      this.changeSideNavStatus(false);
      this.checked = false;
    }
  }
};
</script>

<style lang="scss">
$font-color: #1f2041;
$hover-color: #ebf2f9;
$selected-item-color: #cde4ff;
$selected-item-font-color: #155dbe;
$background-color: linear-gradient(270deg, #f8f9fa 0%, #ffffff 100%);
.navigation-menu-scroll {
  .el-scrollbar__view {
    bottom: 0px;
    height: 120px;
  }
  .el-scrollbar__wrap {
    height: 85% !important;
  }
}
.remove__whitespaces {
  display: none;
}

.navigation-menu {
  .el-checkbox__inner {
    border-radius: 25px;
    border: 2px solid #c0c4cc !important;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $primary-color;
    border-color: #c0c4cc;
  }

  .el-checkbox__inner::after {
    border: 0px;
  }

  .el-submenu:nth-last-child(2) {
    margin-bottom: 40px;
  }

  .settings-menu-item {
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    background-color: white;
  }
  .help-section-menu-item {
    position: absolute;
    width: 100%;
    bottom: 34px;
    z-index: 1;
    background-color: white;
  }
  // .help-section-menu-item {
  //   position: absolute;
  //   width: 100%;
  //   bottom: 38px;
  //   z-index: 2002;
  //   background-color: white;
  // }
  &.el-menu {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: $background-color;
    border-right: unset;
  }
  .el-menu.el-menu--inline {
    background: $background-color;
  }
  .nav__header {
    position: sticky;
    background: $background-color;
    height: calc(var(--header-height) - 1.7px);
    top: 0px;
    z-index: 2004;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    color: $font-color;
    .company-logo {
      margin-right: 10px;
      margin-left: 10px;
      img {
        display: block;
      }
    }
    .nav__header-title {
      a {
        color: $font-color;
        cursor: pointer;
        text-decoration: none;
        p {
          @include responsiveProperty(
            font-size,
            $app_font_size_3,
            $app_font_size_xl,
            $app_font_size_xl
          );
          font-weight: 500;
        }
      }
    }
    .nav__checkbox {
      @include responsiveProperty(padding-left, 30px, 20px, 20px);
    }
  }
  .remove__whitespaces {
    display: none;
  }
  .divider {
    position: sticky;
    top: calc(var(--header-height) - 1px);
    background: #242424;
    opacity: 0.2;
    border: 0.2px solid #242424;
  }

  .el-link {
    overflow: hidden;
    width: 80%;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .el-submenu__title {
    height: unset;
    line-height: unset;
    padding: 9px 10px 10px 10px !important;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    color: $font-color;
    background-color: $hover-color;
    &:hover {
      background-color: $hover-color;
    }
  }
  .el-menu-item {
    height: unset;
    line-height: unset;
    padding: 9px 10px 10px 10px !important;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );

    .el-link--inner {
      color: $font-color;
      // opacity: 0.65;
      @include responsiveProperty(
        font-size,
        $app_font_size_1,
        $app_font_size_2,
        $app_font_size_3
      );
    }
  }
  .el-menu-item-help {
    z-index: 0;
  }
  .el-submenu__icon-arrow {
    border: 1px solid #9da7c1;
    border-radius: 50%;
    padding: 1px;
    color: #9da7c1;
  }
  .menu-item-wrapper {
    text-decoration: none;
    &:focus,
    &:hover {
      color: $font-color;
      font-weight: 500;
      opacity: 1;
    }
    &.is-active {
      color: $selected-item-font-color;
    }
    .el-menu-item {
      align-items: center;
      display: flex;
      min-width: unset;
      .menu-title {
        flex-grow: 1;
        margin: 0px 10px;
      }
      &:focus,
      &:hover {
        color: $font-color;
        background-color: $hover-color;
      }
      &.is-active {
        color: $selected-item-font-color;
        background-color: $selected-item-color;
      }
    }
  }
  img {
    align-self: center;
    margin: 0 auto;
    @include icon-size(svg, prefix);
  }
}
</style>
