import { $axios } from "../config/axios";

export default {
  getReleaseInfo() {
    return $axios.get(`/user-and-notifications`);
  },
  setWatchStatus(params) {
    return $axios.post(`/user-and-notifications/add-watched-status`, {
      ...params
    });
  }
};
