/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: filtersMixin.js
Description: This mixin is useful for handling the filters useful for a component to formatting
*/
// import { getType } from "@/utils/commonUtils";
import filterUtils from "@/utils/filterUtils";
import { mapGetters } from "vuex";
const filtersMixin = {
  computed: {
    ...mapGetters("user", {
      getUserCountryCode: "getUserCountryCode"
    })
  },
  methods: {
    ...Object.keys(filterUtils).reduce((acc, currKey) => {
      acc[`ftm__${currKey}`] = filterUtils[currKey];
      return acc;
    }, {})
  }
};
export default filtersMixin;
