/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: DeviceHealthService.js
Description: This file contains all API service calls in relation to Device Health
*/
import { $axios } from "@/config/axios";
export default {
  fetchLocationAlerts(params) {
    return $axios.get(`ponds/error-alerts-new`, { params });
  },
  fetchPondAlerts({ pond_id, params }) {
    return $axios.get(`ponds/${pond_id}/error-alerts`, { params });
  },
  fetchDeviceStatusForPonds: function(params) {
    return $axios.get("/ponds/device-summary", { params });
  },
  fetchLocationAlertsHistory(params) {
    return $axios.get(`error-alerts-new2-pagination`, { params }); // error-alerts-new2
  },
  patchAlertsHistoryData(params) {
    return $axios.patch(`error-alerts-new2`, params);
  }
};
