/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: user.js
Description: This file contains all API service calls in relation to Superadmin User
*/
import { $superAdminAxios } from "@/config/axios";

export default {
  fetchAllUsers: function({ params }) {
    return $superAdminAxios.get("/admin/users", { params });
  },
  fetchUserToken: function({ user_id, params }) {
    return $superAdminAxios.get(`/admin/user/${user_id}/signin`, { params });
  },
  fetchUserProfile() {
    return $superAdminAxios.get("/profile");
  },
  fetchAUserDetails: function({ user_id, params }) {
    return $superAdminAxios.get(`admin/users/${user_id}`, {
      params
    });
  }
};
