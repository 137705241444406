/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondTS.js
Description: This file contains the model, functions of pond timeslot used in the pondlogs customer site
*/
import {
  objectIdGenerator,
  timeStrHHmmVal,
  castSecsHHmmStr
} from "@/utils/commonUtils";
import {
  calcPondScheduleEndTime,
  calcPondScheduleStartTime,
  calcFG,
  calcMaxTFForADay,
  suggestOCFForPm,
  calcTF
} from "@/utils/scheduleParamsCalculationUtils";
import {
  INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS,
  INIT_OCF_VALUE,
  MIN_TOTAL_FEED_VALUE_ST_MODE,
  MAX_TOTAL_FEED_VALUE_ST_MODE,
  MIN_FEED_GAP_VALUE,
  POND_TS_STATUS,
  MAX_OCF_VALUE,
  TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
  POND_MODES,
  MIN_S_TIME_SECS as C_MIN_S_TIME_SECS,
  MIN_E_TIME_SECS as C_MIN_E_TIME_SECS,
  MAX_E_TIME_SECS as C_MAX_E_TIME_SECS,
  MAX_E_TIME_SECS_ALLOWED as C_MAX_E_TIME_SECS_ALLOWED,
  TS_CREATED_BY,
  SAVED_AT_DEVICE_STATUS
} from "@/constants/schedule";
import dateUtils from "@/utils/dateUtils";
import PondMotherTS from "./pondMotherTS";
const MIN_S_TIME_SECS = C_MIN_S_TIME_SECS;
const MIN_S_TIME = castSecsHHmmStr(MIN_S_TIME_SECS);
const MIN_E_TIME_SECS = C_MIN_E_TIME_SECS;
const MIN_E_TIME = castSecsHHmmStr(MIN_E_TIME_SECS);
const cmCurrTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ;
export default class PondTS {
  constructor(pond_id, pondMode, created_by) {
    this.duration = 1;
    // identification params
    this.ui_id = objectIdGenerator();
    this.setStIds = new Set();
    this.bk_id = undefined;
    this.previousMode = undefined;
    this.mode = pondMode;
    this.feeding_level = 0;
    // relationship parameters
    this.pond_id = pond_id;
    this.created_by = created_by;
    this.time_slots = {};
    this.SAVED_AT_DEVICE = false;
    // time parameters
    this.s_time = MIN_S_TIME;
    this.s_time_secs = MIN_S_TIME_SECS;
    this.bk_s_time = undefined;
    this.bk_s_time_secs = undefined;
    this.e_time = MIN_E_TIME;
    this.e_time_secs = MIN_E_TIME_SECS;
    this.bk_e_time = undefined;
    this.bk_e_time_secs = undefined;
    this.dateQueryType = "TODAY";
    // feed parameters
    this.dispensed_feed = 0;
    this.ocf = INIT_OCF_VALUE;
    this.feed = 0;
    this.feed_gap = 1;
    this.remaining_feed = 0;
    // status params
    this.status = POND_TS_STATUS.TO_BE_RUN;
    this.ui_status = POND_TS_STATUS.TO_BE_RUN;
    // ui params
    this.is_user_edited = false;
    this.enabled = {
      from_time: true,
      to_time: true,
      feed: true,
      mode: true,
      feeding_level: true,
      ocf: true,
      feed_gap: false,
      act_btn_del: true
    };
  }

  get from_time() {
    return this.s_time;
  }

  get to_time() {
    return this.e_time;
  }

  get total_feed_kgs() {
    return this.feed;
  }

  get ocf_g() {
    return this.ocf;
  }

  get feed_gap_mins() {
    return this.feed_gap;
  }

  get pmsSubscribe() {
    return this.time_slots;
  }

  get mode_g() {
    return this.mode;
  }

  get feeding_level_g() {
    return this.feeding_level;
  }

  set from_time({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    this.s_time = updtPropVal || this.s_time;
    this.s_time_secs = timeStrHHmmVal(this.s_time);
  }

  set to_time({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    this.e_time = updtPropVal || this.e_time;
    this.e_time_secs = timeStrHHmmVal(this.e_time);
  }

  set duration_secs(value) {
    this.duration = value;
  }

  set total_feed_kgs({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.feed = updtPropVal;
    } else {
      this.feed = +(+updtPropVal).toFixed(2);
    }
  }

  set ocf_g({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.ocf = updtPropVal;
    } else {
      this.ocf = +(+updtPropVal).toFixed(2);
    }
  }

  set feed_gap_mins(value) {
    this.feed_gap = value;
  }

  set pondMode({ value = [] }) {
    if (value.length > 1) {
      this.mode = POND_MODES.MIXED; // POND_MODES.HYBRID got changed to mixed due to new mode changes
      return;
    }
    this.mode = value[0];
  }

  set mode_g({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.mode = updtPropVal;
    } else {
      this.mode = +(+updtPropVal).toFixed(2);
    }
  }

  set feeding_level_g({ updtPropVal, eventType }) {
    this.is_user_edited = true;
    if (isNaN(+updtPropVal) || +updtPropVal < 0) {
      this.feeding_level = updtPropVal;
    } else {
      this.feeding_level = +(+updtPropVal).toFixed(2);
    }
  }

  set isEditable(value) {
    this.enabled = {
      from_time: value,
      to_time: value,
      feed: value,
      ocf: value,
      mode: value,
      feeding_level: value,
      act_btn_del: value
    };
  }

  set setSavedAtDeviceStatus(arrValues) {
    if (arrValues.length > 1) {
      this.SAVED_AT_DEVICE = SAVED_AT_DEVICE_STATUS.PARTIAL;
    } else if (arrValues.length === 1) {
      this.SAVED_AT_DEVICE = arrValues[0]
        ? SAVED_AT_DEVICE_STATUS.COMPLETED
        : SAVED_AT_DEVICE_STATUS.NOT_SAVED;
    } else {
      this.SAVED_AT_DEVICE = SAVED_AT_DEVICE_STATUS.NOT_SAVED;
    }
  }

  joinPMWithSameProperty(pmTS, updtPondTS = false) {
    this.pmsSubscribe[pmTS.pond_mother_id] = pmTS.ui_id;
    if (updtPondTS) {
      this.feed += pmTS.feed;
      this.ocf = Math.max(this.ocf, pmTS.ocf);
      this.feed_gap = Math.max(this.feed_gap, pmTS.feed_gap);
      this.remaining_feed += pmTS.remaining_feed;
      this.dispensed_feed += pmTS.dispensed_feed;
      ["feed", "dispensed_feed", "remaining_feed"].forEach(prop => {
        this[prop] = +this[prop].toFixed(2);
      });
      if (this.e_time_secs < pmTS.e_time_secs) {
        this.e_time_secs = pmTS.e_time_secs;
        this.e_time = pmTS.e_time;
      }
      let pondFinalStatus = this.ui_status;
      if (pondFinalStatus !== pmTS.ui_status) {
        pondFinalStatus = POND_TS_STATUS.PM_DIFF_STATUS;
      }
      this.status = pmTS.status;
      this.ui_status = pondFinalStatus;
    }
  }

  static getCurrentDayNewTS(
    pondId,
    pondMode,
    created_by,
    userTimeZoneString,
    pmStDetails
  ) {
    const tempDate = dateUtils.getCurrDateInGivenTZ(userTimeZoneString);
    const newPondTS = new PondTS(pondId, pondMode, created_by);
    newPondTS.s_time = dateUtils.formatDate(
      dateUtils.add(tempDate, {
        minutes: INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS
      }),
      "HH:mm"
    );
    newPondTS.s_time_secs = timeStrHHmmVal(newPondTS.s_time);
    newPondTS.e_time = dateUtils.formatDate(
      dateUtils.add(tempDate, {
        minutes: INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS + 1
      }),
      "HH:mm"
    );
    newPondTS.e_time_secs = timeStrHHmmVal(newPondTS.e_time);
    newPondTS.feed = 0;
    newPondTS.ocf = INIT_OCF_VALUE;
    newPondTS.feed_gap = 1;
    return newPondTS;
  }

  refresh(selectedDay, dayPmIdToTsIdToTs) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    if (allPmsTS.length === 0) return;
    this.feed = allPmsTS.map(x => x.feed).reduce((acc, curr) => curr + acc);
    this.ocf = Math.max(...allPmsTS.map(pm => pm.ocf));
    this.feed_gap = Math.max(...allPmsTS.map(pm => pm.feed_gap));
    this.mode = allPmsTS.every(pm => pm.mode) ? allPmsTS[0].mode : "";
    this.feeding_level = allPmsTS.every(pm => pm.feeding_level)
      ? allPmsTS[0].feeding_level
      : 0;
    this.is_user_edited = allPmsTS.some(x => x.is_user_edited);
    const pmWithMaxToTime = allPmsTS.sort((a, b) => {
      return b.e_time_secs - a.e_time_secs;
    })[0];
    this.e_time_secs = pmWithMaxToTime.e_time_secs;
    this.e_time = pmWithMaxToTime.e_time;
  }

  updtPmsSubscribeTime(selectedDay, dayPmIdToTsIdToTs, prop) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    if (allPmsTS.length === 0) return;
    const keyMap = {
      from_time: ["s_time", "s_time_secs"],
      to_time: ["e_time", "e_time_secs"]
    };
    const maxToTimeSecs = 0;
    allPmsTS.forEach(pmTS => {
      keyMap[prop].forEach(tsKey => {
        pmTS[tsKey] = this[tsKey];
      });
      pmTS.is_user_edited = true;
    });
    allPmsTS.forEach(pmTS => {
      if (prop === "from_time") {
        // calculte each pm to_time then assign the max to time to pond to_time
        pmTS.updtToTime("s_time", "init");
        if (maxToTimeSecs < pmTS.e_time_secs) {
          this.e_time_secs = pmTS.e_time_secs;
          this.e_time = pmTS.e_time;
        }
      } else {
        if (maxToTimeSecs < pmTS.e_time_secs) {
          this.e_time_secs = pmTS.e_time_secs;
          this.e_time = pmTS.e_time;
        }
      }
    });
  }

  updtPmsFeed(selectedDay, dayPmIdToTsIdToTs) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    const noOfPmslength = allPmsTS.length;
    if (noOfPmslength === 0) return;
    let pm_feed = this.feed / noOfPmslength;
    pm_feed = Number(pm_feed.toFixed(2));
    this.feed = +(pm_feed * noOfPmslength).toFixed(2);
    allPmsTS.forEach(pmTS => {
      pmTS.is_user_edited = true;
      pmTS.feed = pm_feed;
      pmTS.updtToTime("total_feed_kgs", "INIT");
      if (this.e_time_secs < pmTS.e_time_secs) {
        this.e_time_secs = pmTS.e_time_secs;
        this.e_time = pmTS.e_time;
      }
    });
  }

  updtPmsMode(selectedDay, dayPmIdToTsIdToTs) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    const noOfPmslength = allPmsTS.length;
    if (noOfPmslength === 0) return;
    // let pm_feed = this.feed / noOfPmslength;
    // pm_feed = Number(pm_feed.toFixed(2));
    // this.feed = +(pm_feed * noOfPmslength).toFixed(2);
    console.log("allPmsTS", this.mode, allPmsTS);
    allPmsTS.forEach(pmTS => {
      pmTS.is_user_edited = true;
      pmTS.mode = this.mode;
      // pmTS.updtToTime("total_feed_kgs", "INIT");
      // if (this.e_time_secs < pmTS.e_time_secs) {
      //   this.e_time_secs = pmTS.e_time_secs;
      //   this.e_time = pmTS.e_time;
      // }
    });
  }

  updtPmsFeedingLevel(selectedDay, dayPmIdToTsIdToTs) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    const noOfPmslength = allPmsTS.length;
    if (noOfPmslength === 0) return;
    // let pm_feed = this.feed / noOfPmslength;
    // pm_feed = Number(pm_feed.toFixed(2));
    // this.feed = +(pm_feed * noOfPmslength).toFixed(2);
    allPmsTS.forEach(pmTS => {
      pmTS.is_user_edited = true;
      pmTS.feeding_level = this.feeding_level;
      // pmTS.updtToTime("total_feed_kgs", "INIT");
      // if (this.e_time_secs < pmTS.e_time_secs) {
      //   this.e_time_secs = pmTS.e_time_secs;
      //   this.e_time = pmTS.e_time;
      // }
    });
  }

  updtPondFeedGap(selectedDay, dayPmIdToTsIdToTs, userTimeZoneString) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    const noOfPmslength = allPmsTS.length;
    if (noOfPmslength === 0) return;
    allPmsTS.forEach(pmTS => {
      pmTS.updtFeedGap(userTimeZoneString);
    });
    this.feed_gap = allPmsTS.reduce((maxFG, pmTS) => {
      if (maxFG < pmTS.feed_gap) {
        maxFG = pmTS.feed_gap;
      }
      return maxFG;
    }, 0);
  }

  updtPmsOCF(selectedDay, dayPmIdToTsIdToTs) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    const noOfPmslength = allPmsTS.length;
    if (noOfPmslength === 0) return;
    const pm_ocf = this.ocf;
    allPmsTS.forEach(pmTS => {
      pmTS.is_user_edited = true;
      pmTS.ocf = pm_ocf;
      pmTS.updtToTime("ocf_g", "init");
      if (this.e_time_secs < pmTS.e_time_secs) {
        this.e_time_secs = pmTS.e_time_secs;
        this.e_time = pmTS.e_time;
      }
    });
  }

  updtToTime(prop, eventType) {
    if (this.s_time_secs < this.e_time_secs) return;
    this.e_time = calcPondScheduleEndTime({
      STRTSecs: this.s_time_secs,
      FG: this.feed_gap,
      OCF: this.ocf,
      TF: this.feed,
      pmsCount: Object.keys(this.pmsSubscribe).length
    });
    this.e_time_secs = timeStrHHmmVal(this.e_time);
    if (
      this.s_time_secs > C_MAX_E_TIME_SECS &&
      this.e_time_secs > C_MAX_E_TIME_SECS
    ) {
      this.s_time_secs = MIN_S_TIME_SECS;
      // this.e_time_secs = MIN_S_TIME_SECS + 60;
      this.s_time = MIN_S_TIME;
      // this.e_time = castSecsHHmmStr(this.e_time_secs);
    } else if (this.e_time_secs > C_MAX_E_TIME_SECS) {
      this.e_time_secs = C_MAX_E_TIME_SECS_ALLOWED;
      this.e_time = castSecsHHmmStr(this.e_time_secs);
    }
  }

  updtFromTime() {
    this.s_time = calcPondScheduleStartTime({
      STPTSecs: this.e_time_secs,
      FG: this.feed_gap,
      OCF: this.ocf,
      TF: this.feed,
      pmsCount: Object.keys(this.pmsSubscribe).length
    });
    this.s_time_secs = timeStrHHmmVal(this.s_time);
  }

  checkAndUpdateTSStatus(selectedDay, dayPmIdToTsIdToTs, userTimeZoneString) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    if (allPmsTS.length === 0) return;
    allPmsTS.forEach(pmTS => {
      pmTS.checkAndUpdateStatus(userTimeZoneString);
    });
    const pondStatus = [...new Set(allPmsTS.map(x => x.ui_status))];
    if (pondStatus.length === 1) {
      this.ui_status = pondStatus[0];
    } else {
      this.ui_status = POND_TS_STATUS.PM_DIFF_STATUS;
    }
  }

  isETimeGrtrSTime() {
    const ts = this;
    if (ts.e_time_secs > ts.s_time_secs) {
      return true;
    }
    return false;
  }

  isValidTimeSecsForCurrentDayByThreshold(
    tSecs,
    thresholdSecs,
    userTimeZoneString
  ) {
    const ptSecs = dateUtils.getCurrTimeSecsInGivenTZ(userTimeZoneString);
    if (this.isTIntvlGreaterThanThreshold(ptSecs, tSecs, thresholdSecs)) {
      return true;
    }
    return false;
  }

  isTIntvlGreaterThanThreshold(ptSecs, tSecs, thresholdSecs) {
    const time1Secs = ptSecs;
    const time2Secs = tSecs;
    if (time2Secs - time1Secs > thresholdSecs) {
      return true;
    }
    return false;
  }

  isValidScheduleTIntvl(userTimeZoneString, eventType) {
    const isCurrentDay = this.dateQueryType === "TODAY";
    const currIntvl = this;
    if (eventType === "init") return "NO_ERROR";
    if (currIntvl.s_time_secs < C_MIN_S_TIME_SECS) {
      return {
        type: "time",
        validation: "start_time_minimum"
      };
    }
    if (currIntvl.e_time_secs >= C_MAX_E_TIME_SECS) {
      return {
        type: "time",
        validation: "end_time_maximum"
      };
    }
    if (!currIntvl.isETimeGrtrSTime()) {
      return {
        type: "time",
        validation: "end_time_greater_start_time"
      };
    }
    const notARunningTS =
      currIntvl.bk_id &&
      ![
        POND_TS_STATUS.RUNNING,
        POND_TS_STATUS.UI_RUNNING,
        POND_TS_STATUS.PAUSED
      ].includes(currIntvl.ui_status);
    const isUICreatedTS = !currIntvl.bk_id; // if there is no backend id then it is ui created one
    const isFromTimeLessCurrTime = currIntvl.isValidTimeSecsForCurrentDayByThreshold(
      this.s_time_secs,
      TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
      userTimeZoneString
    );
    if (
      isCurrentDay &&
      (notARunningTS || isUICreatedTS) &&
      !isFromTimeLessCurrTime
    ) {
      return {
        type: "time",
        validation: "start_time_less_from_time"
      };
    }
    const isRunningTS =
      this.bk_id &&
      (currIntvl.ui_status === POND_TS_STATUS.UI_RUNNING ||
        currIntvl.ui_status === POND_TS_STATUS.RUNNING);
    if (
      isCurrentDay &&
      isRunningTS &&
      !this.isValidTimeSecsForCurrentDayByThreshold(
        this.e_time_secs,
        TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS,
        userTimeZoneString
      )
    ) {
      // return false;
      return {
        type: "time",
        validation: "end_time_before_5min_completion"
      };
    }
    return "NO_ERROR";
  }

  isTSOverlapping(allIntvls) {
    const resp = this.isOverlap(allIntvls);
    if (resp[0] !== "NO_OVERLAP") {
      // it should not overlap with the prev timeslot
      // it should not overlap with the future timeslot
      return {
        type: "time",
        validation: "overlap",
        schedule_id: resp[0]
      };
    }
    return "NO_ERROR";
  }

  isTIntvlHasValidFromTimeForCurrentDay(ts, userTimeZoneString) {
    // if timeslot is creating or editing for current day then it should be greater than the present time
    const ptSecs = dateUtils.getCurrDateInGivenTZ(userTimeZoneString);
    const sTSecs = ts.s_time_secs;
    const eTSecs = ts.e_time_secs;
    if (sTSecs >= ptSecs && eTSecs > sTSecs) {
      return true;
    }
    return false;
  }

  isValidTIntvlByThreshold(ptSecs, tSecs, thresholdSecs) {
    const time1Secs = ptSecs;
    const time2Secs = tSecs;
    if (time2Secs - time1Secs > thresholdSecs) {
      return true;
    }
    return false;
  }

  isValidTotalFeedForScheduleMode() {
    if (isNaN(+this.feed)) {
      return {
        type: "feed",
        validation: "empty"
      };
    }
    if (this.ocf < 200 || this.ocf > MAX_OCF_VALUE) return "SKIP_ERROR";
    const noOfPmsSubscribe = Object.values(this.pmsSubscribe).length;
    if (this.feed * 1000 < noOfPmsSubscribe * this.ocf) {
      return {
        type: "feed",
        validation: "minimum",
        threshold: (this.ocf / 1000) * noOfPmsSubscribe
      };
    }
    return "NO_ERROR";
  }

  isValidTotalFeedForAutomaticMode() {
    if (isNaN(+this.feed)) {
      return {
        type: "feed",
        validation: "empty"
      };
    }
    const totalFeed = this.feed - this.dispensed_feed;
    const noOfPmsSubscribe = Object.values(this.pmsSubscribe).length;
    if (totalFeed < MIN_TOTAL_FEED_VALUE_ST_MODE * noOfPmsSubscribe) {
      return {
        type: "feed",
        validation: "minimum",
        threshold: MIN_TOTAL_FEED_VALUE_ST_MODE
      };
    } else if (totalFeed > MAX_TOTAL_FEED_VALUE_ST_MODE * noOfPmsSubscribe) {
      return {
        type: "feed",
        validation: "maximum",
        threshold: MAX_TOTAL_FEED_VALUE_ST_MODE
      };
    }
    // current feed > dispensed feed
    if (this.ui_status === POND_TS_STATUS.RUNNING) {
      if (this.feed - this.dispensed_feed < 0) {
        return {
          type: "feed",
          validation: "less_than_dispensed_feed",
          threshold: this.dispensed_feed + (this.ocf * noOfPmsSubscribe) / 1000
        };
      }
    }
    return "NO_ERROR";
  }

  isValidTotalFeedForBasicMode(userTimeZoneString) {
    if (isNaN(+this.feed)) {
      return {
        type: "feed",
        validation: "empty"
      };
    }
    if (this.ocf < 200 || this.ocf > MAX_OCF_VALUE) return "SKIP_ERROR";
    const noOfPmsSubscribe = Object.values(this.pmsSubscribe).length;
    const sTime = castSecsHHmmStr(cmCurrTimeSecs(userTimeZoneString));
    const maxLimit = calcMaxTFForADay({
      STRTStr: sTime,
      FG: MIN_FEED_GAP_VALUE,
      OCF: this.ocf
    });
    const minLimit = calcTF({
      TT: Math.ceil(TIME_TO_SAVE_DATA_IN_DEVICE_IN_SECS / 60) + 1,
      FG: MIN_FEED_GAP_VALUE,
      OCF: this.ocf
    });
    const totalFeed = this.feed - this.dispensed_feed;
    if (minLimit >= maxLimit) {
      return {
        type: "time",
        validation: "possible_to_give_timeslots"
      };
    }
    if (totalFeed > noOfPmsSubscribe * maxLimit) {
      return {
        type: "feed",
        validation: "maximum",
        threshold: maxLimit * noOfPmsSubscribe
      };
    }
    if (totalFeed < noOfPmsSubscribe * minLimit) {
      return {
        type: "feed",
        validation: "minimum",
        threshold: minLimit * noOfPmsSubscribe
      };
    }
    return "NO_ERROR";
  }

  isValidOCFForScheduleMode(userTimeZoneString) {
    if (isNaN(+this.ocf)) {
      return {
        type: "ocf",
        validation: "empty"
      };
    }
    const pmCounts = Object.keys(this.pmsSubscribe).length;
    const totalFeed = (this.feed - this.dispensed_feed) / pmCounts;
    const isOCFValid = paramOCF =>
      paramOCF >= INIT_OCF_VALUE && paramOCF <= MAX_OCF_VALUE;
    const isValidFeedGap = paramFG => paramFG >= 1;
    const isTSTFValid = this.feed > 0;
    let start_time_secs = this.s_time_secs;
    if (
      [POND_TS_STATUS.RUNNING, POND_TS_STATUS.PAUSED].indexOf(this.ui_status) >
        -1 ||
      this.mode === "MANUAL"
    ) {
      start_time_secs = cmCurrTimeSecs(userTimeZoneString);
    }
    const totalTimeInMins = Math.ceil(
      (this.e_time_secs - start_time_secs) / 60
    );
    const suggestOCF = suggestOCFForPm;
    if (isOCFValid(this.ocf) && !isTSTFValid) {
      return "SKIP_ERROR";
    }
    if (isOCFValid(this.ocf) && isTSTFValid) {
      const feedGap = calcFG({
        TT: totalTimeInMins,
        TF: totalFeed,
        OCF: this.ocf
      });
      if (isValidFeedGap(feedGap)) {
        this.feed_gap_mins = feedGap;
        return "NO_ERROR";
      }
      const suggestedOCF = suggestOCF(totalTimeInMins, totalFeed);
      if (isOCFValid(suggestedOCF)) {
        return {
          type: "ocf",
          validation: "minimum",
          threshold: suggestedOCF
        };
      }
      return {
        type: "feed_gap",
        validation: "minimum"
      };
    }
    if (!isOCFValid(this.ocf) && isTSTFValid) {
      const suggestedOCF = suggestOCF(totalTimeInMins, totalFeed);
      if (isOCFValid(suggestedOCF)) {
        return {
          type: "ocf",
          validation: "minimum",
          threshold: suggestedOCF
        };
      }
    }
    if (this.ocf < INIT_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "minimum",
        threshold: INIT_OCF_VALUE
      };
    }
    if (this.ocf > MAX_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "maximum",
        threshold: MAX_OCF_VALUE
      };
    }
  }

  isValidOCFForBasicMode() {
    if (isNaN(+this.ocf)) {
      return {
        type: "ocf",
        validation: "empty"
      };
    }
    const isOCFValid = paramOCF =>
      paramOCF >= INIT_OCF_VALUE && paramOCF <= MAX_OCF_VALUE;
    const isTSTFValid = this.feed > 0;
    if (isOCFValid(this.ocf) && !isTSTFValid) {
      return "SKIP_ERROR";
    }
    if (this.ocf < INIT_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "minimum",
        threshold: INIT_OCF_VALUE
      };
    }
    if (this.ocf > MAX_OCF_VALUE) {
      return {
        type: "ocf",
        validation: "maximum",
        threshold: MAX_OCF_VALUE
      };
    }
    return "NO_ERROR";
  }

  isOverlap(allsIntvls) {
    const allIntvlsSize = allsIntvls.length;
    const temp = [...allsIntvls];
    temp.sort((a, b) => a.e_time_secs - b.s_time_secs);
    for (let i = 0; i < allIntvlsSize; i++) {
      const prevTIntvl = temp[i];

      if (prevTIntvl.ui_id === this.ui_id) continue;
      // console.log(prevTIntvl.ui_id, this.ui_id);
      const errMsgs = [];
      [[prevTIntvl, this]].forEach((tsIntvlPair, index) => {
        if (tsIntvlPair[0] && tsIntvlPair[1]) {
          const s1h1 = tsIntvlPair[0].s_time_secs;
          const s1h2 = tsIntvlPair[0].e_time_secs;
          const s2h1 = tsIntvlPair[1].s_time_secs;
          const s2h2 = tsIntvlPair[1].e_time_secs;
          const schedIndex = tsIntvlPair[0].ui_id;
          const s1PmIds = Object.keys(tsIntvlPair[0].pmsSubscribe);
          const s2PmIds = Object.keys(tsIntvlPair[1].pmsSubscribe);
          const uniquePmIds = [...new Set([...s1PmIds, ...s2PmIds])].length;
          const totalLen = s1PmIds.length + s2PmIds.length;
          const hasNoCommonPms = uniquePmIds === totalLen;
          /**
           * Case:
           * ts 1:------S-------E------
           * ts 2:---------S------E----
           */
          if (s1h1 <= s2h1 && s2h1 <= s1h2 && !hasNoCommonPms) {
            errMsgs.push(schedIndex);
            return;
          }
          /**
           * Case:
           * ts 1:------S-------E------
           * ts 2:---S------E----------
           */
          if (s1h1 <= s2h2 && s2h2 <= s1h2 && !hasNoCommonPms) {
            errMsgs.push(schedIndex);
            return;
          }
          /**
           * Case:
           * ts 1:-----S-------E-----
           * ts 2:-------S---E-------
           */
          if (s2h1 <= s1h1 && s2h2 >= s1h2 && !hasNoCommonPms) {
            errMsgs.push(schedIndex);
          }
        }
      });
      const errMsgsLen = errMsgs.length;
      if (errMsgsLen > 0) {
        return errMsgs;
      }
    }
    // not overlapping
    return ["NO_OVERLAP"];
  }

  static getAllValidPondTSFromArrPondTSByTime(arrCurrPondDayTS) {
    const validTimeSlots = arrCurrPondDayTS.filter((ts, index) => {
      if (!ts.bk_id) {
        return true;
      }
      // Filtering the running schedule as it is not editted by the user
      // if (
      //   [POND_TS_STATUS.RUNNING].includes(ts.ui_status) &&
      //   !ts.is_user_edited
      // ) {
      //   return false;
      // }
      if (
        [
          POND_TS_STATUS.COMPLETED,
          POND_TS_STATUS.STOPPED,
          POND_TS_STATUS.UI_COMPLETED
        ].indexOf(ts.ui_status) === -1
      ) {
        return true;
      }
      return false;
    });
    return validTimeSlots;
  }

  static getAllValidPondTSFromArrPondTS(arrCurrPondDayTS) {
    arrCurrPondDayTS = arrCurrPondDayTS.sort(
      (a, b) => a.s_time_secs - b.s_time_secs
    );
    const arrEditedIndexes = arrCurrPondDayTS.reduce((acc, ts, index) => {
      if (ts.is_user_edited) {
        acc.push(index);
      }
      return acc;
    }, []);
    if (arrEditedIndexes.length === 0) return [];
    const indexOfEditedRecord = Math.min(...arrEditedIndexes);
    const validTimeSlots = arrCurrPondDayTS.filter((ts, index) => {
      if (indexOfEditedRecord > index) {
        return false;
      }
      if (!ts.bk_id) {
        return true;
      }
      if (
        [
          POND_TS_STATUS.COMPLETED,
          POND_TS_STATUS.STOPPED,
          POND_TS_STATUS.UI_COMPLETED
        ].indexOf(ts.ui_status) === -1
      ) {
        return true;
      }
      return false;
    });
    return validTimeSlots;
  }

  setFieldsEditableStatus() {
    if (this.ui_status === POND_TS_STATUS.TO_BE_RUN) return;
    if (
      [
        POND_TS_STATUS.UI_RUNNING,
        POND_TS_STATUS.RUNNING,
        POND_TS_STATUS.PAUSED
      ].includes(this.ui_status)
    ) {
      this.enabled.from_time = false;
      this.enabled.mode = false;
      this.enabled.act_btn_del = false;
    } else {
      this.isEditable = false;
    }
  }

  castBackendTSToUiTS(arrProperties, bkTS) {
    const bkToFrontend = {
      s_time: "bk_s_time",
      s_time_secs: "bk_s_time_secs",
      e_time: "bk_e_time",
      e_time_secs: "bk_e_time_secs"
    };
    arrProperties.forEach(property => {
      switch (property) {
        case "feed_gap":
          this[property] = +(bkTS[property] || 1).toFixed(1);
          this[property] = +this[property];
          break;
        case "feed":
        case "dispensed_feed":
        case "remaining_feed":
          this[property] = +(bkTS[property] || 0).toFixed(2);
          break;
        case "ocf":
          this[property] = +(bkTS[property] || 200).toFixed(2);
          break;
        case "s_time":
        case "s_time_secs":
        case "e_time":
        case "e_time_secs":
          this[property] = bkTS[property];
          this[bkToFrontend[property]] = bkTS[property];
          break;
        default:
          this[property] = bkTS[property];
      }
    });
  }

  castToBackendObjType(
    selectedDay,
    dayPmIdToTsIdToTs,
    pondMode,
    userTimeZoneString,
    pmIdToStIds = null,
    stIdToStDetails
  ) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = [];
    allPmIds.forEach(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      allPmsTS.push(dayPmIdToTsIdToTs[currDayKey][pmTSId]);
    });
    const noOfPmslength = allPmsTS.length;
    if (noOfPmslength === 0) return;
    const [isPondBasic, isPondAutomatic] = [
      POND_MODES.BASIC,
      POND_MODES.AUTOMATIC
    ].map(x => this.mode === x);
    const groupPmTsByProperty = {
      property: isPondAutomatic ? "shrimp_talk_id|s_time" : "e_time",
      time_slots: {}
    };
    const isStatusRunning = [
      POND_TS_STATUS.RUNNING,
      POND_TS_STATUS.PAUSED,
      POND_TS_STATUS.UI_RUNNING
    ].includes(this.status);
    const currTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ(userTimeZoneString);
    const basicSTimeSecs = isStatusRunning ? this.s_time_secs : currTimeSecs;
    const basicSTimeSecsForEtimeCalculation = currTimeSecs;
    allPmsTS.reduce((acc, pmTS) => {
      const bkPmObj = {};
      bkPmObj.pond_mother_id = pmTS.pond_mother_id;
      bkPmObj.feed = pmTS.feed;
      bkPmObj.managed_by = pondMode;
      bkPmObj.status = isPondBasic ? POND_TS_STATUS.RUNNING : pmTS.status;
      let key = pmTS.e_time;
      if (isPondBasic) {
        key = pmTS.getBasicModeEtime(
          basicSTimeSecsForEtimeCalculation,
          userTimeZoneString
        ).e_time;
      }
      if (isPondAutomatic) {
        bkPmObj.shrimp_talk_id = pmIdToStIds[pmTS.pond_mother_id];
        key = `${bkPmObj.shrimp_talk_id}|${pmTS.s_time}`;
      } else {
        bkPmObj.ocf = pmTS.ocf;
        bkPmObj.feed_gap =
          pmTS.getFeedGapBasedOnTSStatus(userTimeZoneString) * 60;
      }
      if (!acc.time_slots[key]) {
        acc.time_slots[key] = [];
      }
      acc.time_slots[key].push(bkPmObj);
      return acc;
    }, groupPmTsByProperty);
    if (isPondAutomatic) {
      const arrTimeSlots = Object.keys(groupPmTsByProperty.time_slots).map(
        (key, index) => {
          const [stId, s_time] = key.split("|");
          const time_slots = groupPmTsByProperty.time_slots[key];
          const bkTSObj = {};
          bkTSObj.s_time =
            this.status === POND_TS_STATUS.TO_BE_RUN && !this.bk_id
              ? castSecsHHmmStr(currTimeSecs)
              : s_time;
          bkTSObj.e_time = stIdToStDetails[stId].e_time;
          bkTSObj.managed_by = pondMode;
          bkTSObj.status = this.status;
          bkTSObj.pond_mothers = time_slots;
          bkTSObj.feed = time_slots.reduce((acc, curr) => acc + curr.feed, 0);
          if (this.bk_id && index === 0) {
            bkTSObj._id = this.bk_id;
          }
          bkTSObj.shrimp_talk_id = stId;
          return bkTSObj;
        }
      );
      return arrTimeSlots;
    } else {
      const timeSlotObj = groupPmTsByProperty.time_slots;
      const arrTimeSlots = Object.keys(timeSlotObj).map((eTime, index) => {
        const time_slots = groupPmTsByProperty.time_slots[eTime];
        const bkTSObj = {};
        bkTSObj.s_time = isPondBasic
          ? castSecsHHmmStr(basicSTimeSecs)
          : this.s_time;
        bkTSObj.e_time = eTime;
        bkTSObj.managed_by = pondMode;
        bkTSObj.status = isPondBasic ? POND_TS_STATUS.RUNNING : this.status;
        bkTSObj.pond_mothers = time_slots;
        bkTSObj.feed = time_slots.reduce((acc, curr) => acc + curr.feed, 0);
        if (this.bk_id && index === 0) {
          bkTSObj._id = this.bk_id;
        }
        return bkTSObj;
      });
      return arrTimeSlots;
    }
  }

  getAllPmTSFromPmTSObj(selectedDay, dayPmIdToTsIdToTs) {
    const allPmIds = Object.keys(this.pmsSubscribe);
    const allPmsTS = allPmIds.map(pmId => {
      const currDayKey = `${selectedDay}_${this.pond_id}_${pmId}`;
      const pmTSId = this.pmsSubscribe[pmId];
      return dayPmIdToTsIdToTs[currDayKey][pmTSId];
    });
    return allPmsTS;
  }

  static getPondTSGroupBySTimeAndETime(arrPondTS, seperator = "|") {
    return arrPondTS.reduce((acc, currTS) => {
      acc[currTS.s_time_secs + seperator + currTS.e_time_secs] = currTS;
      return acc;
    }, {});
  }

  static getPondTSGroupBySTimeAndTSStatus(arrPondTS, seperator = "|") {
    return arrPondTS.reduce((acc, currTS) => {
      acc[currTS.s_time_secs + seperator + currTS.ui_status] = currTS;
      return acc;
    }, {});
  }

  static generatePondTSGroupByThresholdSTime(arrPmTS, thresholdInMins) {
    if (arrPmTS.length === 0) return;
    const thresholdInSecs = thresholdInMins * 60;
    const sTimeArr = arrPmTS.sort((a, b) => a.s_time_secs - b.s_time_secs);
    let i = 0;
    let j = 0;
    const groupPmTSBySTimeAndStatus = {};
    while (i < sTimeArr.length && j < sTimeArr.length) {
      const ts1 = sTimeArr[i];
      const ts2 = sTimeArr[j];
      if (ts2.s_time_secs - ts1.s_time_secs < thresholdInSecs) {
        const key = `${ts1.s_time_secs}_${ts2.status}`;
        if (!groupPmTSBySTimeAndStatus[key]) {
          groupPmTSBySTimeAndStatus[key] = [];
        }
        groupPmTSBySTimeAndStatus[key].push(ts2);
        j++;
      } else {
        i = j;
      }
    }
    console.log(groupPmTSBySTimeAndStatus, sTimeArr.length);
  }

  static createPondTSForGroupPmTS(
    pmTSGroup,
    existPondTS,
    pondId,
    dateQueryType
  ) {
    const mode = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "mode",
      "UNIQUE"
    );
    const tempPondTS = new PondTS(pondId, undefined, TS_CREATED_BY.IT_SELF);
    tempPondTS.ui_id = existPondTS ? existPondTS.ui_id : tempPondTS.ui_id;
    tempPondTS.pondMode = {
      value: mode
    };
    tempPondTS.s_time_secs = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "s_time_secs",
      "MIN"
    );
    tempPondTS.feeding_level = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "feeding_level",
      "UNIQUE"
    )[0];
    tempPondTS.bk_s_time_secs = tempPondTS.s_time_secs;
    tempPondTS.s_time = castSecsHHmmStr(tempPondTS.s_time_secs);
    tempPondTS.bk_s_time = tempPondTS.s_time;
    tempPondTS.e_time_secs = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "e_time_secs",
      "MAX"
    );
    tempPondTS.bk_e_time_secs = tempPondTS.e_time_secs;
    tempPondTS.e_time = castSecsHHmmStr(tempPondTS.e_time_secs);
    tempPondTS.bk_e_time = tempPondTS.e_time;
    pmTSGroup.forEach(pmTS => {
      tempPondTS.joinPMWithSameProperty(pmTS, true);
    });
    const uiStatus = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "ui_status",
      "UNIQUE"
    );
    tempPondTS.ui_status =
      uiStatus.length > 1 ? POND_TS_STATUS.PM_DIFF_STATUS : uiStatus[0];

    tempPondTS.bk_id = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "bk_id",
      "UNIQUE"
    )[0];
    tempPondTS.previousMode = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "previousMode",
      "UNIQUE"
    )[0];
    tempPondTS.setStIds = new Set(
      PondMotherTS.getPropertyValueInArrTS(
        pmTSGroup,
        "shrimp_talk_id",
        "UNIQUE"
      )
    );
    tempPondTS.setFieldsEditableStatus();
    tempPondTS.setSavedAtDeviceStatus = PondMotherTS.getPropertyValueInArrTS(
      pmTSGroup,
      "SAVED_AT_DEVICE",
      "UNIQUE"
    );
    tempPondTS.dateQueryType = dateQueryType;
    return tempPondTS;
  }

  static generatePondTS(
    pondId,
    dateQueryType,
    arrPmTS,
    existArrPondTS,
    mergingStrategy,
    arrStrategyParams
  ) {
    if (arrPmTS.length === 0) return {};
    const { strategy, params } = PondMotherTS.mergingStrategyMapper(
      mergingStrategy,
      arrStrategyParams
    );
    const groupedPmTS = strategy(arrPmTS, ...params);
    const arrPondTS = groupedPmTS.map((eachGroup, index) => {
      return PondTS.createPondTSForGroupPmTS(
        eachGroup,
        existArrPondTS[index],
        pondId,
        dateQueryType
      );
    });
    const mapPondTS = arrPondTS
      .sort((a, b) => {
        return a.s_time_secs - b.s_time_secs || a.e_time_secs - b.e_time_secs;
      })
      .reduce((acc, eachPondTS) => {
        acc[eachPondTS.ui_id] = eachPondTS;
        return acc;
      }, {});
    return mapPondTS;
  }
}
