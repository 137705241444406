/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: aquasim.js
Description: This file contains all the vuex store functions used in relation to aquasim
*/
import ThirdPartyService from "@/services/ThirdPartyService";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    updateAquasim: async (context, params = {}) => {
      await ThirdPartyService.updateAquasimDetails(params);
    }
  }
};
