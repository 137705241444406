export const UAM_FILTER_TYPES = {
  USER_TYPE: "USER_TYPE",
  SEARCH_STRING: "SEARCH_STRING"
};
export const ADMIN_FILTER_TYPES = {
  USER_TYPE: "DEPARTMENT",
  SEARCH_STRING: "SEARCH_STRING"
};
export const ADMIN_USER_TYPES = [
  { key: "MARKETING", label: "Marketing" },
  { key: "FINANCE", label: "Finance" },
  { key: "OPERATIONS", label: "Operations" },
  { key: "CUSTOMER_SUPPORT", label: "Customer Support" },
  { key: "TECHNOLOGY", label: "Technology" },
  { key: "IT", label: "IT" },
  { key: "HR", label: "HR" }
];
export default {
  UAM_FILTER_TYPES,
  ADMIN_USER_TYPES,
  ADMIN_FILTER_TYPES
};
